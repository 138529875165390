// export default function ShowTime(TimeStamp){
// if(!TimeStamp){
//   return null;
// }
// let date = new Date(TimeStamp);
// // date.setHours(date.getHours() + 5);
// let currentDate = new Date();
// let timeDifference = Math.abs(currentDate.getTime() - date.getTime());
// let dayDifference = Math.floor(timeDifference / (1000 * 3600 * 24));

// // if (timeDifference>0) {
//   if (timeDifference < 60 * 1000) {
//     // Within a minute
//     return "now";
//   } else if (timeDifference < 60 * 60 * 1000) {
//     // Within an hour
//     let minDifference = Math.floor(timeDifference / (1000 * 60));
//     return `${minDifference} min ago`;
//   } else if (dayDifference === 0) {
//     // Today
//     return date.toLocaleString("en-US", {
//       hour: "numeric",
//       minute: "numeric",
//       hour12: true
//     });
//   } else if (dayDifference === 1) {
//     // Yesterday
//     return "Yesterday";
//   } else if (dayDifference < 7) {
//     // Within the week
//     return date.toLocaleDateString("en-US", { weekday: "short" });
//   } else {
//     // More than a week ago
//     return date.toLocaleDateString("en-US", {
//       month: "short",
//       day: "numeric",
//       year: "numeric"
//     });
//   }
// }

export default function ShowTime(TimeStamp) {
  if (!TimeStamp) {
    return null;
  }
  
  let date = new Date(TimeStamp);
  let currentDate = new Date();
  let timeDifference = Math.abs(currentDate.getTime() - date.getTime());

  if (timeDifference < 60 * 1000) {
  //   // Within a minute
    return "now";
  //  }else if (timeDifference < 60 * 60 * 1000) {
  //   // Within an hour
  //   let minDifference = Math.floor(timeDifference / (1000 * 60));
  //   return `${minDifference} min ago`;
  } else {
    // Display date and time
    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  }
}

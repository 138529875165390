import './NotFound.css'
import React from 'react';
import { Link } from 'react-router-dom';

export default function NotFound(){
  return(
  <div className='Center_NotFound'>
      <h1>-404-</h1>
      <h2>Not Found</h2>
      <br/>
      <h1>Sorry!!!</h1>
    <h2>This Path Doesn't Exist.</h2>
    <Link to="/">Go To HomePage</Link>
  </div>
  )
};

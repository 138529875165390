import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Container, Card, Button } from "react-bootstrap";
import axios from 'axios';
import AdminDashboardLayout from "../../../Components/AdminDashboard/AdminDashboardLayout";
import Loading from 'react-fullscreen-loading';
import { toast } from "react-hot-toast";
import Select from 'react-select';

export default function EditCoupon() {
  const navigate = useNavigate();
  const [screenloader, setScreenloader] = useState(true);
  const [duration, setDuration] = useState("");
  const [expiresAt, setExpiresAt] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [comments, setComments] = useState("");
  const [coupon, setCoupon] = useState();
  const Param1 = useParams();
  const id = Param1.id;

  useEffect(() => {
    getCoupon();
  }, []);

  const DateFormatDispaly=(d)=>{
    const t = new Date(d);
    const date = ('0' + t.getDate()).slice(-2);
    const month = ('0' + (t.getMonth() + 1)).slice(-2);
    const year = t.getFullYear();
    return(`${year}-${month}-${date}`);
  };
  const getCoupon = async () =>{
    await axios.get(`${process.env.REACT_APP_API_URL}/admin/coupons/${id}`, {
      headers: {
        'Access-Token': localStorage.getItem('accessToken'),
      }
    })
      .then(res => {
        setScreenloader(false);
        if (res.data.status) {
          const coupon = res.data.coupon;
          setCoupon(coupon);
          setComments(coupon.notes);
          const enddate=DateFormatDispaly(coupon.expires_at);
          setExpiresAt(enddate);
          setDuration(coupon.duration);
        }else {
          toast.error('Something Went Wrong');
          return;
        }
      })
      .catch(er => {
        setScreenloader(false);
        if (er.response.data?.message) {
          toast.error(er.response.data?.message);
          return;
        } else {
          toast.error("Something Went Wrong");
          return;
        }
      });
  }
  
  const EditCoupon = async () => {
    await axios.put(`${process.env.REACT_APP_API_URL}/admin/coupons/${id}`, {
      duration: duration,
      expires_at: expiresAt,
      notes: comments
    }, {
      headers: {
        'Access-Token': localStorage.getItem('accessToken'),
      }
    })
      .then(res => {
        setTimeout(() => {
          setScreenloader(false);
        }, 1500);
        if (res.data.status) {
          const coupon = res.data.coupon;
          setCoupon(coupon);
          setComments(coupon.notes);
          const enddate=DateFormatDispaly(coupon.expires_at);
          setExpiresAt(enddate);
          setDuration(coupon.duration);
          toast.success('Coupon has been updated');
        } else {
          toast.error('Something Went Wrong');
          return;
        }
      })
      .catch(er => {
        setScreenloader(false);
        if (er.response.data?.message) {
          toast.error(er.response.data?.message);
          return;
        } else {
          toast.error("Something Went Wrong");
          return;
        }
      });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    let isValid = true;

    if (isValid) {
      setScreenloader(true);
      await EditCoupon();
      setScreenloader(false);
    }
  };

  const labelStyles = {
    fontWeight: "bold",
    fontSize: "14px",
  };

  const handleDurationChange = (e) => {
    const value = e.target.value;
    if (value >= 0) {
      setDuration(value);
    }
  };

  return (
    <div>
      <AdminDashboardLayout />
      <Loading loading={screenloader} background="#fff" loaderColor="#883484" />
      <div className='content-page-bg'>
        <div className='content-page'>
          <div className='content'>
            <h1>Edit Coupon</h1>
            <Container fluid className='behind'>
              <Card className='mt-4'>
                <Card.Body>
                  <form onSubmit={handleFormSubmit}>
                    <div className='row my-4'>
                      <div className='col-lg-4 col-md-4 col-6'>
                        <Card.Subtitle className='mb-2 text-muted'>
                          Reference ID
                        </Card.Subtitle>
                        <Card.Title className="text-muted">{coupon?.ref_id}</Card.Title>
                      </div>                   
                    </div>
                    <div className='row my-4'>
                      <div className='col-lg-4 col-md-4 col-12 col-sm-6'>
                        <Card.Subtitle className='mb-2 text-muted'>
                          Created At
                        </Card.Subtitle>
                        <Card.Title className="text-muted">{coupon?.created_at}</Card.Title>
                      </div>  
                    </div>
                    <div className='row my-4'>
                      <div className='col-lg-4 col-md-4 col-12 col-sm-6'>
                        <label htmlFor="noOfCoupons" className="form-label" style={labelStyles}>
                          Duration (days)
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter number of days"
                          value={duration}
                          onChange={handleDurationChange}
                        />
                      </div>
                    </div>
                    <div className='row my-4'>
                      <div className='col-lg-4 col-md-4 col-12 col-sm-6'>
                        <label htmlFor="expiresAt" className="form-label" style={labelStyles}>
                          Expires At
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          value={expiresAt}
                          onChange={(e) => setExpiresAt(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="comments" className="form-label" style={labelStyles}>
                          Comments
                        </label>
                        <textarea
                          className="form-control"
                          id="comments"
                          placeholder='Enter any comments'
                          value={comments}
                          onChange={(e) => setComments(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className='mb-3 text-left'>
                      <Button className="btn" style={{ color: '#ffffff', backgroundColor: '#2e2e67', borderColor: '#2e2e67' }} type="submit">
                        Update
                      </Button>
                    </div>
                  </form>
                </Card.Body>
              </Card>
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
}

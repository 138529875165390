import "./Dashboard.css";
import React from "react";
import { NavLink } from "react-router-dom"; 
import { managementSections } from "./AdminSidebarData.js";
import { FaHome } from "react-icons/fa";

const UserBox = (props) => {
  return (
    <div className='user-box text-center'>
      <p className='cursor-pointer text-dark h5 mt-0 mb-1 d-block'>
        {props.username}
      </p>
    </div>
  );
};

const SideBarContent = (props) => {
  return (
    <>
      <UserBox username={props.username} />

      <div id='sidebar-menu' className="ScrollY">
        <ul className='side-menu' id='side-menu'>
          <li>
            <NavLink to='/admin/dashboard' style={{ textDecoration: 'none' }} activeclassname="active">
              <FaHome />
              <span> Dashboard </span>
            </NavLink>
          </li>

          {managementSections.map((section, index) => (
            section.items.some(item => item.roles.includes(localStorage.getItem("userType"))) && (
              <React.Fragment key={index}>
                <li className='menu-title mt-0' style={{ fontWeight: 'bold' }}>{section.title}</li>
                {section.items.map((item, itemIndex) => (
                  item.roles.includes(localStorage.getItem("userType")) && (
                    <li key={itemIndex} className='side-nav-item icon-clr'>
                      <NavLink to={item.path} className='side-nav-link-ref icon-clr' style={{ textDecoration: 'none' }} activeclassname="active">
                        <i className='feather feather-calendar'>{item.icon}</i>
                        <span> {item.title} </span>
                      </NavLink>
                    </li>
                  )
                ))}
              </React.Fragment>
            )
          ))}
        </ul>
      </div>
    </>
  );
};

export default SideBarContent;
import { useEffect, useState, useMemo } from "react";
import axios from 'axios';
import AdminDashboardLayout from "../../../Components/AdminDashboard/AdminDashboardLayout";
import Loading from 'react-fullscreen-loading';
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import DataTable from 'react-data-table-component';
import ShowTime from "../../../utils/ShowTime/ShowTime";
import { FaEdit, FaRegIdCard } from "react-icons/fa";
import Select from 'react-select';
import CustomLoader from "../../../utils/DataTableLoader/CustomLoader";
import { Button } from "react-bootstrap";
import { Tooltip } from 'react-tooltip';
import { Link } from 'react-router-dom';
import MapProduct from "../../../utils/ProductMapper/MapProduct";

export default function Index() {
  const navigate = useNavigate();

  const [TableData, setTableData] = useState([]);
  const [screenloader, setScreenloader] = useState(true);
  const [TotalRecords, setTotalRecords] = useState();
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState("created_at");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [pending, setPending] = useState(true);

  //filters
  const initialFilters = {
    'c.created_at': { operator: '', value: '' },
    email: { operator: '', value: '' },
    ref_id: { operator: '', value: '' },
    'u.used_at': { operator: '', value: '' },
  };
  const [filters, setFilters] = useState(initialFilters);
  const [createdAt, setCreatedAt] = useState("");
  const [email, setEmail] = useState("");
  const [refId, setRefId] = useState("");
  const [usedAt, setUsedAt] = useState("");

  const handleFiltersChange = (key, value, operator) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [key]: {
        operator: operator,
        value: value
      }
    }));
  };

  const handleFiltersReset = () => {
    setFilters(initialFilters);
    setCreatedAt("");
    setEmail("");
    setRefId("");
    setUsedAt("");
  };

  const handleApplyFilters = () => {
    handleFiltersChange("email", `%${email}%`, 'LIKE');
    handleFiltersChange("ref_id", `%${refId}%`, 'LIKE');
    handleFiltersChange("used_at", `%${usedAt}%`, 'LIKE');
  };

  const handleEnterKeyPress = (e) => {
    var value = e.target.value;
    if (value !== "") {
      value = `%${value}%`;
    }
    if (e.key === 'Enter') {
      handleFiltersChange(e.target.id, value, 'LIKE');
    }
  };

  const handleCopyCouponList = () => {
    const coupons = TableData.map(coupon => coupon.ref_id);
    const couponList = (coupons.join(', '));
    try {
      navigator.clipboard.writeText(couponList);
      toast.success(`${coupons.length} Coupons copied to clipboard!`);
    } catch (error) {
      toast.error('Failed to copy coupon list to clipboard');
    }
  };

  const columns = [
    { 
      name: "Actions", 
      sortable: false, 
      cell: row => (
        <>
          <FaEdit
            data-tooltip-id="coupon-index-edit"
            data-tooltip-content="edit coupon"
            data-tooltip-place="top"
            className="edit-icon"
            onClick={() => {
              navigate(`/Admin/Coupons/${row.id}/edit`, {
                replace: false,
              });
            }}
          />
        </>
      )
    },
    {
      name: 'Reference ID',
      selector: row => (row.user_reference_id),
      sortField: "ref_id",
      sortable: true,
      cell: row => (<div style={{ whiteSpace: 'pre-wrap' }}>{row.ref_id}</div>),
    },
    {
      name: 'Duration',
      selector: row => (row.duration),
      sortField: "duration",
      sortable: true,
      cell: row => (<div style={{ whiteSpace: 'pre-wrap' }}>{row.duration}</div>),
    },
    {
      name: 'Created At',
      selector: row => row.created_at,
      sortField: "created_at",
      sortable: true,
      cell: row => <div style={{ whiteSpace: 'pre-wrap' }}>{ShowTime(row.created_at)}</div>,
    },
    {
      name: 'Expire At',
      selector: row => row.expires_at,
      sortField: "expires_at",
      sortable: true,
      cell: row => <div style={{ whiteSpace: 'pre-wrap' }}>{ShowTime(row.expires_at)}</div>,
    },
    {
      name: 'Comments',
      selector: row => row.notes,
      sortField: "notes",
      sortable: true,
      cell: row => <div style={{ whiteSpace: 'pre-wrap' }}>{row.notes}</div>,
    },
    {
      name: 'Email',
      selector: row => row.email,
      sortField: "email",
      sortable: true,
      cell: row => <div style={{ whiteSpace: 'pre-wrap' }}>{row.email ? row.email : "-"}</div>,
    },
    {
      name: 'Used At',
      selector: row => row.used_at,
      sortField: "used_at",
      sortable: true,
      cell: row => <div style={{ whiteSpace: 'pre-wrap' }}>{row.used_at ? ShowTime(row.used_at) : "-"}</div>,
    },
    {
      name: 'Product',
      selector: row => row.product_id,
      sortField: "product_id",
      sortable: true,
      cell: row => <div style={{ whiteSpace: 'pre-wrap' }}>{MapProduct(row.product_id, "simple")}</div>,
    },
    // {
    //   name: 'Status',
    //   selector: row => row.status,
    //   sortField: "status",
    //   sortable: false,
    //   cell: row => <div style={{ whiteSpace: 'pre-wrap' }}>{row.status}</div>,
    // },
  ];

  const getCouponList = async () => {
    try {
      const formattedFilters = Object.keys(filters)
        .filter(key => filters[key].value) // Filter out falsy values
        .map((key, index) => ({
          [`filters[${index}][column]`]: key,
          [`filters[${index}][operator]`]: filters[key].operator,
          [`filters[${index}][value]`]: filters[key].value,
        }))
        .reduce((acc, obj) => ({ ...acc, ...obj }), {});

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/coupons/`, {
        params: {
          limit: pageSize,
          page: page,
          sort_by: sortBy,
          sort_order: sortOrder,
          ...formattedFilters
        },
        headers: {
          'access-token': localStorage.getItem('accessToken'),
        },
      });
      setScreenloader(false);
      setPending(false);
      if (response.data.status) {
        const total = response.data.meta.pagination.total;
        setTotalRecords(total);
        setTableData(response.data.coupons);
      } else {
        toast.error('Something Went Wrong');
        console.log(response.data);
      }
    } catch (error) {
      setScreenloader(false);
      setPending(false);
      if (error.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        console.error(error);
        toast.error('Something Went Wrong');
      }
    }
  };

  useEffect(() => {
    setPending(true);
    getCouponList();
  }, [filters, page, pageSize, sortBy, sortOrder]);
  
  const now = new Date();

  const startOfDay = new Date(now);
  startOfDay.setHours(0, 0, 0, 0);

  const startOf3DaysAgo = new Date(now);
  startOf3DaysAgo.setDate(startOf3DaysAgo.getDate() - 2);
  startOf3DaysAgo.setHours(0, 0, 0, 0);

  const endOf3DaysAfter = new Date(now);
  endOf3DaysAfter.setDate(endOf3DaysAfter.getDate() + 3);
  endOf3DaysAfter.setHours(0, 0, 0, 0);

  const startOf1WeekAgo = new Date(now);
  startOf1WeekAgo.setDate(startOf1WeekAgo.getDate() - 7);
  startOf1WeekAgo.setHours(0, 0, 0, 0);

  const endOf1WeekAfter = new Date(now);
  endOf1WeekAfter.setDate(endOf1WeekAfter.getDate() + 7);
  endOf1WeekAfter.setHours(0, 0, 0, 0);

  const startOf1MonthAgo = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate());
  startOf1MonthAgo.setHours(0, 0, 0, 0);

  const endOf1MonthAfter = new Date(now.getFullYear(), now.getMonth() + 1, now.getDate());
  endOf1MonthAfter.setHours(0, 0, 0, 0);

  const startOf2MonthAgo = new Date(now.getFullYear(), now.getMonth() - 2, now.getDate());
  startOf2MonthAgo.setHours(0, 0, 0, 0);

  const endOf2MonthAfter = new Date(now.getFullYear(), now.getMonth() + 2, now.getDate());
  endOf2MonthAfter.setHours(0, 0, 0, 0);


return (
  <div>
    <AdminDashboardLayout />
    <Loading loading={screenloader} background="#fff" loaderColor="#883484" />
    <div className='content-page-bg'>
      <div className='content-page'>
        <div className='content'>
          <div className="row">
            <div className="col-6 col-md-6 mb-3 d-flex">
            <h2>Coupons</h2>
            </div>
            <div className="col-6 col-md-6 mb-3 d-flex justify-content-end align-items-end">
            <Link to="/Admin/Coupons/new">
              <Button onClick={handleFiltersReset} variant="primary" style={{ backgroundColor: '#ab1852', borderColor: '#ab1852' }}>
                Add New Coupon
              </Button>
            </Link>
          </div>
          </div>
          <div className="filters row">
            <div className="col-md-3 mb-3">
              <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder='Email'
                  value={email}
                  onChange={e =>{setEmail(e.target.value);}}
                  onKeyPress={handleEnterKeyPress}
              />
            </div>

            <div className="col-sm-12 d-md-none mb-3">
              <button className="btn" style={{ color: '#ffffff', backgroundColor: '#2e2e67', borderColor: '#2e2e67' }} onClick={handleApplyFilters}>Apply Filters</button>
            </div>
            <div className="col-md-3 mb-3">
              <Select
                placeholder="Created At"
                options={[
                    { value: "", label:"All" },
                    { value: startOfDay.toISOString(), label:"Today" },
                    { value: startOf3DaysAgo.toISOString(), label: "Past 3 Days" },
                    { value: startOf1WeekAgo.toISOString(), label: "Within a Week" },
                    { value: startOf1MonthAgo.toISOString(), label:"Witin a Month" },
                    { value: startOf2MonthAgo.toISOString(), label:"Within 2 Months" },
                ]}
                value={createdAt}
                onChange={(e) => {setCreatedAt(e);handleFiltersChange('c.created_at', e.value, '>=')}}
              />
            </div>
            <div className="col-md-3 mb-3">
              <input
                  type="ref_id"
                  className="form-control"
                  id="ref_id"
                  placeholder='Reference ID'
                  value={refId}
                  onChange={e =>{setRefId(e.target.value);}}
                  onKeyPress={handleEnterKeyPress}
              />
            </div>
            <div className="col-md-3 mb-3">
              <Select
                placeholder="Used At"
                options={[
                    { value: "", label:"All" },
                    { value: startOfDay.toISOString(), label:"Today" },
                    { value: startOf3DaysAgo.toISOString(), label: "Past 3 Days" },
                    { value: startOf1WeekAgo.toISOString(), label: "Within a Week" },
                    { value: startOf1MonthAgo.toISOString(), label:"Witin a Month" },
                    { value: startOf2MonthAgo.toISOString(), label:"Within 2 Months" },
                ]}
                value={usedAt}
                onChange={(e) => {setUsedAt(e);handleFiltersChange('c.used_at', e.value, '>=')}}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6 col-md-6 mb-3 d-flex">
              <Button onClick={handleCopyCouponList} variant="primary"  style={{ backgroundColor: '#2e2e67', borderColor: '#2e2e67' }}>
                Copy Coupon List
              </Button>
            </div>
            <div className="col-6 col-md-6 mb-3 d-flex justify-content-end align-items-end">
              <Button onClick={handleFiltersReset} variant="primary"  style={{ backgroundColor: '#2e2e67', borderColor: '#2e2e67' }}>
                Clear all Filters
              </Button>
            </div>
          </div>
          <div className="row d-flex d-sm-none">
            <p>Total Records: <strong>{TotalRecords}</strong></p>
          </div>

          <div className="table-wrapper">
            <DataTable
              //data
              columns={columns}
              data={TableData}
              //style
              dense
              direction="auto"
              highlightOnHover
              noHeader
              persistTableHead
              pointerOnHover
              responsive
              subHeaderAlign="right"
              subHeaderWrap
              progressPending={pending}
              progressComponent={<CustomLoader />}
              // conditionalRowStyles={conditionalRowStyles}
              //pagination
              pagination
              paginationServer 
              paginationPerPage={pageSize} 
              paginationTotalRows={TotalRecords} 
              paginationRowsPerPageOptions={[10,20,50,100,150]}
              onChangePage={(page) => setPage(page)} 
              onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {setPageSize(currentRowsPerPage); setPage(currentPage)}} 
              sortServer
              onSort={(column, sortDirection) => {setSortBy(column.sortField); setSortOrder(sortDirection);}}
            />
          </div>
        </div>
      </div>
    </div>
    <Tooltip id="coupon-index-edit"  style={{ zIndex: 99 }} />
  </div>
);
}    
import './Signin.css';
import Footer from "../../../../Components/Footer/Footer";
import NavigationBar from "../../../../Components/NavigationBar/NavigationBar";
import { Button, Card, Form } from "react-bootstrap";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { toast } from 'react-hot-toast';
import Loading from 'react-fullscreen-loading';

export default function Signin(){
    const navigate = useNavigate();
    const [screenloader,setScreenloader] = useState(false);

    const [email,setEmail] = useState("");
    const [password,setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const login=async()=>{
        await axios.post(`${process.env.REACT_APP_API_URL}/auth/signin`,{email,password})
        .then(res=>{
            setScreenloader(false);
            if(res.data.status===true){
              toast.success("Login Successful!");
              localStorage.setItem("accessToken", res.headers['access-token']);
              localStorage.setItem("refreshToken", res.headers['refresh-token']);
              localStorage.setItem("userType", res.data.userType);
              localStorage.setItem("email",email);
              if(res.data.userType === "user"){
                navigate(`/user/licenses`, { replace: true });
              }else{
                navigate(`/admin/dashboard`, { replace: true });
              }
              return;
          }else{
              toast.error(res.data.message);
              return;
          }
        })
        .catch(er=>{
            setScreenloader(false);
            console.log(er)
            toast.error("Unable to connect to Server");
            return;
        })
    }

    const Submit=async(e)=>{
        e.preventDefault();
        setScreenloader(true);
        await login();
    }
    const toggleShowPassword = () => {
      setShowPassword(!showPassword);
    };
    return(
        <>
        <Loading loading={screenloader} background="#fff" loaderColor="#883484" />
        <NavigationBar/>
        <div className="nav_fix vh-100 d-flex flex-column justify-content-center align-items-center"  style={{ backgroundColor: '#CBD2D9' }}>
            <Card className="shadow col-10 col-md-6 mx-auto">
              <Card.Body className="d-flex flex-column justify-content-center align-items-center">
                <div className="mb-3 mt-md-2">
                  <h2 className="fw-bold mb-2 text-uppercase ">Cadomation</h2>
                  <p className=" mb-2">Please enter your email and password!</p>
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <Form onSubmit={e=>Submit(e)}>
                      <Form.Group className="mb-3" controlId="email">
                        <Form.Label className="text-center">
                          Email address
                        </Form.Label>
                        <Form.Control 
                          type="email" 
                          placeholder="Enter email" 
                          required value={email} 
                          onChange={e=>setEmail(e.target.value)}/>
                      </Form.Group>

                      <Form.Group
                        className="mb-3"
                        controlId="password"
                      >
                        <Form.Label>Password</Form.Label>
                        <Form.Control 
                          type={showPassword ? "text" : "password"} 
                          placeholder="Password" 
                          required 
                          value={password} 
                          onChange={e=>setPassword(e.target.value)}/>
                        <Form.Check
                          type="checkbox"
                          label="Show Password"
                          onChange={toggleShowPassword}
                          id="showPassword"
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="forgotPassword"
                      >
                        <p className="small">
                        <span className="link_like" style={{ color: '#2e2e67' }} onClick={()=>{navigate(`/forgotpasswordrequest`, { replace: true })}}>
                            Forgot password?
                          </span>
                        </p>
                      </Form.Group>
                      <div className="d-grid">
                        <Button variant="primary" type="submit"  style={{ backgroundColor: '#2D3748', borderColor: '#2D3748' }}>
                          Login
                        </Button>
                      </div>
                    </Form>
                    <div className="mt-2">
                      <p className="mb-0  text-center">
                        Don't have an account?{" "}
                        <span onClick={() => { navigate(`/signup`, { replace: true }) }} className="link_like fw-bold" style={{ color: '#2D3748' }}>
                          Sign Up
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
        </div>
        <Footer/>
        </>
    )
}
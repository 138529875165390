// import "./UserLeftsidebar.css";
import React from "react";
import { NavLink } from "react-router-dom";
import { LicenseManagement } from "./UserSidebarData.js";
import { FaHome } from "react-icons/fa";

/* user box */
const UserBox = (props) => {
  // get the profilemenu

  // const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <div className='user-box text-center'>
      {/* <img
        src={profileImg}
        alt=''
        title=''
        className='rounded-circle avatar-md'
      /> */}
      <p className='cursor-pointer text-dark h5 mt-0 mb-1 d-block'>
        {props.username}
      </p>
    </div>
  );
};

/* sidebar content */
const UserSideBarContent = (props) => {
  return (
    <>
      <UserBox username={props.username} />

      <div id='sidebar-menu' className="ScrollY">
        <ul className='side-menu ' id='side-menu' >

          <li>
            <NavLink to='/' style={{ textDecoration: 'none' }} activeclassname="active">
              <FaHome />
              <span> Dashboard </span>
            </NavLink>
          </li>

          <li className='menu-title mt-0' style={{fontWeight:'bold'}}>License Management</li>
          {LicenseManagement.map((item, index) => {
            return (
              // <li key={index} className='side-nav-item icon-clr'>
              //   <Link to={item.path} className='side-nav-link-ref icon-clr' style={{textDecoration:'none'}}>
              //     <i className='feather feather-calendar'>{item.icon}</i>
              //     <span> {item.title} </span>
              //   </Link>
              // </li>
              <li key={index} className='side-nav-item icon-clr'>
                <NavLink to={item.path} className='side-nav-link-ref icon-clr' style={{ textDecoration: 'none' }} activeclassname="active">
                  <i className='feather feather-calendar'>{item.icon}</i>
                  <span> {item.title} </span>
                </NavLink>
              </li>
            );
          })}

          


          {/* <li className='menu-title mt-0' style={{fontWeight:'bold'}}>Payment Management</li>
          {PaymentManagement.map((item, index) => {
            return (
              <li key={index} className='side-nav-item icon-clr'>
                <Link to={item.path} className='side-nav-link-ref icon-clr' style={{textDecoration:'none'}}>
                  <i className='feather feather-calendar'>{item.icon}</i>
                  <span> {item.title} </span>
                </Link>
              </li>
            );
          })}
     */}
          {/* <li className='menu-title mt-0' style={{fontWeight:'bold'}}>Marketing</li>
          {Marketing.map((item, index) => {
            return (
              <li key={index} className='side-nav-item icon-clr'>
                <Link to={item.path} className='side-nav-link-ref icon-clr' style={{textDecoration:'none'}}>
                  <i className='feather feather-calendar'>{item.icon}</i>
                  <span> {item.title} </span>
                </Link>
              </li>
            );
          })} */}
        </ul>
      </div>
    </>
  );
};


export default UserSideBarContent;

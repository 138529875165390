import React, { useEffect, useState } from "react";
import axios from 'axios';
import Loading from 'react-fullscreen-loading';
import { toast } from "react-hot-toast";
import DataTable from 'react-data-table-component';
import ShowTime from "../../../utils/ShowTime/ShowTime";
import MapProduct from "../../../utils/ProductMapper/MapProduct";
import CustomLoader from "../../../utils/DataTableLoader/CustomLoader";
import UserDashboardLayout from "../../../Components/UserDashboardLayout/UserDashboardLayout";

export default function Orders() {
  const [screenloader, setScreenloader] = useState(true);

  const [TableData, setTableData] = useState([]);
  const [TotalRecords, setTotalRecords] = useState();
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);
  const [sortBy,setSortBy] = useState(null);
  const [sortOrder,setSortOrder] = useState(null);
  const [pending, setPending] = useState(true);


  const columns = [
    {
      name: 'Order Id',
      selector: row => (row.cad_generated_id),
      sortField: "cad_generated_id",
      sortable: true,
      cell: row=>(<div style={{ whiteSpace: 'pre-wrap' }}>{row.cad_generated_id}</div>),
    },
    {
      name: 'Amount',
      selector: row => (row.amount),
      sortField: "amount",
      sortable: true,
      cell: row=>(<div style={{ whiteSpace: 'pre-wrap' }}>{row.amount}</div>),
    },
    {
      name: 'Status',
      selector: row => (row.status),
      sortField: "status",
      sortable: true,
      cell: row=>(row.status),
    },
    {
      name: 'Created At',
      selector: row => row.created_at,
      sortField: "created_at",
      sortable: true,
      cell: row => <div style={{ whiteSpace: 'pre-wrap' }}>{ShowTime(row.created_at)}</div>,
    },
    {
      name: 'PID',
      selector: row => row.pid,
      sortField: "pid",
      sortable: true,
      cell: row => <div style={{ whiteSpace: 'pre-wrap' }}>{MapProduct(row.pid)}</div>,
    },
    
  ];

  const getList = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/order/index`, {
        params: {
          limit: pageSize,
          page: page,
          sort_by:sortBy,
          sort_order:sortOrder,
        },
        headers: {
          'authorization': localStorage.getItem('accessToken'),
        },
      });
      setScreenloader(false);
      setPending(false);
      if (response.data.status) {
        const total = response.data.meta.pagination.total;
        setTotalRecords(total);
        setTableData(response.data.orders);
      } else {
        toast.error('Something Went Wrong');
        console.log(response.data);
      }
    } catch (error) {
      setScreenloader(false);
      setPending(false);
      if (error.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        console.error(error);
        toast.error('Something Went Wrong');
      }
    }
  };
  
  useEffect(() => {
    setPending(true);
    getList();
  }, [ page, pageSize, sortBy, sortOrder]);
 

  return (
    <div>
      <UserDashboardLayout/>
      <Loading loading={screenloader} background="#fff" loaderColor="#883484" />
      <div className='content-page-bg'>
        <div className='content-page'>
          <div className='content'>

            <div className="row">
              <h2>Orders</h2>
            </div>

            <div className="row d-flex d-sm-none">
              <p>Total Records: <strong>{TotalRecords}</strong></p>
            </div>
            {console.log(TableData)}
            <div className="table-wrapper">
              <DataTable
                //data
                columns={columns}
                data={TableData}
                //style
                dense
                direction="auto"
                highlightOnHover
                noHeader
                persistTableHead
                pointerOnHover
                responsive
                subHeaderAlign="right"
                subHeaderWrap
                progressPending={pending}
                progressComponent={<CustomLoader />}
                //pagination
                pagination
                paginationServer 
                paginationPerPage={pageSize} 
                paginationTotalRows={TotalRecords} 
                paginationRowsPerPageOptions={[10,20,50,100,150]}
                onChangePage={(page) => setPage(page)} 
                onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {setPageSize(currentRowsPerPage); setPage(currentPage)}} 
                sortServer
                onSort={(column, sortDirection) => {setSortBy(column.sortField); setSortOrder(sortDirection);}}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}    
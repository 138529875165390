import AdminDashboardLayout from "../../../Components/AdminDashboard/AdminDashboardLayout";
import Loading from 'react-fullscreen-loading';
import React, { useState } from "react";

import axios from 'axios';
import { toast } from "react-hot-toast";
import Composing from "./Composing";
import EmailSelection from "./EmailSelection";
import ResponseView from "./ResponseView";


export default function SendEmail(){
    const [screenloader,setScreenloader] = useState(false);
    const [CurrentScreen,setCurrentScreen] = useState("Write Email");

    const [senderEmail,setSenderEmail] = useState("sales@cadomation.com");
    const [subject,setSubject] = useState("")
    const [content, setContent] = useState("");
    const [emailsList,setEmailsList] = useState([]);

    const [response,setResponse] = useState();


    const Selector=()=>{
        if(CurrentScreen==="Write Email"){
            return(<Composing
                senderEmail={senderEmail}
                setSenderEmail={setSenderEmail}
                subject={subject}
                setSubject={setSubject}
                content={content}
                setContent={setContent}
                setCurrentScreen={setCurrentScreen}
            />)
        }
        else if(CurrentScreen==="Select Emails"){
            return(<EmailSelection 
                setScreenloader={setScreenloader}
                setCurrentScreen={setCurrentScreen}
                emailsList={emailsList}
                setEmailsList={setEmailsList}
                SendEmail={SendEmail}
                response={response}
                />)
        }
        else if(CurrentScreen==="View Response"){
            return(<ResponseView 
                setCurrentScreen={setCurrentScreen}
                response={response}
                />)
        }
    }

     const SendEmail = async () => {
        setScreenloader(true);
        try {
          const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/marketing/sendemails`, {
            senderEmail: senderEmail,
            emails: emailsList,
            body: content,
            subject: subject
          }, {
            headers: {
              'authorization': localStorage.getItem('accessToken'),
            },
          });
          // console.log(response.data);
          if (response.data.status === 'success') {
            setResponse(response.data);
            toast.success(`Emails sent!`);
            setCurrentScreen("View Response");
            setScreenloader(false);
            // const acceptedCount = response.data.message[0]?.accepted.length || 0;
            // const rejectedCount = response.data.message[0]?.rejected.length || 0;
            // if (rejectedCount > 0) {
            //   toast.error(`${rejectedCount} Emails failed!`);
            // }
            
          } else {
            setScreenloader(false);
            toast.error("Email sending Failed!");
          }
          
        } catch (error) {
          setScreenloader(false);
          if (error.response && error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
          } else {
            console.error(error);
            toast.error("Something Went Wrong");
          }
        }
      };
    

    return(
    <div>
      <AdminDashboardLayout />
      <Loading loading={screenloader} background="#fff" loaderColor="#883484" />
      <div className='content-page-bg'>
        <div className='content-page'>
          <div className='content'>
            {Selector()}
          </div>
        </div>
      </div>
    </div>
    )
}
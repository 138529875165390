import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { Toaster } from 'react-hot-toast';

import AdminDashboard from './Pages/Admin/AdminDashboard/AdminDashboard';
// import ShowUser from './Pages/Admin/ShowUser/ShowUser';
import AddLicense from './Pages/Admin/AddLicense/AddLicense';
import AllLicenses from './Pages/Admin/AllLicenses/AllLicenses';
// import CompletedPayments from './Pages/Admin/CompletedPayments/CompletedPayments';
// import GeneratedPayments from './Pages/Admin/GeneratedPayments/GeneratedPayments';
import ActiveProducts from './Pages/Admin/ActiveProducts/ActiveProducts';
import ShowProduct from './Pages/Admin/ShowProduct/ShowProduct';
import SendEmail from './Pages/Admin/SendEmail/SendEmail';

import NotFound from './Pages/Public/NotFound/NotFound';


import { AdminRoute } from './authentication/AdminRoutes';
// import ProductReleaseNotes from './Pages/Admin/ProductReleaseNotes/ProductReleaseNotes';
// import SPCADFeatures from './Pages/Public/Products/SPCAD/SPCADFeatures';
import TrialLicenses from './Pages/Admin/TrialLicenses/TrialLicenses';
import Signin from './Pages/User/Auth/Signin/Sigin';
import UserDashboard from './Pages/User/UserDashboard/UserDashboard';
import Signup from './Pages/User/Auth/Signup/Signup';
import VerifyEmail from './Pages/User/Auth/VerifyEmail/VerifyEmail';
import ForgotPasswordRequest from './Pages/User/Auth/ForgotPassword/ForgotPasswordRequest';
import ChangePassword from './Pages/User/Auth/ForgotPassword/ChangePassword';
import Licenses from './Pages/User/Licenses/Licenses';
import { UserRoute } from './authentication/UserRoute';
import AttachedMachines from './Pages/User/AttachedMachines/AttachedMachines';
import BuyLicense from './Pages/User/BuyLicense/BuyLicense';
import ShowUserTrial from './Pages/Admin/ShowUserTrial/ShowUserTrial';
import GenerateOrder from './Pages/User/GenerateOrder/GenerateOrder';
import Orders from './Pages/User/Orders/Orders';
import AdminSignin from './Pages/Admin/Auth/Signin/AdminSignin';
import RequestQuote from './Pages/User/RequestQuote/RequestQuote';
import RequestQuotes from './Pages/Admin/RequestedQuotes/RequestedQuotes';
import AddProduct from './Pages/Admin/AddProduct/AddProduct';
import AssignedMachines from './Pages/Admin/AssignedMachines/AssignedMachines';
import LicenseInvoice from './Pages/Admin/LicenseInvoice/LicenseInvoice';
import EditLicense from './Pages/Admin/EditLicense/EditLicense';
import EditUser from './Pages/Admin/EditUser/EditUser';
import AllUsers from './Pages/Admin/AllUsers/AllUsers';
import AddUser from './Pages/Admin/AddUser/AddUser';
import TrialVerificationCodes from './Pages/Admin/TrialVerificationCodes/TrialVerificationCodes';
import LicenseVerificationCodes from './Pages/Admin/LicenseVerificationCode/LicenseVerificationCode';
import GeneratedOrders from './Pages/Admin/GeneratedOrders/GeneratedOrders';
import CompletedOrders from './Pages/Admin/CompletedOrders/CompletedOrders';
import PostPaymentPaymob from './Pages/Public/PostPaymentPaymob/postPaymentPaymob';
import IndexCoupon from './Pages/Admin/Coupons';
import EditCoupon from './Pages/Admin/Coupons/edit';
import CreateCoupon from './Pages/Admin/Coupons/create';
// import AddProduct from './Pages/AdminPages/AddProduct/AddProduct';

function App() {
  return (
    <Router>
      <Toaster/>
      <Routes>
        <Route  path="/" element={<UserRoute><UserDashboard/></UserRoute>} exact/>
      
        <Route  path="/Signin" element={<Signin/>} exact/>
        <Route  path="/Signup" element={<Signup/>} exact/>
        <Route  path="/VerifyEmail/:email" element={<VerifyEmail/>} exact/>
        <Route  path="/ForgotPasswordRequest" element={<ForgotPasswordRequest/>} exact/>
        <Route  path="/ChangePassword/:email" element={<ChangePassword/>} exact/>
        <Route  path="/postpayment" element={<PostPaymentPaymob/>} exact/>

        <Route  path="/User/Dashboard" element={<UserRoute><UserDashboard/></UserRoute> } exact/>
        <Route  path="/User/licenses" element={<UserRoute><Licenses/></UserRoute>} exact/>
        <Route  path="/User/licenses/:license_id" element={<UserRoute><AttachedMachines/></UserRoute>} exact/>
        {/* <Route  path="/User/BuyLicense" element={<UserRoute><BuyLicense/></UserRoute>} exact/> */}
        {/* <Route  path="/User/BuyLicense/GenerateOrder/:product_id" element={<UserRoute><GenerateOrder/></UserRoute>} exact/> */}
        <Route  path="/User/Orders" element={<UserRoute><Orders/></UserRoute>} exact/>

        <Route  path="/Admin/Dashboard" element={<AdminRoute><AdminDashboard/></AdminRoute>} exact/>
        {/*         
        <Route  path="/Admin/ShowUser/:TargetSecurityKey" element={<AdminRoute><ShowUser/></AdminRoute>} exact/>
        <Route  path="/Admin/CompletedPayments" element={<AdminRoute><CompletedPayments/></AdminRoute>} exact/>
        <Route  path="/Admin/GeneratedPayments" element={<AdminRoute><GeneratedPayments/></AdminRoute>} exact/>
        <Route  path="/Admin/ProductReleaseNotes" element={<AdminRoute><ProductReleaseNotes/></AdminRoute>} exact/> 
        */}
        <Route  path="/Admin/AddLicense" element={<AdminRoute><AddLicense/></AdminRoute>} exact/>
        <Route  path="/Admin/Licenses" element={<AdminRoute><AllLicenses/></AdminRoute>} exact/>
        <Route  path="/Admin/Licenses/:id/AssignedMachines" element={<AdminRoute><AssignedMachines/></AdminRoute>} exact/>
        <Route  path="/Admin/Licenses/:id/edit" element={<AdminRoute><EditLicense/></AdminRoute>} exact/>
        <Route  path="/Admin/Licenses/user/:id/edit" element={<AdminRoute><EditUser/></AdminRoute>} exact/>

        <Route  path="Admin/triallicenses" element={<AdminRoute><TrialLicenses/></AdminRoute>} exact/>
        <Route  path="/Admin/triallicenses/edit/:TargetSecurityKey" element={<AdminRoute><ShowUserTrial/></AdminRoute>} exact/>
        
        <Route  path="/Admin/SendEmail" element={<AdminRoute><SendEmail/></AdminRoute>} exact/>
        <Route  path="/Admin/Quotes" element={<AdminRoute><RequestQuotes/></AdminRoute>} exact/>
       
        <Route  path="/Admin/AddProduct" element={<AdminRoute><AddProduct/></AdminRoute>} exact/>
        <Route  path="/Admin/ActiveProducts" element={<AdminRoute><ActiveProducts/></AdminRoute>} exact/>
        <Route  path="/Admin/ActiveProducts/edit/:id" element={<AdminRoute><ShowProduct/></AdminRoute>} exact/>
        
        <Route  path="/Admin/Licenses/Invoice/:id" element={<AdminRoute><LicenseInvoice/></AdminRoute>} exact/>
        
        <Route  path="/Admin/Users" element={<AdminRoute><AllUsers/></AdminRoute>} exact/>
        <Route  path="/Admin/Users/license/:id/edit" element={<AdminRoute><EditUser/></AdminRoute>} exact/>
        <Route  path="/Admin/AddUser" element={<AdminRoute><AddUser/></AdminRoute>} exact/>
      
        <Route  path="/Admin/Coupons" element={<AdminRoute><IndexCoupon/></AdminRoute>} exact/>
        <Route  path="/Admin/Coupons/:id/edit" element={<AdminRoute><EditCoupon/></AdminRoute>} exact/>
        <Route  path="/Admin/Coupons/new" element={<AdminRoute><CreateCoupon/></AdminRoute>} exact/>

        <Route  path="/Admin/TrialVerificationCodes/" element={<AdminRoute><TrialVerificationCodes/></AdminRoute>} exact/>
        <Route  path="/Admin/LicenseVerificationCodes/" element={<AdminRoute><LicenseVerificationCodes/></AdminRoute>} exact/>

        <Route  path="/Admin/GeneratedOrders/" element={<AdminRoute><GeneratedOrders/></AdminRoute>} exact/>
        <Route  path="/Admin/CompletedOrders/" element={<AdminRoute><CompletedOrders/></AdminRoute>} exact/>

        <Route path='*' element={<NotFound/>} />
      </Routes>
    </Router>
  );
}

export default App;


import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation, useParams } from "react-router-dom";
import {Container,Card,Form,Button} from "react-bootstrap";
import axios from 'axios';
import AdminDashboardLayout from "../../../Components/AdminDashboard/AdminDashboardLayout";
import Loading from 'react-fullscreen-loading';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { toast } from "react-hot-toast";



export default function EditUser() {
  const navigate = useNavigate();

  const [screenloader,setScreenloader] = useState(true);
  const [userRefId,setUserRefId] = useState("");
  const [email,setEmail] = useState("");
  const [fname,setFname] = useState("");
  const [lname,setLname] = useState("");
  const [country,setCountry] = useState("");
  const [organization,setOrganization] = useState("");
  const [verified,setVerified] = useState(0);

  const Param1 = useParams();
  const location = useLocation();
  // eslint-disable-next-line no-unused-vars
  const loc = location.pathname === `/admin/license/user/${Param1}` ? true : false;
  const id = Param1.id;
  

  const getLicenseDetail=async()=>{
      await axios.get(`${process.env.REACT_APP_API_URL}/admin/User/${id}`,{
        headers:{
          'authorization':localStorage.getItem('accessToken'),
        },
      })
      .then(res=>{
          setScreenloader(false);
          if(res.data.status==="success"){
              const data=res.data.user
              setEmail(data.email);
              setFname(data.fname);
              setLname(data.lname);
              setCountry(data.country);
              setOrganization(data.organization);
              setUserRefId(data.user_reference_id);
              setVerified(data.verified);
              return;
          }
          else if(res.data.status==="norecord"){
            toast.success('License not Found!');
            return;
          }else{
            toast.error('Something Went Wrong');
            console.log(res.data);
            return;
          }
      })
      .catch(er=>{
        setScreenloader(false);
        if(er.response.data?.message){
          toast.error(er.response.data.message)
        }else{
          console.log(er)
          toast.error("Something Went Wrong")
        }
      })
  };
  useEffect(()=>{
    getLicenseDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const UpdateLicense=async()=>{
    setScreenloader(true);
    // console.log(id,licenseRefId,product,locked,expiresAt)
    await axios.post(`${process.env.REACT_APP_API_URL}/admin/user/edit`,{
      id:id,
      user_reference_id:userRefId,
      email:email,
      fname:fname,
      lname:lname,
      country:country,
      organization:organization,
      verified:verified
    },{
      headers:{
        'authorization':localStorage.getItem('accessToken'),
      },
    })
    .then(res=>{
        setScreenloader(false);
        if(res.data.status==="success"){
            toast.success("User updated successfully!");
            setTimeout(()=>{
                window.location.reload();
            },400)
            return;
        }
        else if(res.data.status==="failed"){
          toast.success('Could not update User!');
          return;
        }else{
          toast.error('Something Went Wrong');
          console.log(res.data);
          return;
        }
    })
    .catch(er=>{
      setScreenloader(false);
      if(er.response.data?.message){
        toast.error(er.response.data.message)
      }else{
        console.log(er)
        toast.error("Something Went Wrong")
      }
    })
  }

  const Actions=()=>{
      return(
        <>
          <div className='mb-3 text-left'>
               <Button onClick={UpdateLicense} variant="success">Update</Button>
          </div>
          <div className='mb-3 text-left'>
            <Button onClick={()=>{navigate(-1)}} variant="danger">Back</Button>
          </div>
        </>
      )
  }
  return (
    <div>
      <AdminDashboardLayout />
      <Loading loading={screenloader} background="#fff" loaderColor="#883484" />
      <div className='content-page-bg'>
        <div className='content-page'>
          <div className='content'>
          <h1>Edit User</h1>
            <Container fluid className='behind'>
              <Card className='mt-4'>
                <Card.Body>
                <div className='row my-4'>
                    <div className='col-lg-4 col-md-4 col-6'>
                      <Card.Subtitle className='mb-2 text-muted'>User Reference Id</Card.Subtitle>
                      <Form.Control
                        className="fontBold"
                        required
                        placeholder="Enter Reference Id"
                        title="Ref ID"
                        type="number"
                        name="Ref ID"
                        value={userRefId}
                        onChange={(event) => {
                          setUserRefId(event.target.value)
                        }}
                      />
                    </div>
                  </div>
                  <div className='row my-4'>
                    <div className='col-lg-4 col-md-4 col-6'>
                      <Card.Subtitle className='mb-2 text-muted'>Email</Card.Subtitle>
                      <Form.Control
                        className="fontBold"
                        required
                        placeholder="Enter Email"
                        title="PID"
                        type="email"
                        name="email"
                        value={email}
                        onChange={(event) => {
                          setEmail(event.target.value)
                        }}
                      />
                    </div>
                  </div>
                  <div className='row my-4'>
                    <div className='col-lg-4 col-md-4 col-6'>
                      <Card.Subtitle className='mb-2 text-muted'>First Name</Card.Subtitle>
                      <Form.Control
                        type='text'
                        required
                        value={fname}
                        onChange={(event) => {
                          setFname(event.target.value)
                        }}
                      />
                    </div>
                  </div>
                  <div className='row my-4'>
                    <div className='col-lg-4 col-md-4 col-6'>
                      <Card.Subtitle className='mb-2 text-muted'>last Name</Card.Subtitle>
                      <Form.Control
                        type='text'
                        required
                        value={lname}
                        onChange={(event) => {
                          setLname(event.target.value)
                        }}
                      />
                    </div>
                  </div>
                  <div className='row my-4'>
                    <div className='col-lg-4 col-md-4 col-6'>
                      <Card.Subtitle className='mb-2 text-muted'>Country</Card.Subtitle>
                      <Form.Control
                        type='text'
                        required
                        value={country}
                        onChange={(event) => {
                          setCountry(event.target.value)
                        }}
                      />
                    </div>
                  </div>
                  <div className='row my-4'>
                    <div className='col-lg-4 col-md-4 col-6'>
                      <Card.Subtitle className='mb-2 text-muted'>Organization</Card.Subtitle>
                      <Form.Control
                        type='text'
                        required
                        value={organization}
                        onChange={(event) => {
                          setOrganization(event.target.value)
                        }}
                      />
                    </div>
                  </div>
                  <div className='row my-4'>
                    <div className='col-lg-4 col-md-4 col-6'>
                      <Card.Subtitle className='mb-2 text-muted'>Email Verified</Card.Subtitle>
                      <Form.Control
                        type='number'
                        required
                        value={verified}
                        onChange={(event) => {
                          setVerified(event.target.value)
                        }}
                      />
                    </div>
                  </div>
                  {Actions()}
                </Card.Body>
              </Card>
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
}

import { Button } from "react-bootstrap";

export default function ResponseView({ response, setCurrentScreen }) {
  // console.log(response)
  const successfulEmails = response?.message?.filter(
    (msg) => msg.accepted.length > 0
  );
  const unsuccessfulEmails = response?.message?.filter(
    (msg) => msg.rejected.length > 0
  );
  console.log(response.message.length)
  return (
    <>
      <div className="row g-0">
        <div className="col-6 justify-content-start d-flex align-items-center">
          <Button
            className="font-bold"
            size="sm"
            style={{ color: '#ffffff', backgroundColor: '#2e2e67', borderColor: '#2e2e67' }} 
            onClick={() => setCurrentScreen("Select Emails")}
          >
            {"< Back(Edit List)"}
          </Button>
        </div>
        <div className="col-6 justify-content-end d-flex"></div>
      </div>
      <div className="row g-0">
        <h2 className="ps-2">Total Emails: {response.message.length}</h2>
        <div className="m-2 col-12 col-lg-6 justify-content-start align-items-start text-start d-flex flex-column bg-light">
          <h2 className="ps-2">Successful Emails({successfulEmails.length}):</h2>
          <ul>
            {successfulEmails?.map((msg, index) => (
              <p key={index}>{msg.envelope.to},</p>
            ))}
          </ul>
        </div>
        <div className="m-2 ms-lg-5 col-12 col-lg-5 justify-content-start align-items-start text-start d-flex flex-column bg-light">
          <h2 className="ps-2">Unsuccessful Emails({unsuccessfulEmails.length}):</h2>
          <ul>
            {unsuccessfulEmails?.map((msg, index) => (
              <p key={index}>{msg.envelope.to},</p>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}

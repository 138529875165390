
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation, useParams } from "react-router-dom";
import {Container,Card,Form,Button} from "react-bootstrap";
import axios from 'axios';
import AdminDashboardLayout from "../../../Components/AdminDashboard/AdminDashboardLayout";
import Loading from 'react-fullscreen-loading';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { toast } from "react-hot-toast";
import MapProduct from "../../../utils/ProductMapper/MapProduct";



export default function ShowUserTrial() {
  const navigate = useNavigate();

  const [screenloader,setScreenloader] = useState(true);
  const [cardData,setCardData] = useState({});

  const [SecurityKey,setSecurityKey] = useState("");
  const [PID,setPID] = useState("");
    // eslint-disable-next-line no-unused-vars
  const [SecurityKeyUpdate,setSecurityKeyUpdate] = useState(false);
 
  // eslint-disable-next-line no-unused-vars
  const [StartDate,setStartDate] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [StartDateUpdate,setStartDateUpdate] = useState(false);

  const [EndDate,setEndDate] = useState("");
  const [EndDateUpdate,setEndDateUpdate] = useState(false);

  const Param1 = useParams();
  const location = useLocation();
  // eslint-disable-next-line no-unused-vars
  const loc = location.pathname === `/ShowUser/${Param1}` ? true : false;
  const TargetSecurityKey = Param1.TargetSecurityKey;
  

  const getUserDetail=async()=>{
      await axios.get(`${process.env.REACT_APP_API_URL}/admin/triallicense/getuserdetail/${TargetSecurityKey}`,{
        headers:{
          'authorization':localStorage.getItem('accessToken'),
        },
      })
      .then(res=>{
          setScreenloader(false);
          if(res.data.status==="success"){
              setCardData(res.data.license);
              setSecurityKey(res.data.license.machine_id);
              setPID(res.data.license.product_id);
  
              const startdate=DateFormatDispaly(res.data.license.created_at);
              setStartDate(startdate);
              const enddate=DateFormatDispaly(res.data.license.expires_at);
              setEndDate(enddate);
              return;
          }
          else if(res.data.status==="norecord"){
            toast.success('License not Found!');
            return;
          }else{
            toast.error('Something Went Wrong');
            console.log(res.data);
            return;
          }
      })
      .catch(er=>{
        setScreenloader(false);
        if(er.response.data?.message){
          toast.error(er.response.data.message)
        }else{
          console.log(er)
          toast.error("Something Went Wrong")
        }
      })
  };
  useEffect(()=>{
    getUserDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  // eslint-disable-next-line no-unused-vars
  // const UpdateUserDetail=()=>{
  //   setScreenloader(true);
  //   // console.log("update");
  // // eslint-disable-next-line no-unused-vars
  //   if((EndDateUpdate===true || StartDateUpdate===true || SecurityKeyUpdate===true) && PID!=='' && SecurityKey!==''){
  //     axios.post("https://cadomation.com/api/admin/UpdateUserData",{
  //       headers:{Authorization:localStorage.getItem('accessToken')},
  //       AdminCode: localStorage.getItem("adminCode"),
  //       SecurityKey: cardData.machine_id,
  //       NewData:{SecurityKey:SecurityKey,PID:PID,EndDate:EndDate}
  //     })
  //     .then(res=>{
  //         setScreenloader(false);
  //         console.log(res.data);
  //         if (res.data === "tokenExpired") {
  //           // alert("Getting new Authenticaiton tokens! Reload page.");
  //           return;
  //         }
  //         if (res.data === "noToken") {
  //           // alert("Getting new Authenticaiton tokens! Reload page.");
  //           return;
  //         }
  //         if(res.data === 0){
  //           alert('Couldnot Change Status.')
  //           return;
  //         }
  //         if(res.data==="RecordExists"){
  //           alert("The New SecurityKey Already exists with some other user!");
  //           return;
  //         }
  //         if(res.data === 1){
  //           // alert('Status Changed.')
  //           window.location.reload(false);
  //           // navigate(`/ActiveTrials`, { replace: true });
  //           return
  //         }

  //     })
  //     .catch(er=>{
  //         setScreenloader(false);
  //         console.log(er);
  //     })
  //   }else{
  //     setScreenloader(false);
  //     alert('fields canot be empty');
  //   }
  // }
  
  const UpdateUserEndDate=async()=>{
    await axios.post(`${process.env.REACT_APP_API_URL}/admin/triallicense/changeenddate`,{
        machine_id: cardData.machine_id,
        expires_at: EndDate
      },{
        headers:{
          'Authorization':localStorage.getItem('accessToken'),
        }
      })
      .then(res=>{
          setScreenloader(false);
          console.log(res.data);
          if(res.data.status===1){
            toast.success(res.data.message);
            setTimeout(()=>{
              window.location.reload();
            },1000)
          }
          else{
            toast.error('Something Went Wrong');
            console.log(res.data);
            return;
          }
      })
      .catch(er=>{
        setScreenloader(false);
        if(er.response.data?.message){
          toast.error(er.response.data.message);
          return;
        }else{
          console.log(er);
          toast.error("Something Went Wrong");
          return;
        }
      })
  };
  const DeleteUser=async ()=>{
    await axios.post(`${process.env.REACT_APP_API_URL}/admin/DeleteUserLicense`,{
        SecurityKey: cardData.machine_id,
      },{
        headers:{
          'Authorization':localStorage.getItem('AccessToken'),
          'API-Key': process.env.REACT_APP_ADMINAPI_KEY
        }
      })
      .then(res=>{
          setScreenloader(false);
          // console.log(res.data);
          if(res.data.Status==="Success"){
            toast.success('License Deleted');
            setTimeout(()=>{
              navigate(`/Admin/Licenses`, { replace: true });
            },1000)
          }
          else{
            toast.error('Something Went Wrong');
            console.log(res.data);
            return;
          }
      })
      .catch(er=>{
        setScreenloader(false);
        if(er.response.data?.message){
          toast.error(er.response.data.message)
        }else{
          console.log(er)
          toast.error("Something Went Wrong")
        }
      })
  };
  const DeleteClicked=()=>{
    confirmAlert({
      title: 'Confirm to Delete',
      message: 'Are you sure to do this. This Canot be reverted.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {setScreenloader(true);DeleteUser();}
        },
        {
          label: 'No',
          // onClick: () => alert('')
        }
      ]
    });
  }
  const Displaydate=(date)=>{
    const d1 = new Date(date).toDateString();
    return(d1);
  }
  const DateFormatDispaly=(d)=>{
    const t = new Date(d);
    const date = ('0' + t.getDate()).slice(-2);
    const month = ('0' + (t.getMonth() + 1)).slice(-2);
    const year = t.getFullYear();
    return(`${year}-${month}-${date}`);
  };

  const Actions=()=>{
    if(StartDateUpdate===false && EndDateUpdate===false && SecurityKeyUpdate===false){
      return(
        <>
          <div className='mb-3 text-left'>
            <Button onClick={()=>{navigate(-1)}} variant="danger">Back</Button>
          </div>
        </>
      )
    }
    if(EndDateUpdate===true||StartDateUpdate===true||SecurityKeyUpdate===true){
      return(
          <>
            <div className='mb-3 text-left'>
              <Button onClick={UpdateUserEndDate} variant="success">Update</Button>
            </div>
            <div className='mb-3 text-left'>
              <Button onClick={()=>{window.location.reload(false)}} variant="danger">Cancel</Button>
            </div>
          </>
      )
    }
  }
  // const HandleChange=(e)=>{
  //   setSecurityKeyUpdate(true);
  //   const value=e.target.value;
  //   const name=e.target.name;
  //   if(name==="SecurityKey"){
  //     setSecurityKey(value);
  //   }
  //   else if(name==="PID"){
  //     setPID(value);
  //     SecurityKey(Keyparts[0]+'@@@'+value);   
  //   }
  // };

  // const SecurityKeyButton=()=>{
  //   if(SecurityKeyUpdate===true){
  //     return(
  //       <>
  //         <div className='mb-3 mt-3 text-left'>
  //           <Button onClick={UpdateUserEndDate} variant="success">Update SecurityKey and PID</Button>
  //         </div>
  //       </>
  //     )
  //   }
  // };
  // const StartDateButton=()=>{
  //   if(StartDateUpdate===true){
  //     return(
  //       <>
  //         <div className='mb-3 text-left'>
  //           <Button onClick={UpdateUserEndDate} variant="success">Update Start Date</Button>
  //         </div>
  //       </>
  //     )
  //   }
  // };
  // const EndDateButton=()=>{
  //   if(EndDateUpdate===true){
  //     return(
  //       <>
  //         <div className='mb-3 text-left'>
  //           <Button onClick={UpdateUserEndDate} variant="success">Update End Date</Button>
  //         </div>
  //       </>
  //     )
  //   }
  // };

  return (
    <div>
      <AdminDashboardLayout />
      <Loading loading={screenloader} background="#fff" loaderColor="#883484" />
      <div className='content-page-bg'>
        <div className='content-page'>
          <div className='content'>
            <Container fluid className='behind'>
              <Card className='mt-4'>
                <Card.Body>
                {/* <div className='mb-3 text-left'>
                  <Button onClick={DeleteClicked} variant="danger">Delete This License</Button>
                </div> */}
                  <div className='row my-4'>
                    <div className='col-lg-4 col-md-4 col-6'>
                      <Card.Subtitle className='mb-2 text-muted'>
                        Name
                      </Card.Subtitle>
                      <Card.Title className="text-muted">{cardData.fname} {cardData.lname}</Card.Title>
                    </div>
                  </div>
                  <div className='row my-4'>
                    <div className='col-lg-4 col-md-4 col-12 col-sm-6'>
                      <Card.Subtitle className='mb-2 text-muted'>
                        Email
                      </Card.Subtitle>
                      <Card.Title className="text-muted">{cardData.email}</Card.Title>
                    </div>
                  </div>
                  <div className='row my-4'>
                    <div className='col-lg-4 col-md-4 col-12 col-sm-6'>
                      <Card.Subtitle className='mb-2 text-muted'>
                        Country
                      </Card.Subtitle>
                      <Card.Title className="text-muted">{cardData.country}</Card.Title>
                    </div>
                  </div>
                  <div className='row my-4'>
                    <div className='col-lg-4 col-md-4 col-12 col-sm-6'>
                      <Card.Subtitle className='mb-2 text-muted'>
                        Organization
                      </Card.Subtitle>
                      <Card.Title className="text-muted">{cardData.organization}</Card.Title>
                    </div>
                  </div>
                  <div className='row my-4'>
                    <div className='col-lg-4 col-md-4 col-12 col-sm-6'>
                      <Card.Subtitle className='mb-2 text-muted'>
                        Security Key
                      </Card.Subtitle>
                      <Card.Title className="text-muted">{cardData.machine_id}</Card.Title>
                    </div>
                  </div>
                  <div className='row my-4'>
                    <div className='col-lg-4 col-md-4 col-12 col-sm-6'>
                      <Card.Subtitle className='mb-2 text-muted'>
                        PID
                      </Card.Subtitle>
                      <Card.Title className="text-muted">{MapProduct(cardData.product_id)}</Card.Title>
                    </div>
                  </div>
                  {/* <div className='row my-4'>
                    <div className='col-lg-4 col-md-4 col-6'>
                      <Card.Subtitle className='mb-2 text-muted'>
                        SecurityKey
                      </Card.Subtitle>
                      <Form.Control
                      className="fontBold"
                      required
                      placeholder="Enter Securitykey"
                      title="Security Key"
                      type="text"
                      name="SecurityKey"
                      value={SecurityKey}
                      onChange={HandleChange}
                      />
                    </div>
                  </div>
                  <div className='row my-4'>
                    <div className='col-lg-4 col-md-4 col-6'>
                      <Card.Subtitle className='mb-2 text-muted'>
                        PID
                      </Card.Subtitle>
                      <Form.Control 
                        className="fontBold"
                        required
                        placeholder="Enter Product ID"
                        title="PID"
                        type="text"
                        name="PID"
                        value={PID}
                        onChange={HandleChange}
                      />
                    </div>
                  </div> */}
                  
                  <div className='row my-4'>
                    
                    <div className='col-lg-4 col-md-4 col-6'>
                      <Card.Subtitle className='mb-2 text-muted'>Start Date</Card.Subtitle>
                      <Card.Title className="text-muted">{Displaydate(cardData.created_at)}</Card.Title>
                      {/* <Form.Control
                        type='date'
                        required
                        value={StartDate}
                        onChange={(event) => {
                          setStartDateUpdate(true);
                          setStartDate(event.target.value);
                        }}
                      /> */}
                      {/* {StartDateButton()} */}
                    </div>
                    
                  </div>
                  <div className='row my-4'>
                    <div className='col-lg-4 col-md-4 col-6'>
                      <Card.Subtitle className='mb-2 text-muted'>Change End Date</Card.Subtitle>
                      <Form.Control
                        type='date'
                        required
                        value={EndDate}
                        onChange={(event) => {
                          setEndDateUpdate(true);
                          setEndDate(event.target.value);
                        }}
                      />
                      {/* {EndDateButton()} */}
                    </div>
                  </div>

                  <div className='row my-4'>
                    <div className='col-lg-4 col-md-4 col-6'>
                      <Card.Subtitle className='mb-2 text-muted'>
                        Status
                      </Card.Subtitle>
                      <Card.Title>{cardData.status}</Card.Title>
                    </div>
                  </div>
                  
                  {/* <div className='mb-3 text-left'>
                    <Button onClick={()=>{navigate(`/Admin/ActiveTrials`, { replace: true })}} variant="danger">Back</Button>
                  </div> */}
                  
                  {Actions()}
                </Card.Body>
              </Card>
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
}

import "./Dashboard.css";
import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import FeatherIcon from "feather-icons-react";
import ProfileDropdown from "../ProfileDropdown";
import SideBarContent from "./AdminLeftSidebar";
import logo from "../../assets/Logo/Cgear_Black.webp";

const ProfileMenus = [
  {
    label: "Logout",
    icon: "log-out",
    redirectTo: "/",
  },
];

const AdminDashboardLayout = (props) => {
  const [sidebar, setSidebar] = useState(true);
  const showSidebar = () => {
    setSidebar(!sidebar);
    // Toggle class on content-page when sidebar state changes
    const contentPage = document.querySelector(".content-page");
    if (contentPage) {
      contentPage.classList.toggle("collapsed-sidebar", sidebar);
    }
  };
  return (
    <React.Fragment>
      <div>
        <div className='navbar-custom'>
          <div className='container-fluid p-0'>
            <div className='logo-box'>
              <Link to='/admin/dashboard' className='logo logo-dark'>
                <span className='logo-sm'>
                  <img src={logo} alt='' height='24' />
                </span>
                <span className='logo-lg text-center'>
                  <img src={logo} alt='' height='50' />
                </span>
              </Link>
            </div>

            <ul className='list-unstyled topnav-menu float-end mb-0'>
              <li className='dropdown notification-list topbar-dropdown'>
                <ProfileDropdown
                  profilePic={logo}
                  menuItems={ProfileMenus}
                  username={localStorage.getItem('email')?localStorage.getItem('email'):'Admin'}
                />
              </li>
            </ul>

            <ul className='list-unstyled topnav-menu topnav-menu-left m-0'>
              <li>
                <button className='button-menu-mobile' onClick={showSidebar}>
                  <FeatherIcon icon='menu' />
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div
          className={`left-side-menu front  ${
            sidebar ? "activeDash" : "hideSideBar"
          }`}
        >
          <SideBarContent username={localStorage.getItem('userType')} />
        </div>
      </div>
    </React.Fragment>
  );
};
export default AdminDashboardLayout;

import React, { useEffect, useState} from "react";
import axios from 'axios';
import Loading from 'react-fullscreen-loading';
import { toast } from "react-hot-toast";
import DataTable from 'react-data-table-component';
import ShowTime from "../../../utils/ShowTime/ShowTime";
import CustomLoader from "../../../utils/DataTableLoader/CustomLoader";
import UserDashboardLayout from "../../../Components/UserDashboardLayout/UserDashboardLayout";
import { Button } from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import { Tooltip } from 'react-tooltip'

export default function AttachedMachines() {
  const [screenloader, setScreenloader] = useState(true);

  const [TableData, setTableData] = useState([]);
  const [TotalRecords, setTotalRecords] = useState();
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);
  const [sortBy,setSortBy] = useState(null);
  const [sortOrder,setSortOrder] = useState(null);
  const [pending, setPending] = useState(true);

  const Param1 = useParams();
  const location = useLocation();
  // eslint-disable-next-line no-unused-vars
  const loc = location.pathname === `/user/licenses/${Param1}` ? true : false;
  const id = Param1.license_id;
  const columns = [
    { 
      name: "Actions", 
      sortable: false, 
      cell: row=>(<> {row.removed ? "Currently in use, will be removed once user close app":
                    <Button
                    data-tooltip-id="remove-machine"
                    data-tooltip-content="remove this machine"
                    data-tooltip-place="bottom"
                    className="bg-none my-1"
                    variant="danger"
                    onClick={()=>showConfirmAlert(row.id)}
                    >
                    Remove
                  </Button>}
      </>)
    },
    {
      name: 'Machine User',
      selector: row => (row.machine_user),
      sortField: "machine_user",
      sortable: true,
      cell: row=>(<div style={{ whiteSpace: 'pre-wrap' }}>{row.machine_user}</div>),
    },
    {
      name: 'Last Seen',
      selector: row =>row.last_seen,
      sortField: "last_seen",
      sortable: true,
      cell: row => <div style={{ whiteSpace: 'pre-wrap' }}>{ShowTime(row.last_seen)}</div>,
    },
    
  ];

  const showConfirmAlert = (id) => {
    confirmAlert({
        title: 'Confirm',
        message: `The machine will be removed from this license and need to login again to use the license. Are you sure you want to continue?`,
        buttons: [
            {
                label: 'Yes',
                onClick: () => RemoveMachine(id)
            },
            {
                label: 'No',
                onClick: () => {}
            }
        ]
    });
  };

  const RemoveMachine=async(id)=>{
    setScreenloader(true);
    await axios.get(`${process.env.REACT_APP_API_URL}/user/license/removemachine/${id}`,{
      headers:{
        'authorization':localStorage.getItem('accessToken'),
      },
    })
    .then(res=>{
        if(res.data && res.data.status===1){
          window.location.reload();
        }
        else if(res.data.status===0){
          setScreenloader(false);
          toast.success('Unable to remove machine at the time!');
          return;
        }else{
          toast.error('Something Went Wrong');
          console.log(res.data);
          return;
        }
    })
    .catch(er=>{
      setScreenloader(false);
      if(er.response.data?.message){
        toast.error(er.response.data.message);
        return;
      }else{
          console.log(er);
          toast.error("Something Went Wrong");
          return;
      }
    })
  }

  const getList = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/license/machines/${id}`, {
        params: {
          limit: pageSize,
          page: page,
          sort_by:sortBy,
          sort_order:sortOrder,
        },
        headers: {
          'authorization': localStorage.getItem('accessToken'),
        },
      });
      setScreenloader(false);
      setPending(false);
      if (response.data.status) {
        const total = response.data.meta.pagination.total;
        setTotalRecords(total);
        setTableData(response.data.machines);
      } else {
        toast.error('Something Went Wrong');
        console.log(response.data);
      }
    } catch (error) {
      setScreenloader(false);
      setPending(false);
      if (error.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        console.error(error);
        toast.error('Something Went Wrong');
      }
    }
  };
  
  useEffect(() => {
    setPending(true);
    getList();
  }, [ page, pageSize, sortBy, sortOrder]);
 

  return (
    <div>
      <UserDashboardLayout/>
      <Loading loading={screenloader} background="#fff" loaderColor="#883484" />
      <div className='content-page-bg'>
        <div className='content-page'>
          <div className='content'>

            <div className="row">
              <h2> Attached Machines </h2>
            </div>

            <div className="row d-flex d-sm-none">
              <p>Total Records: <strong>{TotalRecords}</strong></p>
            </div>
            {console.log(TableData)}
            <div className="table-wrapper">
              <DataTable
                //data
                columns={columns}
                data={TableData}
                //style
                dense
                direction="auto"
                highlightOnHover
                noHeader
                persistTableHead
                pointerOnHover
                responsive
                subHeaderAlign="right"
                subHeaderWrap
                progressPending={pending}
                progressComponent={<CustomLoader />}
                //pagination
                pagination
                paginationServer 
                paginationPerPage={pageSize} 
                paginationTotalRows={TotalRecords} 
                paginationRowsPerPageOptions={[10,20,50,100,150]}
                onChangePage={(page) => setPage(page)} 
                onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {setPageSize(currentRowsPerPage); setPage(currentPage)}} 
                sortServer
                onSort={(column, sortDirection) => {setSortBy(column.sortField); setSortOrder(sortDirection);}}
              />
            </div>
          </div>
        </div>
      </div>
      <Tooltip id="remove-machine"  style={{ zIndex: 99 }} />
    </div>
  );
}    
import React, { useEffect, useState } from "react";
import axios from 'axios';
import Loading from 'react-fullscreen-loading';
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import DataTable from 'react-data-table-component';
import ShowTime from "../../../utils/ShowTime/ShowTime";
import MapProduct from "../../../utils/ProductMapper/MapProduct";
import {FaLaptopMedical} from "react-icons/fa";
import CustomLoader from "../../../utils/DataTableLoader/CustomLoader";
import UserDashboardLayout from "../../../Components/UserDashboardLayout/UserDashboardLayout";
import { Tooltip } from 'react-tooltip'


export default function Licenses() {
  const navigate = useNavigate();
  const [screenloader, setScreenloader] = useState(true);

  const [TableData, setTableData] = useState([]);
  const [TotalRecords, setTotalRecords] = useState();
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);
  const [sortBy,setSortBy] = useState(null);
  const [sortOrder,setSortOrder] = useState(null);
  const [pending, setPending] = useState(true);


  const columns = [
    { 
      name: "Actions", 
      sortable: false, 
      cell: row=>(<FaLaptopMedical
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="view attached machines"
                    data-tooltip-place="bottom"
                    className="edit-icon"
                    onClick={() => {
                      navigate(`/user/licenses/${row.license_reference_id}`, {
                        replace: false,
                      });
                    }}
                  />)
    },
    {
      name: 'License Reference Id',
      selector: row => (row.license_reference_id),
      sortField: "license_reference_id",
      sortable: true,
      cell: row=>(<div style={{ whiteSpace: 'pre-wrap' }}>{row.license_reference_id}</div>),
    },
    {
      name: 'In Use Machines',
      selector: row => (row.locked),
      sortField: "locked",
      sortable: true,
      cell: row=>(<div style={{ whiteSpace: 'pre-wrap' }}>{row.locked?"In Use":"Available"}</div>),
    },
    {
      name: 'Machine User',
      selector: row => (row.machine_user),
      sortField: "machine_user",
      sortable: true,
      cell: row=>(<div style={{ whiteSpace: 'pre-wrap' }}>{row.machine_user?row.machine_user:"-"}</div>),
    },
    {
      name: 'Status',
      selector: row => (row.status),
      sortField: "status",
      sortable: true,
      cell: row=>(row.status?"Active":"Expired"),
    },
    {
      name: 'Last Seen',
      selector: row =>row.last_seen,
      sortField: "last_seen",
      sortable: true,
      cell: row => <div style={{ whiteSpace: 'pre-wrap' }}>{row.last_seen?ShowTime(row.last_seen):"-"}</div>,
    },
    {
      name: 'Created At',
      selector: row => row.created_at,
      sortField: "created_at",
      sortable: true,
      cell: row => <div style={{ whiteSpace: 'pre-wrap' }}>{ShowTime(row.created_at)}</div>,
    },
    {
      name: 'Expires At',
      selector: row => row.expires_at,
      sortField: "expires_at",
      sortable: true,
      cell: row => <div style={{ whiteSpace: 'pre-wrap' }}>{ShowTime(row.expires_at)}</div>,
    },
    {
      name: 'Product',
      selector: row => row.product,
      sortField: "product",
      sortable: true,
      cell: row => <div style={{ whiteSpace: 'pre-wrap' }}>{row.product?MapProduct(row.product):"-"}</div>,
    },
    
  ];

  const getList = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/license/index`, {
        params: {
          limit: pageSize,
          page: page,
          sort_by:sortBy,
          sort_order:sortOrder,
        },
        headers: {
          'authorization': localStorage.getItem('accessToken'),
        },
      });
      setScreenloader(false);
      setPending(false);
      if (response.data.status) {
        const total = response.data.meta.pagination.total;
        setTotalRecords(total);
        setTableData(response.data.licenses);
      } else {
        toast.error('Something Went Wrong');
        console.log(response.data);
      }
    } catch (error) {
      setScreenloader(false);
      setPending(false);
      if (error.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        console.error(error);
        toast.error('Something Went Wrong');
      }
    }
  };
  
  useEffect(() => {
    setPending(true);
    getList();
  }, [ page, pageSize, sortBy, sortOrder]);
 

  return (
    <div>
      <UserDashboardLayout/>
      <Loading loading={screenloader} background="#fff" loaderColor="#883484" />
      <div className='content-page-bg'>
        <div className='content-page'>
          <div className='content'>

            <div className="row">
              <h2>Licenses</h2>
            </div>

            <div className="row d-flex d-sm-none">
              <p>Total Records: <strong>{TotalRecords}</strong></p>
            </div>
            <div className="table-wrapper">
              <DataTable
                //data
                columns={columns}
                data={TableData}
                //style
                dense
                direction="auto"
                highlightOnHover
                noHeader
                persistTableHead
                pointerOnHover
                responsive
                subHeaderAlign="right"
                subHeaderWrap
                progressPending={pending}
                progressComponent={<CustomLoader />}
                //pagination
                pagination
                paginationServer 
                paginationPerPage={pageSize} 
                paginationTotalRows={TotalRecords} 
                paginationRowsPerPageOptions={[10,20,50,100,150]}
                onChangePage={(page) => setPage(page)} 
                onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {setPageSize(currentRowsPerPage); setPage(currentPage)}} 
                sortServer
                onSort={(column, sortDirection) => {setSortBy(column.sortField); setSortOrder(sortDirection);}}
              />
            </div>
          </div>
        </div>
      </div>
      <Tooltip id="my-tooltip"  style={{ zIndex: 99 }} />
    </div>
  );
}    
import { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import axios from 'axios';
import AdminDashboardLayout from "../../../Components/AdminDashboard/AdminDashboardLayout";
import Loading from 'react-fullscreen-loading';
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useLocation, useParams } from "react-router-dom";
import MachinesTable from "../../../Components/Tables/Admin/MachinesTable";

export default function AssignedMachines() {
  const navigate = useNavigate();

  const [TableData,setTableData]= useState([]);
  const [screenloader,setScreenloader] = useState(true);
  const [TotalRecords,setTotalRecords] = useState();
  const Param1 = useParams();
  const location = useLocation();
  // eslint-disable-next-line no-unused-vars
  const loc = location.pathname === `/admin/license/${Param1}/assignedmachines` ? true : false;
  const license_id = Param1.id;
  
  useEffect(()=>{
    getUsersList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[])

const getUsersList=async()=>{
    await axios.get(`${process.env.REACT_APP_API_URL}/admin/license/${license_id}/assignedmachines`,{
      headers:{
        'Authorization':localStorage.getItem('accessToken'),
      },
    })
    .then(res=>{
        setScreenloader(false);
        if(res.data.status==="success"){
          const Records=Object.keys(res.data.machines).length;
          setTotalRecords(Records);
          if(Records!==0){
              toast.success(`${Records} Records Found!`);
              setTableData(res.data.machines);
              return;
            }
        }
        else if(res.data.status==="norecord"){
          setTotalRecords(0)
          setTableData([]);
          toast.success('0 Records Found!');
          return;
        }else{
          toast.error('Something Went Wrong');
          console.log(res.data);
          return;
        }
    })
    .catch(er=>{
      setScreenloader(false);
      if(er.response.data?.message){
        toast.error(er.response.data.message);
        return;
      }else{
          console.log(er);
          toast.error("Something Went Wrong");
          return;
      }
    })
}

  return (
    <div>
      <AdminDashboardLayout />
      <Loading loading={screenloader} background="#fff" loaderColor="#883484" />
      <div className='content-page-bg'>
        <div className='content-page'>
            <Button className="bg-none" variant="danger" onClick={() => {navigate(-1);}}>{"Back"}</Button>
            <div className='content'>
                <h1>Assigned Machines</h1>
                <p>Total Records: <span style={{fontWeight:'bold'}}>{TotalRecords}</span></p>
                <Container fluid className='behind scroll'>
                <MachinesTable Data={TableData}/>
                </Container>
            </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-hot-toast";

export const AdminRoute = ({ children }) => {
  const [auth, setAuth] = useState(true);
  const [redirectTo, setRedirectTo] = useState("/admin");

  useEffect(() => {
    const validateAccessToken = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/auth/verifytoken`,
          {
            headers: {
              authorization: localStorage.getItem("accessToken"),
            },
          }
        );
        if (response.data === "tokenExpired") {
          throw new Error("Token expired");
        }

        if (response.data?.status !== 1) {
          throw new Error("Not authorized");
        }
        if (response.data.user.userType === "user") {
          setRedirectTo("/user/dashboard");
          setAuth(false);
        }else{
          setAuth(true);
        }
      } catch (error) {
        handleAuthenticationError(error);
      }
    };

    if (
      localStorage.getItem("accessToken")){
        validateAccessToken();
      } else {
        setRedirectTo("/signin");
        setAuth(false);
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAuthenticationError = (error) => {
    console.error(error);
    if (error.message === "Token expired") {
      // Handle token expiration error
      refreshToken();
    } else if (error.message === "Not authorized") {
      // Handle not authorized error
      toast.error("Not authorized!");
      setAuth(false);
    } else {
      // Handle other errors
      toast.error("An error occurred. Please login again.");
      setAuth(false);
    }
  };

  const refreshToken = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/gettokens`,
        {
          headers: {
            authorization: localStorage.getItem("refreshToken"),
          },
        }
      );

      if (response.data === "tokenExpired") {
        toast.error("Activity Timeout. Login again!");
        localStorage.clear();
        setAuth(false);
        return;
      }

      if (response.data.status === 1) {
        localStorage.setItem("accessToken", response.data.user.accessToken);
        localStorage.setItem("refreshToken", response.data.user.refreshToken);
        localStorage.setItem("userType", response.data.user.userType);
        localStorage.setItem("email", response.data.user.email);
        window.location.reload();
      }
    } catch (error) {
      handleAuthenticationError(error);
    }
  };

  const PageRedirection = () => <Navigate to={redirectTo} />;

  return auth ? children : PageRedirection();
};

import React from 'react';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { Fade } from 'react-awesome-reveal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'; 
import logo from '../../assets/Logo/logo_white.webp';
import { NavLink } from 'react-router-dom';

export default function NavigationBar() {
  return (
    <Navbar collapseOnSelect expand="lg" variant="dark" className='fixed-top' style={{ backgroundColor: '#2D3748' }}>
      <Container>
        <Navbar.Brand>
          <Fade left>
            <Link to='/'>
              <img src={logo} height={"40rem"} alt="logo"></img>
            </Link>
          </Fade>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" className='justify-content-end'>
          <Nav className=" font-Raleway">
            <Fade right>
              <NavLink
                className='ml-2 text-end font-face-reg nav-link nav-itm'
                to='https://cadomation.com'
              >
                Home
              </NavLink>
              <NavLink
                className='ml-2 text-end font-face-reg nav-link nav-itm'
                to='https://cadomation.com/spcad/'
              >
                SPCAD
              </NavLink>
              <NavLink
                className='ml-2 text-end font-face-reg nav-link nav-itm'
                to='https://cadomation.com/downloads/'
              >
                Downloads
              </NavLink>
              <NavLink
                className='ml-2 text-end font-face-reg nav-link nav-itm'
                to='https://cadomation.com/pricing/'
              >
                Pricing
              </NavLink>
              <NavLink
                className='ml-2 text-end font-face-reg nav-link nav-itm'
                to='https://cadomation.com/blogs/'
              >
                Blogs
              </NavLink>
              <NavLink
                className='ml-2 text-end font-face-reg nav-link nav-itm'
                to='https://cadomation.com/about/'
              >
                About Us
              </NavLink>
              <NavDropdown title={<span>Contact Us <FontAwesomeIcon icon={faAngleDown} /> </span>} className="basic-nav-dropdown text-end">
                <NavLink className='dropdown-item text-end' to='https://cadomation.com/contact/'>Contact Us</NavLink>
                <NavLink className='dropdown-item text-end' to='https://cadomation.com/resellers/'>Resellers</NavLink>
                <NavLink className='dropdown-item text-end' to='https://cadomation.com/become-a-reseller/'>Become a Reseller</NavLink>
              </NavDropdown>

              <NavLink
                className='text-end nav-link nav-item sign-in'
                to='/Signin'
                style={{ borderRadius: '18px' }}
              >
                Sign in
              </NavLink>
            </Fade>

          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}





// import {Navbar,Container,Nav} from 'react-bootstrap';
// import { Link } from "react-router-dom";
// import {Fade} from 'react-awesome-reveal';
// // import logo_white from './resources/images/logo_white.webp';
// import React from 'react';
// import { NavLink } from 'react-router-dom';
// import logo from '../../assets/Logo/logo_white.webp'
// export default function NavigationBar(){
//     return(
//         <Navbar  collapseOnSelect expand="lg"  variant="dark" className='fixed-top'style={{ backgroundColor: '#2D3748' }}>
//           <Container>
//           <Navbar.Brand>
//             <Fade left>
//               <Link to='/'>
//                   <img src={logo} height={"40rem"} alt="logo"></img>
//               </Link>
//             </Fade>
//           </Navbar.Brand>
//           <Navbar.Toggle aria-controls="responsive-navbar-nav" />
//           <Navbar.Collapse id="responsive-navbar-nav" className='justify-content-end'>
//             <Nav className=" font-Raleway">
//             <Fade right>
//               <NavLink
//                 className='ml-2 text-end font-face-reg nav-link nav-itm'
//                 to='https://cadomation.com'
//               >
//                 Home
//               </NavLink>
//               <NavLink
//                 className='ml-2 text-end font-face-reg nav-link nav-itm'
//                 to='https://cadomation.com/spcad/'
//               >
//                 SPCAD
//               </NavLink>
//               <NavLink
//                 className='ml-2 text-end font-face-reg nav-link nav-itm'
//                 to='https://cadomation.com/downloads/'
//               >
//                 Downloads
//               </NavLink>
//               <NavLink
//                 className='ml-2 text-end font-face-reg nav-link nav-itm'
//                 to='https://cadomation.com/pricing/'
//               >
//                 Pricing
//               </NavLink>
//               <NavLink
//                 className='ml-2 text-end font-face-reg nav-link nav-itm'
//                 to='https://cadomation.com/blogs/'
//               >
//                 Blogs
//               </NavLink>
//               <NavLink
//                 className='ml-2 text-end font-face-reg nav-link nav-itm'
//                 to='https://cadomation.com/about/'
//               >
//                 About Us
//               </NavLink>
//               <NavLink
//                 className='ml-2 text-end font-face-reg nav-link nav-itm'
//                 to='https://cadomation.com/contact/'
//               >
//                 Contact Us
//               </NavLink>
//               <NavLink
//                 className='ml-2 text-end font-face-reg nav-link nav-itm'
//                 to='https://cadomation.com/resellers/'
//               >
//                 Resellers
//               </NavLink>
//               <NavLink
//                 className='ml-2 text-end font-face-reg nav-link nav-itm'
//                 to='https://cadomation.com/become-a-reseller/'
//               >
//                 Become a reseller
//               </NavLink>

//               <NavLink
//               className='ml-2 ms-2 text-end font-face-reg nav-link nav-itm'
//               to='/Signin'
//               >
//                 Sign in
//               </NavLink>
//             </Fade>
            
//             </Nav>
//           </Navbar.Collapse>
//           </Container>
//         </Navbar>
//     )
// }
import Footer from "../../../../Components/Footer/Footer";
import NavigationBar from "../../../../Components/NavigationBar/NavigationBar";
import { Button, Card, Form } from "react-bootstrap";
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from "axios";
import { toast } from 'react-hot-toast';
import Loading from 'react-fullscreen-loading';

export default function ChangePassword(){
    const navigate = useNavigate();
    const [screenloader,setScreenloader] = useState(false);

    const [password,setPassword] = useState("");
    const [confirmPassword,setConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [userCode,setUserCode] = useState("");
    const [timer, setTimer] = useState(300);
    const Param1 = useParams();
    const location = useLocation();
    // eslint-disable-next-line no-unused-vars
    const loc = location.pathname === `/ChangePassword/${Param1}` ? true : false;
    const email = Param1.email;
    useEffect(() => {
        if (timer > 0) {
          const interval = setInterval(() => {
            setTimer(prevTimer => prevTimer - 1);
          }, 1000);
    
          return () => clearInterval(interval);
        }
    }, [timer]);
    
    const SendAgain=async(e)=>{
        await ResetEmail();
    }


    const ResetEmail=async()=>{
        await axios.post(`${process.env.REACT_APP_API_URL}/auth/forgotpasswordrequest`,{
            email:email,
        })
        .then(res=>{
            setScreenloader(false);
            if(res.data && res.data.status !== undefined){
                if(res.data.status===1){
                    toast.success("Verification Code sent to your email!");
                    setTimer(300);
                    return;
                }else{
                    toast.error(res.data.message);
                    return;
                }
            }
        })
        .catch(er=>{
            setScreenloader(false);
            if(er.response.data?.message){
                toast.error(er.response.data?.message);
                return;
            }else{
                console.log(er)
                toast.error("Unable to connect to Server");
                return;
            }
        })
    }

    const ChangeAccountPassword=async()=>{
        await axios.post(`${process.env.REACT_APP_API_URL}/auth/ChangePassword`,{
            email: email,
            password: password,
            usercode: userCode
        })
        .then(res=>{
            setScreenloader(false);
            if(res.data && res.data.status !== undefined){
                if(res.data.status===1){
                    toast.success("Password Changed Successfully");
                    navigate(`/Signin`, { replace: true });
                    return;
                }else{
                    toast.error(res.data.message);
                    return;
                }
            }
        })
        .catch(er=>{
            setScreenloader(false);
            if(er.response.data?.message){
                toast.error(er.response.data?.message);
                return;
            }else{
                console.log(er)
                toast.error("Unable to connect to Server");
                return;
            }
        })
    }

    const Submit = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
        //   document.getElementById('confirmPassword').setCustomValidity("Passwords do not match");
        toast.error("Passwords do not match")  
        return; // Exit early if the passwords do not match
        }
        if (!isStrongPassword(password)) {
        //   document.getElementById('password').setCustomValidity("Password should be strong (include uppercase, lowercase, numbers, and special characters)");
        toast.error("Password should be strong (greater than 8 characters, include uppercase, lowercase, numbers, and special characters)")  
        return; // Exit early if the password is not strong
        }
        if (!email) {
          toast.error("Email not found!");
          return; // Exit early if email is not found
        }
      
        setScreenloader(true);
        await ChangeAccountPassword();
      }
      

    const isStrongPassword = (password) => {
        // Define your criteria for a strong password here
        const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return strongPasswordRegex.test(password);
      };
      const toggleShowPassword = () => {
        setShowPassword(!showPassword);
      };

    return(
        <>
        <Loading loading={screenloader} background="#fff" loaderColor="#883484" />
        <NavigationBar/>
        <div className="nav_fix vh-95 d-flex flex-column justify-content-center align-items-center" style={{ backgroundColor: '#CBD2D9' }}>
            <Card className="shadow col-10 col-md-6 m-5">
              <Card.Body className="d-flex flex-column justify-content-center align-items-center">
              <div className="mb-3 mt-md-2">
                  <h2 className="fw-bold mb-2 text-uppercase ">Cadomation</h2>
                  <p className=" mb-2">An email with a validation code will be sent to your email for verification.</p>
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <Form onSubmit={e=>Submit(e)}>
                    <Form.Group
                        className="mb-3"
                        controlId="verificationCode"
                      >
                        <Form.Label>Verification Code</Form.Label>
                        <Form.Control 
                            type={'number'} 
                            placeholder="Code" 
                            required 
                            value={userCode} 
                            onChange={e=>setUserCode(e.target.value)}
                        />
                      </Form.Group>

                      <Form.Group
                        className="mb-3"
                        controlId="password"
                      >
                        <Form.Label>New Password</Form.Label>
                        <Form.Control 
                            type={showPassword ? "text" : "password"} 
                            placeholder="Password" 
                            required 
                            value={password} 
                            onChange={e=>setPassword(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="confirmPassword"
                      >
                        <Form.Label>Confirm New Password</Form.Label>
                        <Form.Control 
                            type={showPassword ? "text" : "password"} 
                            placeholder="Password" 
                            required 
                            value={confirmPassword} 
                            onChange={e=>setConfirmPassword(e.target.value)}
                        />
                        <Form.Check
                            type="checkbox"
                            label="Show Password"
                            onChange={toggleShowPassword}
                            id="show password"
                        />
                      </Form.Group>
                      <div className="d-grid">
                      <Button variant="primary" type="submit" style={{ backgroundColor: '#2D3748', borderColor: '#2D3748' }}>
                        Change
                      </Button>
                      </div>
                    </Form>
                    <div className="mt-2">
                    <div className="mt-2">
                        <p className="mb-0 text-center">
                        Didn't receive a code?{' '}
                            {!timer ? (
                            <>
                                
                                <span onClick={SendAgain} className="link_like fw-bold">
                                Send again
                                </span>
                            </>
                            ) : (
                            `Send again in ${Math.floor(timer / 60)}:${timer % 60 < 10 ? '0' : ''}${timer % 60}`
                            )}
                        </p>
                    </div>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
        </div>
        <Footer/>
        </>
    )
}
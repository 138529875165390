
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation, useParams } from "react-router-dom";
import {Container,Card,Form,Button} from "react-bootstrap";
import axios from 'axios';
import AdminDashboardLayout from "../../../Components/AdminDashboard/AdminDashboardLayout";
import Loading from 'react-fullscreen-loading';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { toast } from "react-hot-toast";
import Select from 'react-select'



export default function EditLicense() {
  const navigate = useNavigate();

  const [screenloader,setScreenloader] = useState(true);
  const [licenseRefId,setLicenseRefId] = useState("");
  const [product,setProduct] = useState({});
  const [expiresAt,setExpiresAt] = useState("");
  const [locked,setLocked] = useState({});

  const [cardData,setCardData] = useState({});

  const Param1 = useParams();
  const location = useLocation();
  // eslint-disable-next-line no-unused-vars
  const loc = location.pathname === `/ShowUser/${Param1}` ? true : false;
  const id = Param1.id;
  

  const getLicenseDetail=async()=>{
      await axios.get(`${process.env.REACT_APP_API_URL}/admin/license/${id}`,{
        headers:{
          'authorization':localStorage.getItem('accessToken'),
        },
      })
      .then(res=>{
          setScreenloader(false);
          if(res.data.status==="success"){
              // console.log(res.data.license);
              setCardData(res.data.license);
              if(res.data.license.product== null){
                setProduct({value:"",label:""});
              }else{
                setProduct({value:res.data.license.product,label:res.data.license.product});
              }
              setLocked({value:res.data.license.locked, label:res.data.license.locked?"Locked":"Unlocked"})
              setLicenseRefId(res.data.license.license_reference_id)
              const enddate=DateFormatDispaly(res.data.license.expires_at);
              setExpiresAt(enddate)
              return;
          }
          else if(res.data.status==="norecord"){
            toast.success('License not Found!');
            return;
          }else{
            toast.error('Something Went Wrong');
            console.log(res.data);
            return;
          }
      })
      .catch(er=>{
        setScreenloader(false);
        if(er.response.data?.message){
          toast.error(er.response.data.message)
        }else{
          console.log(er)
          toast.error("Something Went Wrong")
        }
      })
  };
  useEffect(()=>{
    getLicenseDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const UpdateLicense=async()=>{
    setScreenloader(true);
    // console.log(id,licenseRefId,product,locked,expiresAt)
    let pid
    if(product.value===""){
      pid=null
    }else{
      pid=product.value
    }
    await axios.post(`${process.env.REACT_APP_API_URL}/admin/license/edit`,{
      id:id,
      license_reference_id:licenseRefId,
      product:pid,
      expires_at:expiresAt,
      locked:locked.value
    },{
      headers:{
        'authorization':localStorage.getItem('accessToken'),
      },
    })
    .then(res=>{
      // console.log(res.data)
        setScreenloader(false);
        if(res.data.status==="success"){
            toast.success("License updated successfully!");
            setTimeout(()=>{
                window.location.reload();
            },400)
            return;
        }
        else if(res.data.status==="failed"){
          toast.success('Could not update licnese!');
          return;
        }else{
          toast.error('Something Went Wrong');
          console.log(res.data);
          return;
        }
    })
    .catch(er=>{
      setScreenloader(false);
      if(er.response.data?.message){
        toast.error(er.response.data.message)
      }else{
        console.log(er)
        toast.error("Something Went Wrong")
      }
    })
  }
  const Displaydate=(date)=>{
    const d1 = new Date(date).toDateString();
    return(d1);
  }
  const DateFormatDispaly=(d)=>{
    const t = new Date(d);
    const date = ('0' + t.getDate()).slice(-2);
    const month = ('0' + (t.getMonth() + 1)).slice(-2);
    const year = t.getFullYear();
    return(`${year}-${month}-${date}`);
  };

  const Actions=()=>{
      return(
        <>
          <div className='mb-3 text-left'>
               <Button onClick={UpdateLicense} variant="success">Update</Button>
          </div>
          <div className='mb-3 text-left'>
            <Button onClick={()=>{navigate(-1)}} variant="danger">Back</Button>
          </div>
        </>
      )
  }
  return (
    <div>
      <AdminDashboardLayout />
      <Loading loading={screenloader} background="#fff" loaderColor="#883484" />
      <div className='content-page-bg'>
        <div className='content-page'>
          <div className='content'>
          <h1>Edit License</h1>
            <Container fluid className='behind'>
              <Card className='mt-4'>
                <Card.Body>
                <div className='row my-4'>
                    <div className='col-lg-4 col-md-4 col-6'>
                      <Card.Subtitle className='mb-2 text-muted'>License Reference Id</Card.Subtitle>
                      <Form.Control
                        className="fontBold"
                        required
                        placeholder="Enter Reference Id"
                        title="Ref ID"
                        type="number"
                        name="Ref ID"
                        value={licenseRefId}
                        onChange={(event) => {
                          setLicenseRefId(event.target.value)
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="mb-3">
                      <label htmlFor="product" className="form-label">
                          Product
                      </label>
                      <Select
                          placeholder="Select Product"
                          options={[
                              { value: "SPCAD_ACAD_Beta", label: "SPCAD_ACAD_Beta" },
                              { value: "SPCAD_GSTARCAD", label: "SPCAD_GSTARCAD" },
                              { value: "SPCAD_BRX", label: "SPCAD_BRX" },
                              { value: "SPCAD_ZWCAD", label: "SPCAD_ZWCAD" },
                              { value: "SPCAD_CADMATE", label: "SPCAD_CADMATE" },
                          ]}
                          value={product}
                          onChange={(selectedOption) => {
                              setProduct(selectedOption);
                              // setProductError(""); // Clear the error when a new option is selected
                          }}
                      />
                      {/* {productError && <div className="d-flex invalid-feedback">{productError}</div>} */}
                    </div>
                  </div>
                  <div className='row my-4'>
                    <div className='col-lg-4 col-md-4 col-6'>
                      <Card.Subtitle className='mb-2 text-muted'>Change End Date</Card.Subtitle>
                      <Form.Control
                        type='date'
                        required
                        value={expiresAt}
                        onChange={(event) => {
                          setExpiresAt(event.target.value)
                        }}
                      />
                    </div>
                  </div>
                  <div className='row my-4'>
                    <div className='col-lg-4 col-md-4 col-6'>
                    <label htmlFor="product" className="form-label">
                        Change Locked Status
                      </label>
                      <Select
                        placeholder="Select Lock Status"
                        options={[
                          { value: 0, label: "Unlock" },
                        ]}
                        value={locked}
                        onChange={(event) => {
                          setLocked(event);
                        }}
                      />
                    </div>
                  </div>

                  <div className='row my-4'>
                    <div className='col-lg-4 col-md-4 col-6'>
                      <Card.Subtitle className='mb-2 text-muted'>
                        Status
                      </Card.Subtitle>
                      <Card.Title>{cardData.status?"Active":"Expired"}</Card.Title>
                    </div>
                  </div>
                  {Actions()}
                </Card.Body>
              </Card>
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
}


// import "./AdminDashboard.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation, useParams } from "react-router-dom";
import {Container,Card,Form,Button} from "react-bootstrap";
import axios from 'axios';
import AdminDashboardLayout from "../../../Components/AdminDashboard/AdminDashboardLayout";
import Loading from 'react-fullscreen-loading';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { toast } from "react-hot-toast";



export default function ADDProduct() {
  const navigate = useNavigate();

  const [screenloader,setScreenloader] = useState(false);
  const [PID,setPID] = useState('');
  const [Price,setPrice] = useState(0);
  const [Version,setVersion] = useState('');
  const [Note,setNote] = useState('');

  // const Param1 = useParams();
  // const location = useLocation();
  // eslint-disable-next-line no-unused-vars
  // const loc = location.pathname === `/ShowProduct/${Param1}` ? true : false;
  // const id = Param1.id;
  

  
  const Actions=()=>{
    return(
          <>
            <div className='mb-3 text-left'>
              <Button className="btn" style={{ color: '#ffffff', backgroundColor: '#2e2e67', borderColor: '#2e2e67' }} onClick={UpdateData} variant="success">Add</Button>
            </div>
            {/* <div className='mb-3 text-left'>
              <Button onClick={()=>{navigate(-1)}} variant="danger">Back</Button>
            </div> */}
          </>
      )
  }

  const UpdateData=async()=>{
    if(!PID&&!Price&&!Version){
      toast.error('Fields canot be empty');
      return;
    }
    setScreenloader(true);
    console.log(PID,Price,Version,Note)
    await axios.post(`${process.env.REACT_APP_API_URL}/admin/product/add`,{
      pid: PID,
      price: Price,
      version: Version,
      note: Note
    },{
      headers:{
        'Authorization':localStorage.getItem('accessToken'),
      }
    })
    .then(res=>{
        setScreenloader(false);
        // console.log(res.data);
        if(res.data.status==="success"){
          toast.success('New Product Added');
          navigate(`/admin/ActiveProducts`, { replace: true });

          // setTimeout(()=>{
          //   window.location.reload();
          // },1000)
        }
        else{
          toast.error('Something Went Wrong');
          console.log(res.data);
          return;
        }
    })
    .catch(er=>{
      setScreenloader(false);
      if(er.response.data?.message){
        toast.error(er.response.data.message);
        return;
      }else{
        console.log(er);
        toast.error("Something Went Wrong");
        return;
      }
    })
  }


  return (
    <div>
      <AdminDashboardLayout />
      <Loading loading={screenloader} background="#fff" loaderColor="#883484" />
      <div className='content-page-bg'>
        <div className='content-page'>
          <div className='content'>
            <Container fluid className='behind'>
              <Card className='mt-4'>
                <Card.Body>                  
                  <div className='row '>
                    <div className='col-lg-4 col-md-4 col-6'>
                      <Card.Subtitle className='mb-2 text-muted'>
                        PID
                      </Card.Subtitle>
                      <Form.Control
                      className="fontBold"
                      required
                      placeholder="Enter PID"
                      title="PID"
                      type="text"
                      name="PID"
                      value={PID}
                      onChange={(e)=>setPID(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className='row my-4'>
                    <div className='col-lg-4 col-md-4 col-6'>
                      <Card.Subtitle className='mb-2 text-muted'>
                        Version
                      </Card.Subtitle>
                      <Form.Control
                      className="fontBold"
                      required
                      placeholder="Enter Version"
                      title="Version"
                      type="text"
                      name="Version"
                      value={Version}
                      onChange={(e)=>setVersion(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className='row my-4'>
                    <div className='col-lg-4 col-md-4 col-6'>
                      <Card.Subtitle className='mb-2 text-muted'>
                        Price in $
                      </Card.Subtitle>
                      <Form.Control
                      className="fontBold"
                      required
                      placeholder="Enter Price"
                      title="Price"
                      type="number"
                      name="Price"
                      value={Price}
                      onChange={(e)=>setPrice(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className='row my-4'>
                    <div className='col-lg-4 col-md-4 col-6'>
                      <Card.Subtitle className='mb-2 text-muted'>
                        Note
                      </Card.Subtitle>
                      <Form.Control
                      className="fontBold"
                      required
                      placeholder="Enter Version"
                      title="Version"
                      type="text"
                      name="Version"
                      value={Note}
                      onChange={(e)=>setNote(e.target.value)}
                      />
                    </div>
                  </div>
                  {Actions()}
                </Card.Body>
              </Card>
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
}

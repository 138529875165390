
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {Container,Card,Button} from "react-bootstrap";
import axios from 'axios';
import AdminDashboardLayout from "../../../Components/AdminDashboard/AdminDashboardLayout";
import Loading from 'react-fullscreen-loading';
import { toast } from "react-hot-toast";
import Select from 'react-select'


export default function AddLicense() {
  const navigate = useNavigate();
  const [screenloader,setScreenloader] = useState(false);
  const [email, setEmail] = useState("");
  const [noOfLicenses, setNoOfLicenses] = useState(1);
  // const [pid, setPid] = useState("");
  const [licenseRefId,setLicenseRefId] = useState(0);
  const [emailError, setEmailError] = useState("");
  // const [pidError, setPidError] = useState("");
  const [licenseRefIdError,setLicenseRefIdError] = useState("");
  const [noOfLicensesError, setNoOfLicensesError] = useState("");

  const [product,setProduct] = useState({});
  const [productError, setProductError] = useState("");

  const AddLicense=async()=>{
    await axios.post(`${process.env.REACT_APP_API_URL}/admin/license/add`,{
      email:email,
      no_of_licenses:noOfLicenses,
      license_ref_id:licenseRefId,
      product:product.value
    },{
      headers:{
        'Authorization':localStorage.getItem('accessToken'),
      }
    })
    .then(res=>{
        setTimeout(()=>{
            setScreenloader(false);
        },1500)
        // console.log(res.data);
        if(res.data.status==="success"){
          toast.success('Licenses have been Added');
          // setTimeout(()=>{
          //   window.location.reload();
          // },200)
        }
        else if(res.data.status==='-1'){
          toast.error("Email not registered!");
          return;
        }
        else{
          toast.error('Something Went Wrong');
          console.log(res.data);
          return;
        }
    })
    .catch(er=>{
      setScreenloader(false);
      if(er.response.data?.message){
        toast.error(er.response.data?.message);
        return;
      }else{
          console.log(er);
          toast.error("Something Went Wrong");
          return;
      }
    })
  }


  const handleFormSubmit=async(e)=>{
    e.preventDefault();
    setEmailError("");
    setLicenseRefIdError("");
    setNoOfLicensesError("");
    // Validation checks
    let isValid = true;

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      setEmailError("Invalid email address");
      isValid = false;
    }

    if (!/^\d{4}$/.test(licenseRefId)) {
      setLicenseRefIdError("License Ref Id must be exactly 4 digits");
      isValid = false;
    }

    if (noOfLicenses<1){
      setNoOfLicensesError("No of License must be greater than 0");
      isValid = false;
    }
    if (noOfLicenses>20){
      setNoOfLicensesError("You can only add 20 licenses at a time");
      isValid = false;
    }

    if (isValid) {
      setScreenloader(true);
      // console.log(email,licenseRefId);
      await AddLicense();
    }
  }
 const labelStyles = {
      fontWeight: "bold",
      fontSize: "14px", 
  };
  return (
    <div>
      <AdminDashboardLayout />
      <Loading loading={screenloader} background="#fff" loaderColor="#883484" />
      <div className='content-page-bg'>
        <div className='content-page'>
          <div className='content'>
            <h1>Add License</h1>
            <p className="text-muted">License of one Year will be added for the given email.</p>
            <Container fluid className='behind'>
              <Card className='mt-4'>
                <Card.Body>
                  <form onSubmit={handleFormSubmit}>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label htmlFor="email" className="form-label" style={labelStyles}>
                              Email
                          </label>
                          <input
                              type="email"
                              className="form-control"
                              id="email"
                              placeholder='Enter your Email'
                              value={email}
                              onChange={(e) => {setEmail(e.target.value);setEmailError("")}}
                          />
                          {emailError && <div className="d-flex invalid-feedback">{emailError}</div>}
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <label htmlFor="licenseRefId" className="form-label" style={labelStyles} >
                              License Ref Id
                          </label>
                          <input
                              type="number"
                              className="form-control"
                              id="licenseRefId"
                              placeholder='Your License Ref Id'
                              value={licenseRefId}
                              onChange={(e) => {setLicenseRefId(e.target.value);setLicenseRefIdError("");}}
                          />
                          {licenseRefIdError && <div className="d-flex invalid-feedback">{licenseRefIdError}</div>}
                        </div> 
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <label htmlFor="noOfLicenses" className="form-label" style={labelStyles} >
                              No of Licenses
                          </label>
                          <input
                              type="number"
                              className="form-control"
                              id="noOfLicenses"
                              placeholder='No of Licenses'
                              value={noOfLicenses}
                              onChange={(e) => {setNoOfLicenses(e.target.value);setNoOfLicensesError("");}}
                          />
                          {noOfLicensesError && <div className="d-flex invalid-feedback">{noOfLicensesError}</div>}
                        </div> 
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <label htmlFor="product" className="form-label" style={labelStyles}>
                              Product
                          </label>
                          <Select
                              placeholder="Select Product"
                              options={[
                                  { value: "SPCAD_ACAD_Beta", label: "SPCAD_ACAD_Beta" },
                                  { value: "SPCAD_GSTARCAD", label: "SPCAD_GSTARCAD" },
                                  { value: "SPCAD_BRX", label: "SPCAD_BRX" },
                                  { value: "SPCAD_ZWCAD", label: "SPCAD_ZWCAD" },
                                  { value: "SPCAD_CADMATE", label: "SPCAD_CADMATE" },
                              ]}
                              value={product}
                              onChange={(selectedOption) => {
                                  setProduct(selectedOption);
                                  setProductError(""); // Clear the error when a new option is selected
                              }}
                          />
                          {productError && <div className="d-flex invalid-feedback">{productError}</div>}
                          </div>
                        </div>
                      <div className='mb-3 text-left'>
                      <Button className="btn" style={{ color: '#ffffff', backgroundColor: '#2e2e67', borderColor: '#2e2e67' }} type="submit">
                        Add
                      </Button>
                    </div>
                  </form>
                  <div className="text-muted">
                    <h5>Note:</h5>
                    <p>- The email must be registered with us in order to create a license for that user.</p> 
                    <p>- License Ref id should be 4 digits.</p> 
                    <p>- Use 0000 License Ref id to generate a complete 10 digits random id.</p> 
                  </div>
                </Card.Body>
              </Card>
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
}

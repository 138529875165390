import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import classNames from 'classnames';
import FeatherIcon from 'feather-icons-react';
import { toast } from 'react-hot-toast';

const ProfileDropdown = (props) => {
    const profilePic = props.profilePic || null;
    const [dropdownOpen, setDropdownOpen] = useState(false);

    /*
     * toggle profile-dropdown
     */
    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const Logoutfunction = () => {
        localStorage.clear();
        toast.success("Logout Successful!");
    };

    return (
        <Dropdown show={dropdownOpen} onToggle={toggleDropdown}>
            <Dropdown.Toggle
                id="dropdown-profile"
                as="a"
                onClick={toggleDropdown}
                className={classNames('nav-link', 'nav-user', 'me-0', 'cursor-pointer', { show: dropdownOpen })}>
                <img src={profilePic} className="rounded-circle" alt="" />
                <span className="pro-user-name ms-2">
                    {props.username} <FeatherIcon icon='chevron-down'/>
                </span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu-end profile-dropdown">
                <div onClick={toggleDropdown}>
                    {(props.menuItems || []).map((item, i) => (
                        <div key={i}>
                            <Link
                                to={item.redirectTo}
                                onClick={Logoutfunction}
                                className="dropdown-item notify-item"
                                key={`${i}-profile-menu`}>
                                <FeatherIcon icon={item.icon} className="icon-dual icon-xs me-1" />
                                <span>{item.label}</span>
                            </Link>
                        </div>
                    ))}
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default ProfileDropdown;

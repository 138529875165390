import { useEffect, useState, useMemo } from "react";
import axios from 'axios';
import {  Button } from "react-bootstrap";
import AdminDashboardLayout from "../../../Components/AdminDashboard/AdminDashboardLayout";
import Loading from 'react-fullscreen-loading';
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import DataTable from 'react-data-table-component';
import ShowTime from "../../../utils/ShowTime/ShowTime";
import MapProduct from "../../../utils/ProductMapper/MapProduct";
import {FaEdit,FaUserEdit, FaLaptopMedical, FaRegIdCard} from "react-icons/fa";
import countryList from 'react-select-country-list';
import Select from 'react-select'
import CustomLoader from "../../../utils/DataTableLoader/CustomLoader";
import { Tooltip } from 'react-tooltip'
import getTimeRelativeToNow from "../../../utils/ShowTime/GetRelativeTime";


export default function AllLicenses() {
  const navigate = useNavigate();
  const [screenloader, setScreenloader] = useState(true);
  const now = new Date();

  const [TableData, setTableData] = useState([]);
  const [TotalRecords, setTotalRecords] = useState();
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);
  const [sortBy,setSortBy] = useState("updated_at");
  const [sortOrder,setSortOrder] = useState("DESC");
  const [pending, setPending] = useState(true);

  //filters
  const initialFilters = {
    last_seen: { operator: '', value: '' },
    status: { operator: '', value: '' },
    'l.created_at': { operator: '', value: '' },
    'l.expires_at': { operator: '', value: '' },
    email: { operator: '', value: '' },
    product: { operator: '', value: '' },
    organization: { operator: '', value: '' }
  };
  const [filters, setFilters] = useState(initialFilters);
  const [status, setStatus] = useState("");
  const [lastSeen, setLastSeen] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [expiresAt, setExpiresAt] = useState("");
  const [product, setProduct] = useState("");
  const [email, setEmail] = useState("");
  const [organization , setOrganization] = useState("");
  const [country, setCountry] = useState([]);
  const CountriesList = useMemo(() => countryList().getData(), []);
  
  const handleFiltersChange = (key, value, operator) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [key]: {
        operator: operator,
        value: value
      }
    }));
  };

  const handleFiltersReset = () =>{
    setFilters(initialFilters);
    setStatus("");
    setLastSeen("");
    setCreatedAt("");
    setExpiresAt("");
    setProduct("");
    setEmail("");
    setOrganization("");
    setCountry([]);
  };
  const handleApplyFilters = () => {
      handleFiltersChange("email", `%${email}%`, 'LIKE');
      handleFiltersChange("organization", `%${organization}%`, 'LIKE');
  };

  const handleEnterKeyPress = (e) => {
      var value =e.target.value;
      if(value!==""){
        value = `%${value}%`
      }
      if (e.key === 'Enter') {
        handleFiltersChange(e.target.id, value, 'LIKE')    
      }
  };

  const handleCopyEmailList = () =>{
    const emails = Array.from(new Set(TableData.map(license => license.email)));
    const emailList = emails.join(', ');
    try {
      navigator.clipboard.writeText(emailList);
      toast.success(`${emails.length} Emails copied to clipboard!`);
    } catch (error) {
      toast.error('Failed to copy email list to clipboard');
    }
  };


  const columns = [
    { 
      name: "Actions", 
      sortable: false, 
      cell: row=>(<>
                    <FaEdit
                      data-tooltip-id="license-index-edit"
                      data-tooltip-content="edit license"
                      data-tooltip-place="top"
                      className="edit-icon"
                      onClick={() => {
                        navigate(`/Admin/Licenses/${row.id}/edit`, {
                          replace: false,
                        });
                      }}
                    />
                    <FaLaptopMedical
                      data-tooltip-id="license-index-attached-machines"
                      data-tooltip-content="attached machines"
                      data-tooltip-place="top"
                      className="edit-icon"
                      onClick={() => {
                        navigate(`/Admin/Licenses/${row.id}/AssignedMachines`, {
                          replace: false,
                        });
                      }}
                    />
                    <FaUserEdit
                      data-tooltip-id="license-index-edit-user"
                      data-tooltip-content="edit user"
                      data-tooltip-place="top"
                      className="edit-icon"
                      onClick={() => {
                        navigate(`/Admin/Licenses/user/${row.user_id}/edit`, {
                          replace: false,
                        });
                      }}
                    />
                    <FaRegIdCard
                      data-tooltip-id="license-index-view-invoice"
                      data-tooltip-content="view invoice"
                      data-tooltip-place="top"
                      className="edit-icon"
                      onClick={() => {
                        navigate(`/Admin/Licenses/Invoice/${row.user_id}`, {
                          replace: false,
                        });
                      }}
                    />
                  </>
                  )
    },
    {
      name: 'Ref Id',
      selector: row => (row.license_reference_id),
      sortField: "license_reference_id",
      sortable: true,
      cell: row=>(<div style={{ whiteSpace: 'pre-wrap' }}>{row.license_reference_id}</div>),
    },
    {
      name: 'Email',
      selector: row =>row.email,
      sortField: "email",
      sortable: true,
      cell: row => <div style={{ whiteSpace: 'pre-wrap' }}>{row.email}</div>,
    },
    {
      name: 'Last Update',
      selector: row => row.updated_at,
      sortField: "updated_at",
      sortable: true,
      cell: row => <div style={{ whiteSpace: 'pre-wrap' }}>{ShowTime(row.updated_at)}</div>,
    },
    {
      name: 'Start Date',
      selector: row => row.created_at,
      sortField: "created_at",
      sortable: true,
      cell: row => <div style={{ whiteSpace: 'pre-wrap' }}>{ShowTime(row.created_at)}</div>,
    },
    {
      name: 'End Date',
      selector: row => row.expires_at,
      sortField: "expires_at",
      sortable: true,
      cell: row => <div style={{ whiteSpace: 'pre-wrap' }}>{ShowTime(row.expires_at)}</div>,
    },
    {
      name: 'Product',
      selector: row => row.product,
      sortField: "product",
      sortable: true,
      cell: row => <div style={{ whiteSpace: 'pre-wrap' }}>{MapProduct(row.product,"simple")}</div>,
    },
    {
      name: 'Machines Assigned',
      selector: row => row.assigned_machine_count,
      sortField: "assigned_machine_count",
      sortable: true,
      cell: row=> <div>{row.assigned_machine_count}</div>
    },
    {
      name: 'Machine User',
      selector: row => row.machine_user,
      sortField: "machine_user",
      sortable: true,
      cell: row=> <div>{row.machine_user}</div>

    },
    {
      name: 'Version',
      selector: row => row.product_version,
      sortField: "product_version",
      sortable: true,
      cell: row => <div>{row.product_version ? row.product_version : '-'}</div>,
    },
    {
      name: 'Last Action',
      selector: row => row.last_action,
      sortField: "last_action",
      sortable: true,
      cell: row => <div style={{ whiteSpace: 'pre-wrap' }}>{row.last_action}</div>,
    },
    {
      name: 'Locked',
      selector: row => row.locked,
      sortField: "locked",
      sortable: true,
      cell: row => <div style={{ whiteSpace: 'pre-wrap' }}>{row.locked?"In use":"Free"}</div>,
    },
    {
      name: 'Status',
      selector: row => (row.status),
      sortField: "status",
      sortable: true,
      cell: row=>(claculateStatus(row)),
    },
    {
      name: 'First Name',
      selector: row => row.fname,
      sortField: "fname",
      sortable: true,
      cell: row=> <div>{row.fname? row.fname:"-"}</div>
    },
    {
      name: 'Last Name',
      selector: row => row.lname,
      sortField: "lname",
      sortable: true,
      cell: row=> <div>{row.lname? row.lname:"-"}</div>
    },
    {
      name: 'Country',
      selector: row => row.country,
      sortField: "country",
      sortable: true,
      cell: row => <div style={{ whiteSpace: 'pre-wrap' }}>{row.country? row.country:"-"}</div>,
    },
    {
      name: 'Organization',
      selector: row => row.organization,
      sortField: "organization",
      sortable: true,
      cell: row => <div style={{ whiteSpace: 'pre-wrap' }}>{row.organization?row.organization:"-"}</div>,
    },

  ];

  const getList = async () => {
    // console.log(page,pageSize,sortBy,sortOrder,filters)
    try {
      const formattedFilters = Object.keys(filters)
      .filter(key => filters[key].value) // Filter out falsy values
      .map((key, index) => ({
        [`filters[${index}][column]`]: key,
        [`filters[${index}][operator]`]: filters[key].operator,
        [`filters[${index}][value]`]: filters[key].value,
      }))
      .reduce((acc, obj) => ({ ...acc, ...obj }), {});

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/license/index`, {
        params: {
          limit: pageSize,
          page: page,
          sort_by:sortBy,
          sort_order:sortOrder,
          ...formattedFilters
        },
        headers: {
          'access-token': localStorage.getItem('accessToken'),
        },
      });
      setScreenloader(false);
      setPending(false);
      if (response.data.status) {
        const total = response.data.meta.pagination.total;
        setTotalRecords(total);
        setTableData(response.data.licenses);
      } else {
        toast.error('Something Went Wrong');
        console.log(response.data);
      }
    } catch (error) {
      setScreenloader(false);
      setPending(false);
      if (error.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        console.error(error);
        toast.error('Something Went Wrong');
      }
    }
  };
  
  useEffect(() => {
    setPending(true);
    getList();
  }, [filters, page, pageSize, sortBy, sortOrder]);
 

   const claculateStatus = (row) => {
    return(
    <div>
      {new Date(row.expires_at) > now ? 'Active':row.status ? 'Expired but not accessed since' : 'Expired'}
    </div>)
  }
  const conditionalRowStyles = [
    {
      //Active licenses
      when: row => (new Date(row.expires_at) > now),
      style: {
        backgroundColor: 'RGBA(10, 0, 72, 0.7)',
        color: 'white',
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
    {
      //Expired licenses
      when: row => (new Date(row.expires_at) < now && row.status == false),
      style: {
        // backgroundColor: 'rgb(246, 114, 128)',
        // color: 'white',
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
    {      
      //Expired but user hasn't logged for the status to be updated
      when: row => (new Date(row.expires_at) < now && row.status == true),
      style: {
        backgroundColor: 'RGB(128, 120, 120)',
        color: 'white',
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
  ]

//   const navigate = useNavigate();

//   const [TableData,setTableData]= useState([]);
//   const [screenloader,setScreenloader] = useState(true);
//   const [TotalRecords,setTotalRecords] = useState();

//   useEffect(()=>{
//     getUsersList();
//   // eslint-disable-next-line react-hooks/exhaustive-deps
// },[])

// const getUsersList=async()=>{
//     await axios.get(`${process.env.REACT_APP_API_URL}/admin/license/all`,{
//       headers:{
//         'Authorization':localStorage.getItem('accessToken'),
//       },
//     })
//     .then(res=>{
//         setScreenloader(false);
//         if(res.data.status==="success"){
//           // console.log(res.data.licenses)
//           const Records=Object.keys(res.data.licenses).length;
//           setTotalRecords(Records);
//           if(Records!==0){
//               toast.success(`${Records} Records Found!`);
//               setTableData(res.data.licenses);
//               return;
//             }
//         }
//         else if(res.data.status==="norecord"){
//           setTotalRecords(0)
//           setTableData([]);
//           toast.success('0 Records Found!');
//           return;
//         }else{
//           toast.error('Something Went Wrong');
//           console.log(res.data);
//           return;
//         }
//     })
//     .catch(er=>{
//       setScreenloader(false);
//       if(er.response.data?.message){
//         toast.error(er.response.data.message);
//         return;
//       }else{
//           console.log(er);
//           toast.error("Something Went Wrong");
//           return;
//       }
//     })
// }

  return (
    <div>
    <AdminDashboardLayout />
    <Loading loading={screenloader} background="#fff" loaderColor="#883484" />
    <div className='content-page-bg'>
      <div className='content-page'>
        <div className='content'>

          <div className="row">
            <h2>Licenses</h2>
          </div>

          <div className="filters row">
            <div className="col-md-3 mb-3">
              <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder='Email'
                  value={email}
                  onChange={e =>{setEmail(e.target.value);}}
                  onKeyPress={handleEnterKeyPress}
              />
            </div>

            <div className="col-md-3 mb-3">
              <input
                  type="text"
                  className="form-control"
                  id="organization"
                  placeholder='Organization'
                  value={organization}
                  onChange={e =>{setOrganization(e.target.value);}}
                  onKeyPress={handleEnterKeyPress}
              />
            </div>
            <div className="col-sm-12 d-md-none mb-3">
              <button className="btn" style={{ color: '#ffffff', backgroundColor: '#2e2e67', borderColor: '#2e2e67' }}  onClick={handleApplyFilters}>Apply Filters</button>
            </div>

            <div className="col-md-3 mb-3">
              <Select placeholder="Country" options={CountriesList} value={country} onChange={(e) => {setCountry(e);handleFiltersChange('country', e.label, '=')}} />
            </div>
            <div className="col-md-3 mb-3">
              <Select
                placeholder="Status"
                options={[
                    { value: "", label: "All" },
                    { value: "1", label: "Active" },
                    { value: "0", label: "Expired" },
                ]}
                value={status}
                onChange={(e) => {setStatus(e);handleFiltersChange('status', e.value, '=')}}
              />
            </div>

            <div className="col-md-3 mb-3">
              <Select
                placeholder="Last Seen"
                options={[
                    { value: "", label:"All" },
                    { value: getTimeRelativeToNow(0,"day","past"), label:"Today" },
                    { value: getTimeRelativeToNow(1,"day","past"), label:"Past 1 Day" },
                    { value: getTimeRelativeToNow(2,"day","past"), label:"Past 2 Days" },
                    { value: getTimeRelativeToNow(3,"day","past"), label:"Past 3 Days" },
                    { value: getTimeRelativeToNow(1,"week","past"), label: "Within a Week" },
                    { value: getTimeRelativeToNow(1,"month","past"), label:"Witin a Month" },
                    { value: getTimeRelativeToNow(2,"day","past"), label:"Within 2 Months" },
                ]}
                value={lastSeen}
                onChange={(e) => {setLastSeen(e);handleFiltersChange('last_seen', e.value, '>')}}
              />
            </div>

            <div className="col-md-3 mb-3">
              <Select
                placeholder="Start Date"
                options={[
                    { value: "", label:"All" },
                    { value: getTimeRelativeToNow(0,"day","past"), label:"Today" },
                    { value: getTimeRelativeToNow(1,"day","past"), label:"Past 1 Day" },
                    { value: getTimeRelativeToNow(2,"day","past"), label:"Past 2 Days" },
                    { value: getTimeRelativeToNow(3,"day","past"), label:"Past 3 Days" },
                    { value: getTimeRelativeToNow(1,"week","past"), label: "Within a Week" },
                    { value: getTimeRelativeToNow(1,"month","past"), label:"Witin a Month" },
                    { value: getTimeRelativeToNow(2,"day","past"), label:"Within 2 Months" },
                ]}
                value={createdAt}
                onChange={(e) => {setCreatedAt(e);handleFiltersChange('l.created_at', e.value, '>=')}}
              />
            </div>

            <div className="col-md-3 mb-3">
              <Select
                placeholder="Product"
                options={[
                    { value: "", label: "All" },
                    { value: "SPCAD_ACAD_Beta", label: "AUTOCAD" },
                    { value: "SPCAD_CADMATE", label: "CADMATE" },
                    { value: "SPCAD_GSTARCAD", label: "GSTARCAD" },
                    { value: "SPCAD_BRX", label: "BRICSCAD" },
                    { value: "SPCAD_ZWCAD", label: "ZWCAD" },
                ]}
                value={product}
                onChange={(e) => {setProduct(e);handleFiltersChange('product', e.value, '=')}}
              />
            </div>

            <div className="col-md-3 mb-3">
              <Select
                placeholder="End Date"
                options={[
                    { value: "", label:"All" },
                    { value: [getTimeRelativeToNow(0,"day","past"), now], label:"Today" },
                    { value: [getTimeRelativeToNow(3,"day","past"), now], label:"Past 3 Days" },
                    { value: [getTimeRelativeToNow(1,"week","past"), now], label: "Past 1 Week" },
                    { value: [getTimeRelativeToNow(1,"month","past"), now], label: "past 1 Month" },
                    { value: [getTimeRelativeToNow(2,"month","past"), now], label: "past 2 Months" },
                    { value: [now, getTimeRelativeToNow(3,"day","future")], label:"Next 3 Days" },
                    { value: [now, getTimeRelativeToNow(1,"week","future")], label:"Next 1 Week" },
                    { value: [now, getTimeRelativeToNow(1,"month","future")], label:"Next 1 Month" },
                    { value: [now, getTimeRelativeToNow(2,"month","future")], label:"Next 2 Months" },
                ]}
                value={expiresAt}
                onChange={(e) => {setExpiresAt(e);handleFiltersChange('l.expires_at', e.value, 'BETWEEN')}}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-6 mb-3">
              <Button onClick={handleCopyEmailList} variant="primary"  style={{ backgroundColor: '#2e2e67', borderColor: '#2e2e67' }}>
                Copy Email List
              </Button>
            </div>
            <div className="col-6 mb-3 d-flex justify-content-end align-items-end">
              <Button onClick={handleFiltersReset} variant="primary"  style={{ backgroundColor: '#2e2e67', borderColor: '#2e2e67' }}>
                Clear all Filters
              </Button>
            </div>
            <div className="row d-flex d-sm-none">
              <p>Total Records: {TotalRecords}</p>
            </div>
          </div>

          <div className="table-wrapper">
            <DataTable
              //data
              columns={columns}
              data={TableData}
              //style
              dense
              direction="auto"
              highlightOnHover
              noHeader
              persistTableHead
              pointerOnHover
              responsive
              subHeaderAlign="right"
              subHeaderWrap
              progressPending={pending}
              progressComponent={<CustomLoader />}
              conditionalRowStyles={conditionalRowStyles}
              //pagination
              pagination
              paginationServer 
              paginationPerPage={pageSize} 
              paginationTotalRows={TotalRecords} 
              paginationRowsPerPageOptions={[10,20,50,100,150]}
              onChangePage={(page) => setPage(page)} 
              onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {setPageSize(currentRowsPerPage); setPage(currentPage)}} 
              sortServer
              onSort={(column, sortDirection) => {setSortBy(column.sortField); setSortOrder(sortDirection);}}
            />
          </div>
        </div>
      </div>
    </div>
    <Tooltip id="license-index-edit"  style={{ zIndex: 99 }} />
    <Tooltip id="license-index-attached-machines"  style={{ zIndex: 99 }} />
    <Tooltip id="license-index-edit-user"  style={{ zIndex: 99 }} />
    <Tooltip id="license-index-view-invoice"  style={{ zIndex: 99 }} />
  </div>
  );
}

import axios from 'axios';
import { toast } from "react-hot-toast";
import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";

export default function EmailSelection({emailsList, setEmailsList, SendEmail,  setScreenloader, setCurrentScreen, response}){
    const [Searchby,setSearchby] = useState("AddManually");
    const [List,SetList] = useState([]);

    const [ManualEmail,setManualEmail] = useState("");

    useEffect(()=>{
        if(Searchby==="All"||Searchby==="Trial Expired"||Searchby==="Trial Active"||Searchby==="License Users"){
            getActiveUsers()
        }
    },[Searchby])

    const getActiveUsers=async()=>{
        await axios.get(`${process.env.REACT_APP_API_URL}/admin/marketing/emails/${Searchby}`,{
            headers:{
              'authorization':localStorage.getItem('accessToken'),
            },
          })
          .then(res=>{
              setScreenloader(false);
              if(res.data.status==="success"){
                const Records=Object.keys(res.data.emails).length;
                if(Records!==0){
                    SetList(res.data.emails);
                    return;
                  }
              }
              else if(res.data.status==="norecord"){
                toast.success('no emails found!');
                return;
              }else{
                toast.error('Something Went Wrong');
                console.log(res.data);
                return;
              }
          })
          .catch(er=>{
            setScreenloader(false);
            if(er.response.data?.message){
                toast.error(er.response.data.message);
                return;
            }else{
                console.log(er);
                toast.error("Something Went Wrong");
                return;
            }
          })
    }

    const AddList=(List)=>{
        var newlist=List.map(({ email }) => (email));
        var finallist=emailsList.concat(newlist)
        const set = new Set(finallist);
        setEmailsList([...set])
    }
    
    const AddEmail=(list,index)=>{
        const finallist = emailsList.concat(list[index].email);
        const set = new Set(finallist);
        setEmailsList([...set])
    }
    const DeleteEmail=(index)=>{
        const newlist = emailsList.filter((item, i) => i !== index);
        setEmailsList(newlist);
    }

    const AddCustomEmail=()=>{
            let list=ManualEmail.replace(/\s/g, "");
            let newlist=list.split(',');
            const emailRegex = /\S+@\S+\.\S+/;
            const filteredEmails = newlist.filter(email => emailRegex.test(email));
            var finallist=emailsList.concat(filteredEmails)
            const set = new Set(finallist);
            setEmailsList([...set])
    }

    const EmailsList=()=>{
        // if(value===0){
            return(
                <div>
                <Button className="" variant="success" onClick={()=>AddList(List)}>Add({List.length})</Button>
                {List.map((item,key)=>{
                    return (
                        <div key={key} className="row g-0 d-flex flex-row m-1">
                        <div className="col-2 d-flex justify-content-center align-items-center">
                            <button
                            type="button"
                            className="btn btn-success"
                            onClick={()=>AddEmail(List,key)}
                            >
                                <span aria-hidden="true">+</span>
                            </button>
                        </div>
                        <div className="col-10 d-flex text-center justify-content-start align-items-center ps-2">
                            <p className="m-0">{item.email}</p>
                        </div>
                    </div>
                    )
                })}
                </div>
            )
        // }else{
        //     var filteredList=List.filter(function(item){return item.Status===value})
        //         return(
        //             <div>
        //             <Button className="" variant="success" onClick={()=>AddList(filteredList)}>Add All({filteredList.length})</Button>
        //             {filteredList.map((item,key)=>{
        //                 return (
        //                 <div key={key} className="row g-0 d-flex flex-row m-1">
        //                 <div className="col-2 d-flex justify-content-center align-items-center">
        //                     <button
        //                     type="button"
        //                     className="btn btn-success"
        //                     onClick={()=>AddEmail(filteredList,key)}
        //                     >
        //                         <span aria-hidden="true">+</span>
        //                     </button>
        //                 </div>
        //                 <div className="col-10 d-flex text-center justify-content-start align-items-center ps-2">
        //                     <p className="m-0">{item.email}</p>
        //                 </div>
        //             </div>
        //             )
        //             })}
        //             </div>
        //         )
        // }
    }

    const AddManually=()=>{
        return(
            <div className=" d-flex flex-column justify-content-start align-items-start">
                <h4>Add Email/Emails here:</h4>
                <textarea
                style={{height:'25rem',width:'auto'}}
                // className="col-12"                
                type="text"
                value={ManualEmail}
                placeholder="Email1,Email2,Email3"
                onChange={(event)=>setManualEmail(event.target.value)}
                />
                <button
                type="button"
                className="btn btn-success mt-2"
                onClick={()=>AddCustomEmail()}
                >
                <span aria-hidden="true">Add</span>
                </button>
            </div>
        )
    }
    
    const DisplayList=()=>{
        if(Searchby==="AddManually"){
            return AddManually()
        }
        if(List[0]){
            return EmailsList()
        }
        if(!List[0]){
            return(
                <div>
                <h4>Select to get Emails!</h4>
                </div>
            )
        }

        // if(List[0]){
        //     if(Searchby==="0"){
        //         return StatusList(0)
        //     }else if(Searchby==="1"){
        //         return StatusList(1)
        //     }else if(Searchby==="2"){
        //         return StatusList(2)
        //     }else if(Searchby==="3"){
        //         return StatusList(3)
        //     }else if(Searchby==="4"){
        //         return StatusList(4)
        //     }else if(Searchby==="AddManually"){
        //         return AddManually()
        //     }
            
        // }
        // else if(Searchby!=="AddManually"){
        //     return(
        //         <div>
        //         <h4>Cannot get Emails!</h4>
        //         </div>
        //     )
        // }else if(Searchby==="AddManually"){
        //     return AddManually()
        // }
       
    }
   
      
    const AddedList=()=>{
        if(emailsList[0]){
            return(
                <div>
                <div className="row g-0 d-flex">
                    <div className="col-10">
                        <h2>Selected Emails({emailsList.length})</h2>
                    </div>
                    <div className="col-2">
                        <button
                        type="button"
                        className="btn btn-danger"
                        style={{ color: '#ffffff', backgroundColor: '#2e2e67', borderColor: '#2e2e67' }} 
                        onClick={()=>setEmailsList([])}
                        >
                            <span aria-hidden="true">clear</span>
                        </button>
                    </div>
                </div>
                
                {emailsList?.map((item,key)=>{
                    return (
                    <div key={key} className="row g-0 d-flex flex-row m-1">
                        <div className="col-2 d-flex text-start justify-content-start align-items-center">
                            <button
                            type="button"
                            className="btn btn-danger"
                            onClick={()=>DeleteEmail(key)}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="col-10 d-flex align-items-center justify-content-start text-center">
                            <p className="m-0">{item}</p>
                        </div>
                    </div>
                    )
                })}
                </div>
            )
        }
        else{
            return(
                <div>
                    <h2>No Selected Emails</h2>
                </div>
            )
        }
    }
    return(
        <>
        <div className="row g-0">
        <div className="col-6 justify-content-start d-flex align-items-center">
        <Button className="font-bold" size="sm" variant="danger" onClick={()=>setCurrentScreen("Write Email")}>{"< Back(Edit Email)"}</Button>
        </div>
        <div className="col-6 justify-content-end d-flex">
        <Button className="mt-1 mb-1" size="lg" variant="success" onClick={()=>{if(emailsList[0]){SendEmail()}else{alert('Email list canot be empty.')}}}>{response?"Send Again":"Send"}</Button>

        {response?
        <Button className="mt-1 mb-1 ms-2" size="lg" variant="primary" onClick={()=>{setCurrentScreen("View Response")}}>view previous response</Button>:
        <></>}
        </div>
        </div>

        <div className="row g-0">
            <div className="col-12 col-lg-6 justify-content-start align-items-start text-start d-flex flex-column">
            <h2>Select Emails</h2>
                <div className="mt-1 mb-1">
                <Form.Select aria-label="Default select example" onChange={(event)=>setSearchby(event.target.value)} value={Searchby}>
                    <option value="" >Open this select menu</option>
                    <option value="All" >All</option>
                    <option value="Trial Active" >Active Trials</option>
                    <option value="Trial Expired" >Expired Trials</option>
                    <option value="License Users" >License Users</option>
                    <option value="AddManually" >Add Manually</option>
                </Form.Select>
                </div>
            {DisplayList()}
            </div>
            <div className="col-12 col-lg-6 justify-content-start align-items-start text-start d-flex flex-column bg-light">
            {AddedList()}
            </div>

        </div>
        </>
    )
   }
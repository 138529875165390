import Footer from "../../../../Components/Footer/Footer";
import NavigationBar from "../../../../Components/NavigationBar/NavigationBar";
import { Button, Card, Form } from "react-bootstrap";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { toast } from 'react-hot-toast';
import Loading from 'react-fullscreen-loading';

export default function ForgotPasswordRequest(){
    const navigate = useNavigate();
    const [screenloader,setScreenloader] = useState(false);

    const [email,setEmail] = useState("");

    const ResetPasswordRequest=async()=>{
        await axios.post(`${process.env.REACT_APP_API_URL}/auth/forgotpasswordrequest`,{
            email:email,
        })
        .then(res=>{
            setScreenloader(false);
            if(res.data && res.data.status !== undefined){
                if(res.data.status===1){
                    toast.success("Verification Code sent to your email!");
                    navigate(`/ChangePassword/${email}`, { replace: true });
                    return;
                }else{
                    toast.error(res.data.message);
                    return;
                }
            }
        })
        .catch(er=>{
            setScreenloader(false);
            if(er.response.data?.message){
                toast.error(er.response.data?.message);
                return;
            }else{
                console.log(er)
                toast.error("Unable to connect to Server");
                return;
            }
        })
    }

    const SubmitResetRequest=async(e)=>{
        e.preventDefault();
        setScreenloader(true);
        await ResetPasswordRequest();
    }

    return(
        <>
        <Loading loading={screenloader} background="#fff" loaderColor="#883484" />
        <NavigationBar/>
        <div className="d-flex flex-column justify-content-between vh-100" style={{ backgroundColor: '#CBD2D9' }}>
            <div className="mb-4">
                {/* Adding margin to the top of the card */}
                <Card className="shadow col-10 col-md-6 mx-auto" style={{ marginTop: '230px' }}>
                    <Card.Body className="d-flex flex-column justify-content-center align-items-center">
                        <div className="mb-3 mt-md-2">
                            <h2 className="fw-bold mb-2 text-uppercase ">Cadomation</h2>
                            <p className=" mb-2">An email with a validation code will be sent to your email for verification.</p>
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                <Form onSubmit={e=>SubmitResetRequest(e)}>
                                    <Form.Group className="mb-3" controlId="email">
                                        <Form.Label className="text-center">
                                            Email address
                                        </Form.Label>
                                        <Form.Control type="email" placeholder="Enter email" required value={email} onChange={e=>setEmail(e.target.value)}/>
                                    </Form.Group>
                                    <div className="d-grid">
                                        <Button variant="primary" type="submit" style={{ backgroundColor: '#2D3748', borderColor: '#2D3748' }}>
                                            Reset Password
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </div>
            <Footer/>
        </div>
        </>
    )
}



// import Footer from "../../../../Components/Footer/Footer";
// import NavigationBar from "../../../../Components/NavigationBar/NavigationBar";
// import { Button, Card, Form } from "react-bootstrap";
// import { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import axios from "axios";
// import { toast } from 'react-hot-toast';
// import Loading from 'react-fullscreen-loading';

// export default function ForgotPasswordRequest(){
//     const navigate = useNavigate();
//     const [screenloader,setScreenloader] = useState(false);

//     const [email,setEmail] = useState("");

//     const ResetPasswordRequest=async()=>{
//         await axios.post(`${process.env.REACT_APP_API_URL}/auth/forgotpasswordrequest`,{
//             email:email,
//         })
//         .then(res=>{
//             setScreenloader(false);
//             if(res.data && res.data.status !== undefined){
//                 if(res.data.status===1){
//                     toast.success("Verification Code sent to your email!");
//                     navigate(`/ChangePassword/${email}`, { replace: true });
//                     return;
//                 }else{
//                     toast.error(res.data.message);
//                     return;
//                 }
//             }
//         })
//         .catch(er=>{
//             setScreenloader(false);
//             if(er.response.data?.message){
//                 toast.error(er.response.data?.message);
//                 return;
//             }else{
//                 console.log(er)
//                 toast.error("Unable to connect to Server");
//                 return;
//             }
//         })
//     }

//     const SubmitResetRequest=async(e)=>{
//         e.preventDefault();
//         setScreenloader(true);
//         await ResetPasswordRequest();
//     }

//     return(
//         <>
//         <Loading loading={screenloader} background="#fff" loaderColor="#883484" />
//         <NavigationBar/>
//         <div className="nav_fix vh-95 d-flex flex-column justify-content-center align-items-center"  style={{ backgroundColor: '#CBD2D9' }}>
//             <Card className="shadow col-10 col-md-6 m-5">
//               <Card.Body className="d-flex flex-column justify-content-center align-items-center">
//               <div className="mb-3 mt-md-2">
//                   <h2 className="fw-bold mb-2 text-uppercase ">Cadomation</h2>
//                   <p className=" mb-2">An email with a validation code will be sent to your email for verification.</p>
//                   <div className="d-flex flex-column justify-content-center align-items-center">
//                     <Form onSubmit={e=>SubmitResetRequest(e)}>
//                       <Form.Group className="mb-3" controlId="email">
//                         <Form.Label className="text-center">
//                           Email address
//                         </Form.Label>
//                         <Form.Control type="email" placeholder="Enter email" required value={email} onChange={e=>setEmail(e.target.value)}/>
//                       </Form.Group>
//                       <div className="d-grid">
//                         <Button variant="primary" type="submit" style={{ backgroundColor: '#2D3748', borderColor: '#2D3748' }}>
//                           Reset Password
//                         </Button>
//                       </div>
//                     </Form>
//                   </div>
//                 </div>
//               </Card.Body>
//             </Card>
//         </div>
//         <Footer/>
//         </>
//     )
// }
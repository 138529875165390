import React from "react";
import {
  FaPlusSquare,
  FaUserPlus,
  FaUserSlash,
  FaDollarSign,
  FaUsers,
  FaCalendarTimes,
  FaCalendarCheck,
  FaHourglassHalf,
  FaFileInvoiceDollar,
  FaStickyNote,
  FaCloudUploadAlt,
  FaCartPlus
} from "react-icons/fa";
import { MdOutgoingMail } from "react-icons/md";

export const LicenseManagement = [
  // {
  //   title: "Buy License",
  //   path: "/User/BuyLicense",
  //   icon: <FaPlusSquare />,
  // },
  {
    title: "Licenses",
    path: "/User/Licenses",
    icon: <FaUsers />,
  },
  {
    title: "Orders",
    path: "/User/Orders",
    icon: <FaFileInvoiceDollar />,
  },
  // {
  //   title: "All TrialLicenses(dev)",
  //   path: "/Admin/triallicenses",
  //   icon: <FaUsers />,
  // },

  // {
  //   title: "Pending Verification",
  //   path: "/Admin/PendingVerification",
  //   icon: <FaUserSlash />,
  // },
  // {
  //   title: "Active Trials",
  //   path: "/Admin/ActiveTrials",
  //   icon: <FaUserPlus />,
  // },
  // {
  //   title: "Expired Trials",
  //   path: "/Admin/ExpiredTrials",
  //   icon: <FaUserSlash />,
  // },
  // {
  //   title: "Active Licenses",
  //   path: "/Admin/ActiveLicenses",
  //   icon: <FaCalendarCheck />,
  //   // icon: <FaIcons.FaEnvelopeOpenText />,
  // },
  // {
  //   title: "Expired Licenses",
  //   path: "/Admin/ExpiredLicenses",
  //   icon: <FaCalendarTimes />,
  // },
  
];

// export const PaymentManagement = [
  
//   {
//     title: "Generated Payments",
//     path: "/Admin/GeneratedPayments",
//     icon: <FaHourglassHalf />,
//   },
//   {
//     title: "Completed Payments",
//     path: "/Admin/CompletedPayments",
//     icon: <FaDollarSign />,
//   },
  
// ]
// export const Marketing = [
//   {
//     title: "Send Emails",
//     path: "/Admin/SendEmail",
//     icon: <MdOutgoingMail />,
//   },
// ]
// export const Product =[
//   // {
//   //   title: "Add New Product",
//   //   path: "/Admin/AddProduct",
//   //   icon: <FaCartPlus />,
//   // },
//   {
//     title: "Active Products",
//     path: "/Admin/ActiveProducts",
//     icon: <FaFileInvoiceDollar />,
//   },
//   // {
//   //   title: "Upload New Version",
//   //   path: "/Admin/ProductUpload",
//   //   icon: <FaCloudUploadAlt />,
//   // },
//   {
//     title: "Product Release Notes",
//     path: "/Admin/ProductReleaseNotes",
//     icon: <FaStickyNote />,
//   },
// ]

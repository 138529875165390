import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Card, Button, Modal } from "react-bootstrap";
import axios from 'axios';
import AdminDashboardLayout from "../../../Components/AdminDashboard/AdminDashboardLayout";
import Loading from 'react-fullscreen-loading';
import { toast } from "react-hot-toast";

export default function CreateNewCoupon() {
  const navigate = useNavigate();
  const [screenloader, setScreenloader] = useState(false);
  const [noOfCoupons, setNoOfCoupons] = useState("");
  const [noOfCouponsError, setNoOfCouponsError] = useState("");
  const [product, setProduct] = useState({});
  const [productError, setProductError] = useState("");
  const [duration, setDuration] = useState("");
  const [expiresAt, setExpiresAt] = useState("");
  const [comments, setComments] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [couponRefs, setCouponRefs] = useState([]);

  useEffect(() => {
    // setting default expiration date to 30 days from current date
    const defaultExpirationDate = new Date();
    defaultExpirationDate.setMonth(defaultExpirationDate.getMonth() +1);
    setExpiresAt(defaultExpirationDate.toISOString().split('T')[0]);
  }, []);

  const CreateNewCoupon = async () => {
    await axios.post(`${process.env.REACT_APP_API_URL}/admin/coupons`, {
      no_of_coupons: noOfCoupons,
      duration: duration,
      expires_at: expiresAt,
      notes: comments
    }, {
      headers: {
        'Access-Token': localStorage.getItem('accessToken'),
      }
    })
      .then(res => {
        setScreenloader(false);
        if (res.data.status) {
          setCouponRefs(res.data.ref_ids);
          setShowModal(true);
          toast.success('Coupon has been created');
        } else {
          toast.error('Something Went Wrong');
        }
      })
      .catch(er => {
        setScreenloader(false);
        if (er.response.data?.message) {
          toast.error(er.response.data?.message);
        } else {
          toast.error("Something Went Wrong");
        }
      });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setNoOfCouponsError("");
    let isValid = true;

    if (noOfCoupons <= 0) {
      setNoOfCouponsError("No of Coupons must be greater than 0");
      isValid = false;
    }
    if (noOfCoupons > 50) {
      setNoOfCouponsError("You can only add 50 Coupons at a time");
      isValid = false;
    }

    if (isValid) {
      setScreenloader(true);
      await CreateNewCoupon();
    }
  };

  const handleDurationChange = (e) => {
    const value = e.target.value;
    if (value >= 0) {
      setDuration(value);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    navigate('/admin/coupons');
  };

  return (
    <div>
      <AdminDashboardLayout />
      <Loading loading={screenloader} background="#fff" loaderColor="#883484" />
      <div className='content-page-bg'>
        <div className='content-page'>
          <div className='content'>
            <h1>Create New Coupon</h1>
            <Container fluid className='behind'>
              <Card className='mt-4'>
                <Card.Body>
                  <form onSubmit={handleFormSubmit}>
                    <div className='row my-4'>
                      <div className='col-lg-4 col-md-4 col-12 col-sm-6'>
                        <label htmlFor="duration" className="form-label" style={{ fontWeight: "bold", fontSize: "14px" }}>
                          Duration (days)
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter number of days"
                          value={duration}
                          onChange={handleDurationChange}
                        />
                      </div>
                    </div>
                    <div className='row my-4'>
                      <div className='col-lg-4 col-md-4 col-12 col-sm-6'>
                        <label htmlFor="expiresAt" className="form-label" style={{ fontWeight: "bold", fontSize: "14px" }}>
                          Expires At
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          value={expiresAt}
                          onChange={(e) => setExpiresAt(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="noOfCoupons" className="form-label" style={{ fontWeight: "bold", fontSize: "14px" }}>
                          No. of Coupons
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="noOfCoupons"
                          placeholder='No of Coupons'
                          value={noOfCoupons}
                          onChange={(e) => { setNoOfCoupons(e.target.value); setNoOfCouponsError(""); }}
                        />
                        {noOfCouponsError && <div className="d-flex invalid-feedback">{noOfCouponsError}</div>}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="comments" className="form-label" style={{ fontWeight: "bold", fontSize: "14px" }}>
                          Comments
                        </label>
                        <textarea
                          className="form-control"
                          id="comments"
                          placeholder='Enter any comments'
                          value={comments}
                          onChange={(e) => setComments(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className='mb-3 text-left'>
                      <Button className="btn" style={{ color: '#ffffff', backgroundColor: '#2e2e67', borderColor: '#2e2e67' }} type="submit">
                        Add
                      </Button>
                    </div>
                  </form>
                  <div className="text-muted">
                    <h5>Note:</h5>
                    <p>- Limit on the number of times each coupon can be used.</p>
                    <p>- Maximum number of coupons allowed are 50.</p>
                    <p>- All coupons expire within 2 months from creation.</p>
                  </div>
                </Card.Body>
              </Card>
            </Container>
          </div>
        </div>
      </div>

      {/* Coupon reference ID */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Coupon Created Successfully</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Here are the reference IDs for the coupons you created:</p>
          <p>{couponRefs.join(', ')}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

import React from "react";
import { Button, Form } from "react-bootstrap";
import JoditEditor from "jodit-react";

export default function Composing({ senderEmail, setSenderEmail, subject, setSubject, content, setContent, setCurrentScreen }) {
    const config = {
        readonly: false,
        height: 300
      };
    return (
        <>
            <h2>Compose Email</h2>
            <h5>Sender Email:</h5>
            <div className="mt-1 mb-1 col-3">
                <Form.Select aria-label="Default select example" onChange={(event) => setSenderEmail(event.target.value)} value={senderEmail}>
                    <option value="sales@cadomation.com">sales@cadomation.com</option>
                    <option value="services@cadomation.com">services@cadomation.com</option>
                    <option value="support@cadomation.com">support@cadomation.com</option>
                    <option value="no-reply@cadomation.com">no-reply@cadomation.com</option>
                </Form.Select>
            </div>
            <h5>Enter Subject:</h5>
            <input
                className="mb-2"
                placeholder="Email Subject..."
                value={subject}
                onChange={(event) => setSubject(event.target.value)}
            />
            <h5>Enter Body:</h5>
            <JoditEditor
                value={content}
                config={config}
                onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                onChange={(newContent) => {}}
            />
            <div className="d-flex justify-content-between align-items-center mt-1">
                <p>Note: Click Anywhere outside the editor to update</p>
                <Button
                    className=""
                    variant="success"
                    style={{ color: '#ffffff', backgroundColor: '#2e2e67', borderColor: '#2e2e67' }} 
                    onClick={() => {
                        if (subject && content && senderEmail) {
                            setCurrentScreen("Select Emails");
                        } else {
                            alert("Subject, Content, or SenderEmail cannot be empty");
                        }
                    }}
                >
                    Next(Select Emails)
                </Button>
            </div>
            <h4>Output</h4>
            <h5>{subject}</h5>
            <div dangerouslySetInnerHTML={{ __html: content }} />
            {console.log(content)}
        </>
    );
}

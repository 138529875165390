// import "./AdminDashboard.css";
import AdminDashboardLayout from "../../../Components/AdminDashboard/AdminDashboardLayout";


export default function AdminDashboard() {
  return (
    <div>
      <AdminDashboardLayout />
      <div className='content-page-bg'>
        <div className='content-page'>
          <div className='content'>
            <h1>Dashboard</h1>
            <p>Click on the Links in the side bar to get Started.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ShowTime from "../../../utils/ShowTime/ShowTime";
import "./DataTable.css";
import { useState } from "react";
import { Button } from "react-bootstrap";
import Loading from 'react-fullscreen-loading';
import { toast } from "react-hot-toast";
import axios from "axios";

export default function MachinesTable(props) {
  const navigate = useNavigate();
  const [sortOrder, setSortOrder] = useState("desc");
  const [sortColumn, setSortColumn] = useState("last_seen");
  const [screenloader,setScreenloader] = useState(false);

  const sortData = () => {
    const data = [...props.Data];
    const compareFunc = (a, b) =>
      sortOrder === "asc"
        ? a[sortColumn].localeCompare(b[sortColumn])
        : b[sortColumn].localeCompare(a[sortColumn]);
    return data.sort(compareFunc);
  };

  const handleSort = (columnName) => {
    if (columnName === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(columnName);
      setSortOrder("asc");
    }
  };
  const getSortIcon = (columnName) => {
    if (sortColumn === columnName) {
      return sortOrder === "asc" ? "↓" : "↑";
    }
    return "";
  };

  const RemoveMachine=async(id)=>{
    setScreenloader(true);
    await axios.get(`${process.env.REACT_APP_API_URL}/Admin/License/RemoveMachine/${id}`,{
      headers:{
        'authorization':localStorage.getItem('accessToken'),
      },
    })
    .then(res=>{
        setScreenloader(false);
        if(res.data && res.data.status==="success"){
          toast.success("Machine removed");
          window.location.reload();
        }
        else if(res.data.status===-1){
          toast.success('Unable to remove machine at the time!');
          return;
        }else{
          toast.error('Something Went Wrong');
          console.log(res.data);
          return;
        }
    })
    .catch(er=>{
      setScreenloader(false);
      if(er.response.data?.message){
        toast.error(er.response.data.message);
        return;
      }else{
          console.log(er);
          toast.error("Something Went Wrong");
          return;
      }
    })
  }


  return (
    <div>
      <Loading loading={screenloader} background="#fff" loaderColor="#883484" />
      <Table striped bordered hover className="mt-4">
        <thead>
        <tr style={{ cursor: "pointer"}}>
            <th>No.</th>
            <th>
              Machine User
            </th>
            <th>
              Product Version
            </th>
            <th onClick={() => handleSort("last_seen")}>
              Last Seen {getSortIcon("last_seen")}
            </th>
            <th>
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {sortData().map((item, index) => {
            const rowClass = item.locked ? "light-red": "light-green";
            return (
              <tr key={index} className={rowClass}>
                <td>{index+1}</td>
                <td>{item.machine_user}</td>
                <td>{item.product_version?item.product_version:'-'}</td>
                <td className="min-width">{ShowTime(item.last_seen)}</td>
                <td>
                  {item.removed? "Will be removed once user closes autocad":
                  <Button className="bg-none" variant="danger" onClick={()=>RemoveMachine(item.id)}>
                    Remove
                  </Button>} 
                </td>
              </tr>
            );
          })}
        </tbody>
        </Table>
        </div>
  )
}

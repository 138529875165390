// import "./AdminDashboard.css";
import { useEffect, useState } from "react";
import axios from 'axios';
import AdminDashboardLayout from "../../../Components/AdminDashboard/AdminDashboardLayout";
import Loading from 'react-fullscreen-loading';
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import {FaEdit} from "react-icons/fa";
import CustomLoader from "../../../utils/DataTableLoader/CustomLoader";
import DataTable from 'react-data-table-component';


export default function ActiveProducts() {
  const navigate = useNavigate();

  const [TableData,setTableData]= useState([]);
  const [screenloader,setScreenloader] = useState(true);
  const [TotalRecords,setTotalRecords] = useState();
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);
  const [sortBy,setSortBy] = useState(null);
  const [sortOrder,setSortOrder] = useState(null);
  const [pending, setPending] = useState(true);

  
//   //filters
//   const initialFilters = {
//     version: { operator: '', value: '' },
//     pid: { operator: '', value: '' },
//   };
//   const [filters, setFilters] = useState(initialFilters);
//   const [pid, setStatus] = useState("");
//   const [version, setLastSeen] = useState("");
  
//   const handleFiltersChange = (key, value, operator) => {
//     setFilters(prevFilters => ({
//       ...prevFilters,
//       [key]: {
//         operator: operator,
//         value: value
//       }
//     }));
//   };

//   const handleFiltersReset = () =>{
//     setFilters(initialFilters);
//     setpid("");
//     setversion("");
//   }
//   const handleEnterKeyPress = (e) => {
//     var value =e.target.value;
//     if(value!==""){
//       value = `%${value}%`
//     }
//     if (e.key === 'Enter') {
//       handleFiltersChange(e.target.id, value, 'LIKE')    
//     }
// };

const columns = [
  { 
    name: "Actions", 
    sortable: false, 
    cell: row=>(<FaEdit
                  className="edit-icon"
                  onClick={() => {
                    navigate(`/Admin/ActiveProducts/edit/${row.id}`, {
                      replace: false,
                    });
                  }}
                />)
  },
  {
    name: 'ID',
    selector: row => (row.id),
    sortField: "id",
    sortable: true,
    cell: row=>(<div style={{ whiteSpace: 'pre-wrap' }}>{row.id}</div>),
  },
  {
    name: 'PID',
    selector: row => (row.pid),
    sortField: "pid",
    sortable: true,
    cell: row=>(<div style={{ whiteSpace: 'pre-wrap' }}>{row.pid}</div>),
  },
  {
    name: 'Version',
    selector: row => row.version,
    sortField: "version",
    sortable: true,
    cell: row => <div style={{ whiteSpace: 'pre-wrap' }}>{row.version ? row.version : '-'}</div>,
  },
  {
    name: 'Price',
    selector: row => row.price,
    sortField: "price",
    sortable: true,
    cell: row => <div style={{ whiteSpace: 'pre-wrap' }}>{row.price ? row.price : '-'}</div>,
  },
  {
    name: 'Note',
    selector: row => row.note,
    sortField: "note",
    sortable: true,
    cell: row => <div style={{}}>{row.note ? (row.note.length > 15 ? row.note.substring(0, 15) + '...' : row.note) : '-'}
    </div>,
  },
  {
    name: 'Update Notification',
    selector: row => row.notify_update,
    sortField: "notify_update",
    sortable: true,
    cell: row => <div style={{}}>{row.notify_update ? "yes":"no"}
    </div>,
  },
  {
    name: 'Purchasable',
    selector: row => row.purchasable,
    sortField: "purchasable",
    sortable: true,
    cell: row=>(<div style={{ whiteSpace: 'pre-wrap' }}>{row.purchasable? "yes":"no"}</div>),
  },
  {
    name: 'Displayable',
    selector: row => row.displayable,
    sortField: "displayable",
    sortable: true,
    cell: row=>(<div style={{ whiteSpace: 'pre-wrap' }}>{row.displayable? "yes":"no"}</div>),
  },
  
];
const getActiveProducts = async () => {
  // console.log(page,pageSize,sortBy,sortOrder,filters)
  try {
    // const formattedFilters = Object.keys(filters)
    // .filter(key => filters[key].value) // Filter out falsy values
    // .map((key, index) => ({
    //   [`filters[${index}][column]`]: key,
    //   [`filters[${index}][operator]`]: filters[key].operator,
    //   [`filters[${index}][value]`]: filters[key].value,
    // }))
    // .reduce((acc, obj) => ({ ...acc, ...obj }), {});

    const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/product/index`, {
      params: {
        limit: pageSize,
        page: page,
        sort_by:sortBy,
        sort_order:sortOrder,
      },
      headers: {
        'access-token': localStorage.getItem('accessToken'),
      },
    });
    setScreenloader(false);
    setPending(false);
    if (response.data.status) {
      const total = response.data.meta.pagination.total;
      setTotalRecords(total);
      setTableData(response.data.products);
    } else {
      toast.error('Something Went Wrong');
      console.log(response.data);
    }
  } catch (error) {
    setScreenloader(false);
    setPending(false);
    if (error.response?.data?.message) {
      toast.error(error.response.data.message);
    } else {
      console.error(error);
      toast.error('Something Went Wrong');
    }
  }
};
useEffect(() => {
  setPending(true);
  getActiveProducts();
}, [page, pageSize, sortBy, sortOrder]);

return (
  <div>
    <AdminDashboardLayout />
    <Loading loading={screenloader} background="#fff" loaderColor="#883484" />
    <div className='content-page-bg'>
      <div className='content-page'>
        <div className='content'>

          <div className="row">
            <h2>Active Products</h2>
          </div>

          <div className="row d-flex d-sm-none">
            <p>Total Records: <strong>{TotalRecords}</strong></p>
          </div>

          <div className="table-wrapper">
            <DataTable
              //data
              columns={columns}
              data={TableData}
              //style
              dense
              direction="auto"
              highlightOnHover
              noHeader
              persistTableHead
              pointerOnHover
              responsive
              subHeaderAlign="right"
              subHeaderWrap
              progressPending={pending}
              progressComponent={<CustomLoader />}
              // conditionalRowStyles={conditionalRowStyles}
              //pagination
              pagination
              paginationServer 
              paginationPerPage={pageSize} 
              paginationTotalRows={TotalRecords} 
              paginationRowsPerPageOptions={[10,20,50,100,150]}
              onChangePage={(page) => setPage(page)} 
              onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {setPageSize(currentRowsPerPage); setPage(currentPage)}} 
              sortServer
              onSort={(column, sortDirection) => {setSortBy(column.sortField); setSortOrder(sortDirection);}}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);
}    


//   const getActiveProducts=async()=>{
//     await axios.get(`${process.env.REACT_APP_API_URL}/admin/product/all`,{
//       headers:{
//         'Authorization':localStorage.getItem('accessToken'),
//       },
//     })
//     .then(res=>{
//         setScreenloader(false);
//         if(res.data.status==="success"){
//           const Records=Object.keys(res.data.products).length;
//           setTotalRecords(Records);
//           if(Records!==0){
//             setTableData(res.data.products);
//             return
//           }
//         }
//         else if(res.data.status==="norecord"){
//           toast.success('0 Records Found!');
//           return;
//         }else{
//           toast.error('Something Went Wrong');
//           console.log(res.data);
//           return;
//         }
//     })
//     .catch(er=>{
//       setScreenloader(false);
//       if(er.response.data?.message){
//         toast.error(er.response.data?.message);
//         return;
//       }else{
//           console.log(er)
//           toast.error("Something Went Wrong");
//           return;
//       }
//     })
//   }
//   const DataTable = (props) => {
//     const navigate = useNavigate();
    
//     return (
//       <div>
//         <Table striped bordered hover className='mt-4'>
//           <thead>
//             <tr>
//               <th>Action</th>
//               <th>PID</th>
//               <th>Price in $</th>
//               <th>Version</th>
//             </tr>
//           </thead>
//           <tbody>
//             {props.Data.map((item, index) => {
//               return (
//                 <tr key={index} >
//                   <td 
//                   onClick={()=>{navigate(`/Admin/ShowProduct/${item.PID}`, { replace: false })}}
//                   style={{cursor:'pointer'}}
//                   >View/Edit</td>
//                   <td>{item.PID}</td>
//                   <td>{item.Price}</td>
//                   <td>{item.Version}</td>
//                 </tr>
//               );
//             })}
//           </tbody>
//         </Table>
//       </div>
//     );
//   };

//   return (
//     <div>
//       <AdminDashboardLayout />
//       <Loading loading={screenloader} background="#fff" loaderColor="#883484" />
//       <div className='content-page-bg'>
//         <div className='content-page'>
//           <div className='content'>
//             <h1>Active Products</h1>
//             <p>Total Records: <span style={{fontWeight:'bold'}}>{TotalRecords}</span></p>
//             <Container fluid className='behind scroll'>
//               <ProductsTable Data={TableData} />
//             </Container>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// import "./AdminDashboard.css";
import { useEffect, useState, useMemo } from "react";
import axios from 'axios';
import AdminDashboardLayout from "../../../Components/AdminDashboard/AdminDashboardLayout";
import Loading from 'react-fullscreen-loading';
import { toast } from "react-hot-toast";
import {  Button } from "react-bootstrap";
import DataTable from 'react-data-table-component';
import ShowTime from "../../../utils/ShowTime/ShowTime";
import MapProduct from "../../../utils/ProductMapper/MapProduct";
import countryList from 'react-select-country-list';
import Select from 'react-select'
import CustomLoader from "../../../utils/DataTableLoader/CustomLoader";


export default function RequestQuotes() {

  const [TableData,setTableData]= useState([]);
  const [screenloader,setScreenloader] = useState(true);
  const [TotalRecords,setTotalRecords] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [sortBy,setSortBy] = useState();
  const [sortOrder,setSortOrder] = useState(null);
  const [pending, setPending] = useState(true);

  const initialFilters = {
    'l.created_at': { operator: '', value: '' },
    email: { operator: '', value: '' },
    Product: { operator: '', value: '' },
    organization: { operator: '', value: '' }
  };
  const [filters, setFilters] = useState(initialFilters);
  const [createdAt, setCreatedAt] = useState("");
  const [product, setProduct] = useState("");
  const [email, setEmail] = useState("");
  const [organization , setOrganization] = useState("");
  const [country, setCountry] = useState([]);
  const CountriesList = useMemo(() => countryList().getData(), []);
  
  const handleFiltersChange = (key, value, operator) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [key]: {
        operator: operator,
        value: value
      }
    }));
  };

  const handleFiltersReset = () =>{
    setFilters(initialFilters);
    setCreatedAt("");
    setProduct("");
    setEmail("");
    setOrganization("");
    setCountry([]);
  }
  const handleApplyFilters = () => {
    handleFiltersChange("email", `%${email}%`, 'LIKE');
    handleFiltersChange("organization", `%${organization}%`, 'LIKE');
  };

  const handleEnterKeyPress = (e) => {
      var value =e.target.value;
      if(value!==""){
        value = `%${value}%`
      }
      if (e.key === 'Enter') {
        handleFiltersChange(e.target.id, value, 'LIKE')    
      }
  };

  const handleCopyEmailList = () =>{
    const emails = TableData.map(license => license.email);
    const emailList = (emails.join(', '));
    try {
      navigator.clipboard.writeText(emailList);
      toast.success(`${emails.length} Emails copied to clipboard!`);
    } catch (error) {
      toast.error('Failed to copy email list to clipboard');
    }
  };
  const columns = [
    // { 
    //   name: "Actions", 
    //   sortable: false, 
    //   cell: row=>(<FaEdit
    //                 className="edit-icon"
    //                 onClick={() => {
    //                   navigate(`/Admin/Quotes/edit/${row.machine_id}`, {
    //                     replace: false,
    //                   });
    //                 }}
    //               />)
    // },
    {
      name: 'ID',
      selector: row => row.id,
    },
    {
      name: 'Email',
      selector: row => row.email,
      sortField: "email",
      sortable: true,
      cell: row => <div style={{ whiteSpace: 'pre-wrap' }}>{row.email}</div>,
    },
    {
      name: 'Product',
      selector: row => row.Product,
      sortField: "Product",
      sortable: true,
      cell: row => <div style={{ whiteSpace: 'pre-wrap' }}>{MapProduct(row.Product,"simple")}</div>,
    },
    {
      name: 'No of Licenses',
      selector: row => row.no_of_licenses,
      sortField: "no_of_licenses",
      sortable: true,
      cell: row => <div style={{ whiteSpace: 'pre-wrap' }}>{row.no_of_licenses ? row.no_of_licenses: "-" }</div>,
    },
    {
      name: 'Name',
      selector: row => row.name,
      sortField: "name",
      sortable: true,
      cell: row => <div style={{ whiteSpace: 'pre-wrap' }}>{row.name ? row.name: '-'}</div>,
    },
    {
      name: 'Phone',
      selector: row => row.phone,
      sortField: "phone",
      sortable: true,
      cell: row => <div style={{ whiteSpace: 'pre-wrap' }}>{row.phone ? row.phone: '-'}</div>,
    },
    {
      name: 'Country',
      selector: row => row.country,
      sortField: "country",
      sortable: true,
      cell: row => <div style={{ whiteSpace: 'pre-wrap' }}>{row.country ? row.country: '-'}</div>,
    },
    {
      name: 'Industry',
      selector: row => row.industry,
      sortField: "industry",
      sortable: true,
      cell: row => <div style={{ whiteSpace: 'pre-wrap' }}>{row.industry ? row.industry: '-'}</div>,
    },
    {
      name: 'Organization',
      selector: row => row.organization,
      sortField: "organization",
      sortable: true,
      cell: row => <div style={{ whiteSpace: 'pre-wrap' }}>{row.organization ? row.organization: '-'}</div>,
    },
    {
      name: 'Message',
      selector: row => row.message,
      sortField: "message",
      sortable: true,
      cell: row => <div style={{ whiteSpace: 'pre-wrap' }}>{row.message ? row.message: '-'}</div>,
    },
    {
      name: 'Created At',
      selector: row => row.created_at,
      sortField: "created_at",
      sortable: true,
      cell: row => <div style={{ whiteSpace: 'pre-wrap' }}>{ShowTime(row.created_at)}</div>,
    },
    
  ];

const getUsersList = async () => {
  // console.log(page,pageSize,sortBy,sortOrder,filters)
  try {
    const formattedFilters = Object.keys(filters)
    .filter(key => filters[key].value) // Filter out falsy values
    .map((key, index) => ({
      [`filters[${index}][column]`]: key,
      [`filters[${index}][operator]`]: filters[key].operator,
      [`filters[${index}][value]`]: filters[key].value,
    }))
    .reduce((acc, obj) => ({ ...acc, ...obj }), {});

    const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/marketing/Quotes/index`, {
      params: {
        limit: pageSize,
        page: page,
        sort_by:sortBy,
        sort_order:sortOrder,
        ...formattedFilters
      },
      headers: {
        'access-token': localStorage.getItem('accessToken'),
      },
    });
    setScreenloader(false);
    setPending(false);
    if (response.data.status) {
      const total = response.data.meta.pagination.total;
      setTotalRecords(total);
      setTableData(response.data.quotes);
    } else {
      toast.error('Something Went Wrong');
      console.log(response.data);
    }
  } catch (error) {
    setScreenloader(false);
    setPending(false);
    if (error.response?.data?.message) {
      toast.error(error.response.data.message);
    } else {
      console.error(error);
      toast.error('Something Went Wrong');
    }
  }
};
useEffect(() => {
  setPending(true);
  getUsersList();
}, [filters, page, pageSize, sortBy, sortOrder]);

const now = new Date();

  const startOfDay = new Date(now);
  startOfDay.setHours(0, 0, 0, 0);

  const startOf3DaysAgo = new Date(now);
  startOf3DaysAgo.setDate(startOf3DaysAgo.getDate() - 2);
  startOf3DaysAgo.setHours(0, 0, 0, 0);

  const endOf3DaysAfter = new Date(now);
  endOf3DaysAfter.setDate(endOf3DaysAfter.getDate() + 3);
  endOf3DaysAfter.setHours(0, 0, 0, 0);

  const startOf1WeekAgo = new Date(now);
  startOf1WeekAgo.setDate(startOf1WeekAgo.getDate() - 7);
  startOf1WeekAgo.setHours(0, 0, 0, 0);

  const endOf1WeekAfter = new Date(now);
  endOf1WeekAfter.setDate(endOf1WeekAfter.getDate() + 7);
  endOf1WeekAfter.setHours(0, 0, 0, 0);

  const startOf1MonthAgo = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate());
  startOf1MonthAgo.setHours(0, 0, 0, 0);

  const endOf1MonthAfter = new Date(now.getFullYear(), now.getMonth() + 1, now.getDate());
  endOf1MonthAfter.setHours(0, 0, 0, 0);

  const startOf2MonthAgo = new Date(now.getFullYear(), now.getMonth() - 2, now.getDate());
  startOf2MonthAgo.setHours(0, 0, 0, 0);

  const endOf2MonthAfter = new Date(now.getFullYear(), now.getMonth() + 2, now.getDate());
  endOf2MonthAfter.setHours(0, 0, 0, 0);


  return (
    <div>
      <AdminDashboardLayout />
      <Loading loading={screenloader} background="#fff" loaderColor="#883484" />
      <div className='content-page-bg'>
        <div className='content-page'>
          <div className='content'>

            <div className="row">
              <h2>Requested Quotes</h2>
            </div>

            <div className="filters row">
              <div className="col-md-3 mb-3">
                <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder='Email'
                    value={email}
                    onChange={e =>{setEmail(e.target.value);}}
                    onKeyPress={handleEnterKeyPress}
                />
              </div>

              <div className="col-md-3 mb-3">
                <input
                    type="text"
                    className="form-control"
                    id="organization"
                    placeholder='Organization'
                    value={organization}
                    onChange={e =>{setOrganization(e.target.value);}}
                    onKeyPress={handleEnterKeyPress}
                />
              </div>

              <div className="col-sm-12 d-md-none mb-3">
                <button className="btn" style={{ color: '#ffffff', backgroundColor: '#2e2e67', borderColor: '#2e2e67' }} nClick={handleApplyFilters}>Apply Filters</button>
              </div>

              <div className="col-md-3 mb-3">
                <Select placeholder="Country" options={CountriesList} value={country} onChange={(e) => {setCountry(e);handleFiltersChange('country', e.label, '=')}} />
              </div>

              <div className="col-md-3 mb-3">
                <Select
                  placeholder="Created At"
                  options={[
                      { value: "", label:"All" },
                      { value: startOfDay.toISOString(), label:"Today" },
                      { value: startOf3DaysAgo.toISOString(), label: "Past 3 Days" },
                      { value: startOf1WeekAgo.toISOString(), label: "Within a Week" },
                      { value: startOf1MonthAgo.toISOString(), label:"Witin a Month" },
                      { value: startOf2MonthAgo.toISOString(), label:"Within 2 Months" },
                  ]}
                  value={createdAt}
                  onChange={(e) => {setCreatedAt(e);handleFiltersChange('created_at', e.value, '>=')}}
                />
              </div>

              <div className="col-md-3 mb-3">
                <Select
                  placeholder="Product"
                  options={[
                      { value: "", label: "All" },
                      { value: "SPCAD_ACAD_Beta", label: "AUTOCAD" },
                      { value: "SPCAD_CADMATE", label: "CADMATE" },
                      { value: "SPCAD_GSTARCAD", label: "GSTARCAD" },
                      { value: "SPCAD_BRX", label: "BRICSCAD" },
                      { value: "SPCAD_ZWCAD", label: "ZWCAD" },
                  ]}
                  value={product}
                  onChange={(e) => {setProduct(e);handleFiltersChange('Product', e.value, '=')}}
                />
              </div>

            </div>

            <div className="row">
              <div className="col-6 col-md-6 mb-3 d-flex">
                <Button onClick={handleCopyEmailList} variant="primary"  style={{ backgroundColor: '#2e2e67', borderColor: '#2e2e67' }}>
                  Copy Email List
                </Button>
              </div>
              <div className="col-6 col-md-6 mb-3 d-flex justify-content-end align-items-end">
                <Button onClick={handleFiltersReset} variant="primary"  style={{ backgroundColor: '#2e2e67', borderColor: '#2e2e67' }}>
                  Clear all Filters
                </Button>
              </div>
            </div>
            <div className="row d-flex d-sm-none">
              <p>Total Records: <strong>{TotalRecords}</strong></p>
            </div>

            <div className="table-wrapper">
              <DataTable
                //data
                columns={columns}
                data={TableData}
                //style
                dense
                direction="auto"
                highlightOnHover
                noHeader
                persistTableHead
                pointerOnHover
                responsive
                subHeaderAlign="right"
                subHeaderWrap
                progressPending={pending}
                progressComponent={<CustomLoader />}
                // conditionalRowStyles={conditionalRowStyles}
                //pagination
                pagination
                paginationServer 
                paginationPerPage={pageSize} 
                paginationTotalRows={TotalRecords} 
                paginationRowsPerPageOptions={[10,20,50,100,150]}
                onChangePage={(page) => setPage(page)} 
                onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {setPageSize(currentRowsPerPage); setPage(currentPage)}} 
                sortServer
                onSort={(column, sortDirection) => {setSortBy(column.sortField); setSortOrder(sortDirection);}}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}    

export default function MapProduct(product,type){
    if(type==="simple"){
        if(product==="SPCAD_ACAD_Beta"){
            return "AUTOCAD"
        }else
        if(product==="SPCAD_CADMATE"){
            return "CADMATE"
        }else
        if(product==="SPCAD_GSTARCAD"){
            return "GSTARCAD"
        }else
        if(product==="SPCAD_BRX"){
            return "BRICSCAD"
        }else
        if(product==="SPCAD_ZWCAD"){
            return "ZWCAD"
        }if(product==="" || product===null){
            return "-"
        }
        else
        {
            return product
        }
    }else{
        if(product==="SPCAD_ACAD_Beta"){
            return "SPCAD for Autocad"
        }else
        if(product==="SPCAD_CADMATE"){
            return "SPCAD for Cadmate"
        }else
        if(product==="SPCAD_GSTARCAD"){
            return "SPCAD for Gstarcad"
        }else
        if(product==="SPCAD_BRX"){
            return "SPCAD for Bricscad"
        }else
        if(product==="SPCAD_ZWCAD"){
            return "SPCAD for ZWcad"
        }else
        {
            return product
        }
    }
}
export default function getTimeRelativeToNow(n, unit, direction) {
    const now = new Date();
  
    const calculateTime = (time, unit, n) => {
      switch (unit) {
        case "day":
          time.setDate(time.getDate() + n);
          break;
        case "week":
          time.setDate(time.getDate() + n * 7);
          break;
        case "month":
          time.setMonth(time.getMonth() + n);
          break;
        default:
          break;
      }
      return time;
    };
  
    const startOfTime = new Date(now);
    startOfTime.setHours(0, 0, 0, 0);
  
    if (direction === "past") {
      switch (unit) {
        case "day":
        case "week":
        case "month":
          return calculateTime(startOfTime, unit, -n);
        default:
          return now;
      }
    } else if (direction === "future") {
      switch (unit) {
        case "day":
        case "week":
        case "month":
          return calculateTime(startOfTime, unit, n);
        default:
          return now;
      }
    } else {
      return now;
    }
  }
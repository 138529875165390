import Footer from "../../../../Components/Footer/Footer";
import NavigationBar from "../../../../Components/NavigationBar/NavigationBar";
import { Button, Card, Form } from "react-bootstrap";
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from "axios";
import { toast } from 'react-hot-toast';
import Loading from 'react-fullscreen-loading';

export default function VerifyEmail(){
    const navigate = useNavigate();
    const [screenloader,setScreenloader] = useState(false);

    // const [email,setEmail] = useState("");
    const [userCode,setUserCode] = useState("");
    const [timer, setTimer] = useState(300);
    const Param1 = useParams();
    const location = useLocation();
    // eslint-disable-next-line no-unused-vars
    const loc = location.pathname === `/VerifyEmail/${Param1}` ? true : false;
    const email = Param1.email;
    useEffect(() => {
        if (timer > 0) {
          const interval = setInterval(() => {
            setTimer(prevTimer => prevTimer - 1);
          }, 1000);
    
          return () => clearInterval(interval);
        }
      }, [timer]);

    const Verify=async()=>{
        await axios.post(`${process.env.REACT_APP_API_URL}/auth/verifyemail`,{
            email:email,
            usercode:userCode
        })
        .then(res=>{
            setScreenloader(false);
            console.log(res.data)
            if(res.data){
                if(res.data.status===1){
                    toast.success("verification Successfull");
                    localStorage.setItem("accessToken", res.data.data.accessToken);
                    localStorage.setItem("refreshToken", res.data.data.refreshToken);
                    localStorage.setItem("email",email);
                    navigate(`/user/dashboard`, { replace: true });
                    return;
                }else{
                    toast.error(res.data.message);
                    if(res.data.message ==="Code expired."){
                        setTimer(0);
                    }
                    return;
                }
            }
        })
        .catch(er=>{
            setScreenloader(false);
            if(er.response.data?.message){
                toast.error(er.response.data?.message);
                return;
            }else{
                console.log(er)
                toast.error("Unable to connect to Server");
                return;
            }
        })
    }
    const Resend=async()=>{
        await axios.post(`${process.env.REACT_APP_API_URL}/auth/ResendVerificationCode`,{
            email:email
        })
        .then(res=>{
            setScreenloader(false);
            
            console.log(res.data)
            if(res.data){
                if(res.data.status===1){
                    toast.success("Email Sent!");
                    if (timer === 0) {
                        setTimer(300);
                    }
                    return;
                }else{
                    
                    toast.error(res.data.message);
                    return;
                }
            }
        })
        .catch(er=>{
            setScreenloader(false);
            if(er.response.data?.message){
                toast.error(er.response.data?.message);
                return;
            }else{
                console.log(er)
                toast.error("Unable to connect to Server");
                return;
            }
        })
    }

    const Submit=async(e)=>{
        e.preventDefault();
        setScreenloader(true);
        await Verify();
    }

    const SendAgain=async(e)=>{
        await Resend();
    }
    return(
        <>
        <Loading loading={screenloader} background="#fff" loaderColor="#883484" />
        <NavigationBar/>
        <div className="nav_fix vh-95 d-flex flex-column justify-content-center align-items-center" style={{ backgroundImage: 'url("/resources/Backgrounds/Bg_Earth_View_Dark.webp")' }}>
            <Card className="shadow col-10 col-md-6 m-5">
              <Card.Body className="d-flex flex-column justify-content-center align-items-center">
                <div className="mb-3 mt-md-2">
                  <h2 className="fw-bold mb-2 text-uppercase ">Cadomation</h2>
                  <p className=" mb-2 text-muted">An email with a verification code has been sent to your email.</p>
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <Form onSubmit={e=>Submit(e)}>
                       <Form.Group
                        className="mb-3"
                        controlId="verificationCode"
                      >
                        <Form.Label>Verification Code</Form.Label>
                        <Form.Control 
                            type={'number'} 
                            placeholder="Code" 
                            required 
                            value={userCode} 
                            onChange={e=>setUserCode(e.target.value)}
                        />
                      </Form.Group>

                      <div className="d-grid">
                        <Button variant="success" type="submit">
                          Verify
                        </Button>
                      </div>
                    </Form>
                    <div className="mt-2">
                        <p className="mb-0 text-center text-muted">
                        Didn't receive a code?{' '}
                            {!timer ? (
                            <>
                                
                                <span onClick={SendAgain} className="link_like fw-bold">
                                Send again
                                </span>
                            </>
                            ) : (
                            `Send again in ${Math.floor(timer / 60)}:${timer % 60 < 10 ? '0' : ''}${timer % 60}`
                            )}
                        </p>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
        </div>
        <Footer/>
        </>
    )
}
import React from 'react';
import UserDashboardLayout from "../../../Components/UserDashboardLayout/UserDashboardLayout";
import './UserDashboard.css';  
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDesktop, faUser } from '@fortawesome/free-solid-svg-icons';

export default function UserDashboard() {
    return (
        <>
            <UserDashboardLayout />
            <div className='content-page-bg'>
                <div className='content-page'>
                    <div className='content'>
                        <h1>Dashboard</h1>
                        <div>
                            <h3>How Can You Control Your Licenses and Attached Machines</h3>
                            <div className='list' style={{ color: 'grey' }}>
                            <ul>
                                <li> Only five machines <FontAwesomeIcon icon={faDesktop} /> can be attached to a single license at a time, and only one concurrent user <FontAwesomeIcon icon={faUser} /> can utilize the license.</li>
                                <li> If five machines <FontAwesomeIcon icon={faDesktop} /> are already attached and you want to add another machine, you must either log out of your account from other machines or use this dashboard to forcibly log out that machine.</li>
                                <li>If that machine <FontAwesomeIcon icon={faDesktop}/> is already using the product and is forcefully removed from this dashboard, it will be added to the queue. This means that whenever that user <FontAwesomeIcon icon={faUser} /> ends their session (closes the CAD application), the user will be logged out.</li>
                            </ul>
                            </div>
                        </div>
                        <div>
                            <h2>Quick Guide For Purchasing a New License</h2>
                            <div className='list' style={{ color: 'grey' }}>
                                <ul>
                                    <li>Buy License: Click on "Buy License" in the sidebar, then select the license you want to purchase and then select the desired product and the number of licenses you want.</li>
                                    <li>Enter your details (name, phone number, number of licenses, email). Click on "Generate Order."</li>
                                    <li>Payment Process: You will then be redirected to a secure payment gateway. Proceed to payment and complete the transaction.</li>
                                    <li>Confirmation: Receive confirmation of your purchase. After a successful transaction, a license will be assigned to the user.</li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <h2>SPCAD (Our Specialized CAD Plugin) </h2>
                            <p style={{ color: 'grey', fontSize: '1.08rem' }}> A powerful tool designed to seamlessly integrate with CAD applications. It enhances your CAD experience by providing advanced functionalities for terrain analysis, land development, and cartography. With SPCAD, users can efficiently analyze terrain data, visualize land development projects, and create detailed maps and cartographic representations. Whether you're an architect, engineer, or GIS professional, SPCAD empowers you to take your CAD projects to the next level. 
                            For a comprehensive understanding of our products and services, we invite you to explore the SPCAD Help File, where you'll find detailed documentation and resources to support your workflow.</p>
                            <a href="https://help.cadomation.com/spcad/docs/spcadhelp/"  target="_blank" rel="noopener noreferrer">SPCAD HELP</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

import Footer from "../../../../Components/Footer/Footer";
import NavigationBar from "../../../../Components/NavigationBar/NavigationBar";
import { Button, Card, Form } from "react-bootstrap";
import { useState,useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { toast } from 'react-hot-toast';
import Loading from 'react-fullscreen-loading';
import countryList from 'react-select-country-list';
import Select from 'react-select';

export default function Signup(){
  const navigate = useNavigate();
  const [screenloader,setScreenloader] = useState(false);
  const [fname,setFname] = useState("");
  const [lname,setLname] = useState("");
  const [email,setEmail] = useState("");
  const [country,setCountry] = useState("");
  const [organization,setOrganization] = useState("");
  const [password,setPassword] = useState("");
  const [confirmPassword,setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [fnameError, setFnameError] = useState("");
  const [lnameError, setLnameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [organizationError, setOrganizationError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmpasswordError, setConfirmPasswordError] = useState("");

  const options = useMemo(() => countryList().getData(), [])

  const Signup=async()=>{
    await axios.post(`${process.env.REACT_APP_API_URL}/auth/signup`,{
      email:email,
      password:password,
      fname:fname,
      lname:lname,
      country:country.label,
      organization:organization
    })
    .then(res=>{
      setScreenloader(false);
      console.log(res.data)
      if(res.data.status){
        if(res.data.status===1){
            toast.success("Signup Successfull!");
            toast.success("Verification Code sent to your email!");
            navigate(`/VerifyEmail/${email}`, { replace: true });
            return;
        }else{
            toast.error(res.data.message);
            return;
        }
      }
    })
    .catch(er=>{
        setScreenloader(false);
        if(er.response.data?.message){
          toast.error(er.response.data?.message);
          return;
        }else{
          console.log(er)
          toast.error("Unable to connect to Server");
          return;
        }
    })
  }


  const handleFormSubmit = async (e) => {
    e.preventDefault();
    // Reset previous error messages
    setFnameError("");
    setLnameError("");
    setEmailError("");
    setCountryError("");
    setOrganizationError("");
    setPasswordError("");
    setConfirmPasswordError("");
    // Validation checks
    let isValid = true;

    if (fname === "") {
      setFnameError("First Name is required");
      isValid = false;
    }
    if (lname === "") {
      setLnameError("Last Name is required");
      isValid = false;
    }

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      setEmailError("Invalid email address");
      isValid = false;
    }

    if (country === "") {
      setCountryError("Country is required");
      isValid = false;
    }

    if (organization === "") {
        setOrganizationError("Organization is required");
        isValid = false;
    }
    if (password === "") {
      setPasswordError("Password is required");
      isValid = false;
    }
    if (confirmPassword === "") {
      setConfirmPasswordError("Password is required");
      isValid = false;
    }
    if (!isStrongPassword(password)) {
      setPasswordError("Password should be strong (greater than 6 characters, include alphabets and numbers)");
      isValid = false;
    }
    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match");
      isValid = false;
    }

    if (isValid) {
      // Submit the form or perform other actions
      setScreenloader(true)
      // console.log(fname,lname,email,country.label,organization,password,confirmPassword);
      await Signup();
    }
  };

  const isStrongPassword = (password) => {
    const strongPasswordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/;
    return strongPasswordRegex.test(password);
  };
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const labelStyles = {
      fontWeight: "bold",
      fontSize: "14px", // You can adjust the font size as needed
  };

  return(
    <>
      <Loading loading={screenloader} background="#fff" loaderColor="#883484" />
      <NavigationBar/>
      <div className="nav_fix vh-95 d-flex flex-column justify-content-center align-items-center" style={{ backgroundColor: '#CBD2D9' }}>
          <Card className="shadow col-10 col-md-6 m-5">
            <Card.Body className="d-flex flex-column justify-content-center align-items-center">
              <div className="mb-3 mt-md-2">
                <h3 className="fw-bold mb-2">Getting Started with CADOMATION</h3>
                <p className=" mb-4 text-muted">An Email with a validation code will be sent to your email for verification.</p>
                <div className="container">
                  <form onSubmit={handleFormSubmit}>
                    <div className="row">
                      <div className="col-md-12">
                      <div className="mb-3">
                          <label htmlFor="email" className="form-label" style={labelStyles}>
                              Email
                          </label>
                          <input
                              type="email"
                              className="form-control"
                              id="email"
                              placeholder='Enter your Email'
                              value={email}
                              onChange={(e) => {setEmail(e.target.value);setEmailError("")}}
                          />
                          {emailError && <div className="d-flex invalid-feedback">{emailError}</div>}
                        </div>
                      </div>

                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3">
                        <label htmlFor="firstname" className="form-label" style={labelStyles}>
                            First Name
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="firstname"
                            placeholder='Enter your First Name'
                            value={fname}
                            onChange={(e) => {setFname(e.target.value);setFnameError("")}}
                        />
                        {fnameError && <div className="d-flex invalid-feedback">{fnameError}</div>}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="lastname" className="form-label" style={labelStyles}>
                              Last Name
                          </label>
                          <input
                              type="text"
                              className="form-control"
                              id="lastname"
                              placeholder='Enter your Last Name'
                              value={lname}
                              onChange={(e) => {setLname(e.target.value);setLnameError("")}}
                          />
                          {lnameError && <div className="d-flex invalid-feedback">{lnameError}</div>}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="country" className="form-label" style={labelStyles}>
                              Country
                          </label>
                          <Select placeholder="Select your Country" options={options} value={country} onChange={(e) => {setCountry(e);setCountryError("")}} />
                          {countryError && <div className="d-flex invalid-feedback">{countryError}</div>}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="organization" className="form-label" style={labelStyles}>
                              Organization
                          </label>
                          <input
                              type="text"
                              className="form-control"
                              id="organization"
                              placeholder='Your Organization'
                              value={organization}
                              onChange={(e) => {setOrganization(e.target.value);setOrganizationError("");}}
                          />
                          {organizationError && <div className="d-flex invalid-feedback">{organizationError}</div>}
                        </div> 
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="password" className="form-label" style={labelStyles}>
                              Password
                          </label>
                          <input
                              type={showPassword ? "text" : "password"}
                              className="form-control"
                              id="password"
                              placeholder='Enter your Password'
                              value={password}
                              onChange={(e) => {setPassword(e.target.value);setPasswordError("")}}
                          />
                          {passwordError && <div className="d-flex invalid-feedback">{passwordError}</div>}
                        </div>
                        <Form.Check
                          className="text-muted"
                          type="checkbox"
                          label="Show Password"
                          onChange={toggleShowPassword}
                          id="show password"
                        />
                      </div>
                      <div className="col-md-6">
                      <div className="mb-3">
                          <label htmlFor="confirmpassword" className="form-label" style={labelStyles}>
                              Confirm Password
                          </label>
                          <input
                              type={showPassword ? "text" : "password"}
                              className="form-control"
                              id="confirmpassword"
                              placeholder='Enter your Password'
                              value={confirmPassword}
                              onChange={(e) => {setConfirmPassword(e.target.value);setConfirmPasswordError("")}}
                          />
                          {confirmpasswordError && <div className="d-flex invalid-feedback">{confirmpasswordError}</div>}
                        </div>
                      </div>
                    </div>
                    <div className="mt-2 d-flex flex-column align-center">
                    <Button variant="primary" type="submit"  style={{ backgroundColor: '#2D3748', borderColor: '#2D3748' }}>
                        Signup
                      </Button>
                      <p className="mt-2  text-center text-muted">
                        Already have an account?{" "}
                        <span onClick={() => { navigate(`/signin`, { replace: true }) }} className="link_like fw-bold" style={{ color: '#2D3748' }}>
                          Sign in
                        </span>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </Card.Body>
          </Card>
      </div>
      <Footer/>
    </>
  )
}
// style={{ backgroundImage: 'url("/resources/Backgrounds/Bg_Earth_View_Dark.webp")' }}

import { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {Container,Card,Button} from "react-bootstrap";
import axios from 'axios';
import AdminDashboardLayout from "../../../Components/AdminDashboard/AdminDashboardLayout";
import Loading from 'react-fullscreen-loading';
import { toast } from "react-hot-toast";
import Select from 'react-select'
import countryList from 'react-select-country-list';

export default function AddUser() {
  const navigate = useNavigate();
  const [screenloader,setScreenloader] = useState(false);
  const [email, setEmail] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [country, setCountry] = useState("");
  const [organization, setOrganization] = useState("");
  const [password, setPassword] = useState("");

  const [emailError, setEmailError] = useState("");
  const [fnameError, setFnameError] = useState("");
  const [lnameError, setLnameError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [organizationError, setOrganizationError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const options = useMemo(() => countryList().getData(), [])

  const AddUser=async()=>{
    await axios.post(`${process.env.REACT_APP_API_URL}/admin/user/add`,{
      email:email,
      fname:fname,
      lname:lname,
      country:country.label,
      organization:organization,
      password:password
    },{
      headers:{
        'Authorization':localStorage.getItem('accessToken'),
      }
    })
    .then(res=>{
        setTimeout(()=>{
            setScreenloader(false);
        },1500)
        // console.log(res.data);
        if(res.data.status==="success"){
          toast.success('User have been Added');
          // setTimeout(()=>{
          //   window.location.reload();
          // },200)
        }
        else if(res.data.status==='-1'){
          toast.error("Email Already Registered!");
          return;
        }
        else{
          toast.error('Something Went Wrong');
          console.log(res.data);
          return;
        }
    })
    .catch(er=>{
      setScreenloader(false);
      if(er.response.data?.message){
        toast.error(er.response.data?.message);
        return;
      }else{
          console.log(er);
          toast.error("Something Went Wrong");
          return;
      }
    })
  }
  const isStrongPassword = (password) => {
    const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return strongPasswordRegex.test(password);
  };

  const handleFormSubmit=async(e)=>{
    e.preventDefault();
    setEmailError("");
    setFnameError("");
    setLnameError("");

    setCountryError("");
    setOrganizationError("");
    setPasswordError("");
    // Validation checks
    let isValid = true;

    if (fname === "") {
      setFnameError("First Name is required");
      isValid = false;
    }
    if (lname === "") {
      setLnameError("Last Name is required");
      isValid = false;
    }

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      setEmailError("Invalid email address");
      isValid = false;
    }

    if (country === "") {
      setCountryError("Country is required");
      isValid = false;
    }

    if (organization === "") {
        setOrganizationError("Organization is required");
        isValid = false;
    }
    if (password === "") {
      setPasswordError("Password is required");
      isValid = false;
    }
    if (!isStrongPassword(password)) {
      setPasswordError("Password should be strong (greater than 8 characters, include an uppercase, lowercase, number, and a special character)");
      isValid = false;
    }
    if (isValid) {
      setScreenloader(true);
    //   console.log(email,fname,lname,country,organization,password);
      await AddUser();
    }
  }
 const labelStyles = {
      fontWeight: "bold",
      fontSize: "14px", 
  };
  return (
    <div>
      <AdminDashboardLayout />
      <Loading loading={screenloader} background="#fff" loaderColor="#883484" />
      <div className='content-page-bg'>
        <div className='content-page'>
          <div className='content'>
            <h1>Add User</h1>
            <p className="text-muted">A user will created with the given email and password.</p>
            <Container fluid className='behind'>
              <Card className='mt-4'>
                <Card.Body>
                  <form onSubmit={handleFormSubmit}>
                  <div className="row">
                      <div className="col-md-12">
                      <div className="mb-3">
                          <label htmlFor="email" className="form-label" style={labelStyles}>
                              Email
                          </label>
                          <input
                              type="email"
                              className="form-control"
                              id="email"
                              placeholder='Enter your Email'
                              value={email}
                              onChange={(e) => {setEmail(e.target.value);setEmailError("")}}
                          />
                          {emailError && <div className="d-flex invalid-feedback">{emailError}</div>}
                        </div>
                      </div>

                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3">
                        <label htmlFor="firstname" className="form-label" style={labelStyles}>
                            First Name
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="firstname"
                            placeholder='Enter your First Name'
                            value={fname}
                            onChange={(e) => {setFname(e.target.value);setFnameError("")}}
                        />
                        {fnameError && <div className="d-flex invalid-feedback">{fnameError}</div>}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="lastname" className="form-label" style={labelStyles}>
                              Last Name
                          </label>
                          <input
                              type="text"
                              className="form-control"
                              id="lastname"
                              placeholder='Enter your Last Name'
                              value={lname}
                              onChange={(e) => {setLname(e.target.value);setLnameError("")}}
                          />
                          {lnameError && <div className="d-flex invalid-feedback">{lnameError}</div>}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="country" className="form-label" style={labelStyles}>
                              Country
                          </label>
                          <Select placeholder="Select your Country" options={options} value={country} onChange={(e) => {setCountry(e);setCountryError("")}} />
                          {countryError && <div className="d-flex invalid-feedback">{countryError}</div>}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="organization" className="form-label" style={labelStyles}>
                              Organization
                          </label>
                          <input
                              type="text"
                              className="form-control"
                              id="organization"
                              placeholder='Your Organization'
                              value={organization}
                              onChange={(e) => {setOrganization(e.target.value);setOrganizationError("");}}
                          />
                          {organizationError && <div className="d-flex invalid-feedback">{organizationError}</div>}
                        </div> 
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="password" className="form-label" style={labelStyles}>
                              Password
                          </label>
                          <input
                              type="text"
                              className="form-control"
                              id="password"
                              placeholder='Enter your Password'
                              value={password}
                              onChange={(e) => {setPassword(e.target.value);setPasswordError("")}}
                          />
                          {passwordError && <div className="d-flex invalid-feedback">{passwordError}</div>}
                        </div>
                      </div>
                    </div>
                    <div className="mt-2 d-flex flex-column align-center">
                      <Button className="btn" style={{ color: '#ffffff', backgroundColor: '#2e2e67', borderColor: '#2e2e67' }} type="submit">
                        Create User
                      </Button>
                    </div>  
                  </form>
                </Card.Body>
              </Card>
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
}

import { useEffect } from "react"
import { Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom"
import NavigationBar from "../../../Components/NavigationBar/NavigationBar";
import Footer from "../../../Components/Footer/Footer";

export default function PostPaymentPaymob(){
    const navigate = useNavigate();
    useEffect(()=>{
        if(localStorage.getItem('accessToken')){
            navigate(`/User/licenses`, { replace: true })
        }
    })
    return(
        <>
        <NavigationBar/>
        <div className="nav_fix d-flex flex-column justify-content-center align-items-center" style={{ backgroundImage: 'url("/resources/Backgrounds/BG01.webp")',minHeight:'80vh' }}>
            <div className="mx-5">
                <Card style={{ width:"auto"}}>
                <Card.Body><h2>Thank You for Purchasing with Cadomation! </h2><h6>Contact <strong>support@cadomation.com</strong> in case of any issue.</h6></Card.Body>
                </Card>
            </div>
        </div>
        <Footer/>
        </>
    )
}
import "./License.css";
import { Card } from "react-bootstrap";
import AdminDashboardLayout from "../../../Components/AdminDashboard/AdminDashboardLayout";
import { useNavigate } from "react-router-dom";
import { useLocation, useParams } from "react-router-dom";
import SPCAD_Logo from '../../../assets/Logo/SPCAD.webp'
import Cadomation_Logo from '../../../assets/Logo/logo_Black.webp'
import { useState,useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import InvoiceTable from "../../../Components/Tables/Admin/InvoiceTable";
import Loading from 'react-fullscreen-loading';

export default function LicenseInvoice(){
    const navigate = useNavigate();
    const [screenloader,setScreenloader] = useState(true);
    const [data,setData] = useState({});
    const [licenses,setLicenses] = useState([]);
    const Param1 = useParams();
    const location = useLocation();
    // eslint-disable-next-line no-unused-vars
    const loc = location.pathname === `/Admin/Licenses/Invoice/${Param1}` ? true : false;
    const id = Param1.id;

    useEffect(()=>{
        getLicenseinfo();
    },[])

    const getLicenseinfo = async()=>{
        await axios.get(`${process.env.REACT_APP_API_URL}/admin/user/${id}/invoice`,{
            headers:{
              'Authorization':localStorage.getItem('accessToken'),
            }
          })
          .then(res=>{
              setScreenloader(false);
              if(res.data.status==="success"){
                if(res.data.data && res.data.data[0]){
                    setData(res.data.data[0]);
                    if(res.data.data[0].licenses && res.data.data[0].licenses[0] ){
                        setLicenses(res.data.data[0].licenses)
                    }
                    return
                }
              }
              else if(res.data.status==='-1'){
                toast.error('no data found!');
              }else{
                toast.error('Something Went Wrong');
                console.log(res.data);
                return;
              }
          })
          .catch(er=>{
            setScreenloader(false);
            if(er.response.data?.message){
              toast.error(er.response.data?.message);
              return;
            }else{
                console.log(er)
                toast.error("Something Went Wrong");
                return;
            }
        })
    }

    return (
        <div>
        <AdminDashboardLayout />
        <Loading loading={screenloader} background="#fff" loaderColor="#883484" />

        <div className='content-page-bg'>
            <div className='content-page'>
            <div className='content'>
                <Card className="mt-2">
                    <Card.Body>
                    <div className='row g-0 '>
                        <div className='col-4 d-flex justify-content-start align-items-center'>
                            <img 
                            src={Cadomation_Logo}
                            className="cad_Logo"
                            alt="img"
                            />                   
                        </div>
                        <div className='col-4 d-flex justify-content-center align-items-center'>
                            <h2 className="font-Raleway text-decoration-underline">License Details</h2>
                        </div>
                        <div className='col-4 d-flex justify-content-end align-items-center'>
                            <img 
                            src={SPCAD_Logo}
                            className="spcad_Logo"
                            alt="img"
                            />
                        </div>
                    </div>
                    <div className='row g-0 mt-5'>
                        {/* <h2 className="font-Raleway text-decoration-underline">User info</h2> */}
                        <div className='col-6'>
                            <p><strong className="text-muted">First Name:</strong> <strong>{data.fname}</strong>  <strong className="text-muted ps-5">Last Name:</strong> <strong>{data.lname}</strong></p>
                            <p><strong className="text-muted">Email:</strong> <strong>{data.email}</strong></p>
                            <p><strong className="text-muted">Organization:</strong> <strong>{data.organization}</strong></p>
                            <p><strong className="text-muted">Country:</strong> <strong>{data.country}</strong></p>
                        </div>
                    </div>
                    <div className='row g-0 mt-3'>
                        <h3 className="font-Raleway text-decoration-underline">License Issued</h3>
                        <InvoiceTable Data={licenses}/>
                    </div>
                    </Card.Body>
                    <Card.Footer className="d-flex justify-content-center align-items-center">
                        <p className="text-muted m-0 p-0">This system-generated document does not require signatures.</p>
                    </Card.Footer>
                </Card>
            </div>
            </div>
        </div>
        </div>
    );
}
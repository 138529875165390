import { Table } from "react-bootstrap"
import MapProduct from "../../../utils/ProductMapper/MapProduct";

export default function InvoiceTable(props) {
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };
    const sortedData = props.Data.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );

return(
    <div>
      <Table striped bordered hover className="mt-4">
        <thead>
        <tr>
            <th scope="col">Sr.</th>
            <th scope="col">License ID</th>
            <th scope="col">Product</th>
            <th scope="col">Start Date</th>
            <th scope="col">Expiry Date</th>
        </tr>
        </thead>
        <tbody>
            {sortedData.map((item,index)=>{
                return(
                    <tr key={index}>
                        <td>{index+1}</td>
                        <td>{item.license_ref_id}</td>
                        <td>{MapProduct(item.product)}</td>
                        <td>{formatDate(item.created_at)}</td>
                        <td>{formatDate(item.expires_at)}</td>
                    </tr>
                )
            })
            }
        </tbody>
        </Table>
    </div>
)
}
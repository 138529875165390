import "./Footer.css";
import {
  FaFacebook,
  FaLinkedin,
  FaLocationArrow,
  FaPhone,
  FaMailBulk,
  FaRegCopyright,
  FaYoutube
} from "react-icons/fa";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import logo from '../../assets/Logo/logo_white.webp'

export default function Footer() {
  return (
    <>
      <div className="footer">
        <div className="top_footer font_white d-flex flex-row justify-content-center">
          <Row className="g-0">
            <Col lg={4} sm={12}>
              <Fade left cascade>
                <div className="p-5">
                  <Link
                    className="text-decoration-none font_color_white"
                    to="/"
                  >
                    <img
                      src={logo}
                      className="footer_logo mb-2 hover-effect"
                      alt="img"
                    />
                  </Link>
                  <p>CAD Customization and Automation Services.</p>
                </div>
              </Fade>
            </Col>

            {/* <Col lg={3} sm={6}>
              <div className="p-5">
                <p>Links</p>
                <Link
                  className="text-decoration-none"
                  to="/Policies/Privacy-Policy"
                >
                  <p className="p">Privacy Policy</p>
                </Link>
                <Link
                  className="text-decoration-none"
                  to="/Policies/Terms-and-Conditions"
                >
                  <p className="p">Terms and Conditions</p>
                </Link>
                <Link
                  className="text-decoration-none"
                  to="/Policies/FAQs"
                >
                  <p className="p">FAQs</p>
                </Link>
                <Link
                  className="text-decoration-none"
                  to="/Policies/DeliveryPolicy"
                >
                  <p className="p">Shipping Policy</p>
                </Link>
                <Link
                  className="text-decoration-none"
                  to="/Policies/License-Return-and-Refund"
                >
                  <p className="p">License Return and Refund</p>
                </Link>
              </div>
            </Col> */}

                        
            <Col lg={2} sm={6}>
              <div className="p-5">
                <p>Links</p>
                <a href="https://cadomation.com/privacy-policy/" className="text-decoration-none">
                  <p className="p">Privacy Policy</p>
                </a>
                <a href="https://cadomation.com/shipping-policy/" className="text-decoration-none">
                  <p className="p">Shipping Policy</p>
                </a>
                <a href="https://cadomation.com/terms-conditions/" className="text-decoration-none">
                  <p className="p">Terms and Conditions</p>
                </a>
                <a href="https://cadomation.com/license-return-and-refund/" className="text-decoration-none">
                  <p className="p">License Return and Refund</p>
                </a>
                <a href="https://cadomation.com/faqs/" className="text-decoration-none">
                  <p className="p">FAQs</p>
                </a>
              </div>
            </Col>


            <Col lg={2} sm={6}>
              <Fade left cascade>
                <div className="p-5 ">
                  <p>Follow Us</p>
                  <p className="pointer">
                  <a href="https://www.facebook.com/profile.php?id=100088033569557" target="_blank" rel="noopener noreferrer">
                    <FaFacebook className="hover-effect me-2" />
                  </a>
                  <a href="https://www.youtube.com/@cadomation" target="_blank" rel="noopener noreferrer">
                    <FaYoutube className="hover-effect me-2" />
                  </a>
                  <a href="https://www.linkedin.com/company/cadomation/" target="_blank" rel="noopener noreferrer">
                    <FaLinkedin className="hover-effect me-2" />
                  </a>
                  </p>
                </div>
              </Fade>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Fade left cascade>
                <div className="p-5">
                  <p>Reach Us</p>

                  <p>
                    <FaLocationArrow />{" "}
                    <a
                      href="https://maps.app.goo.gl/dbxqBkXTNqaz2Pj18"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      453-Rahman Center 2nd Floor Ravi Block Allama Iqbal Town, Lahore, Pakistan
                    </a>
                  </p>
                  <p>
                    <FaPhone />{" "}
                    <a href="tel:+923214407665"> +92-423-546-8982</a>
                  </p>
                  <p>
                    <FaMailBulk />{" "}
                    <a href="mailto:support@cadomation.com">support@cadomation.com</a>
                  </p>
                </div>
              </Fade>
            </Col>
          </Row>
        </div>

        <div className="bottom_footer">
          <Row className="g-0">
            <Col >
              <Fade cascade>
                <div className="text-center">
                  <p className="footer_copyright text-decoration-none">
                    Copyright <FaRegCopyright /> 2024 Cadomation - All Rights Reserved.
                  </p>
                </div>
              </Fade>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
